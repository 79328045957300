import { SET_SHOW_MODAL, SET_MODAL_MESSAGE } from "../actions/types";

function modalReducer(state = {}, action) {
  switch (action.type) {
    case SET_SHOW_MODAL:
      return {
        ...state,
        shouldShowModal: action.payload,
      };
    case SET_MODAL_MESSAGE:
      return {
        ...state,
        modalMessage: action.payload,
      };

    default:
      return state;
  }
}

export default modalReducer;
