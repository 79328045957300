import React, { useState } from 'react';
import '../styles/Checkbox.css'; // Import the CSS file for styling
import iconImage from '../images/checked_icon.png'; // Import the icon image

const Checkbox = ({selected, onSelectedChange}) => {
  const [isChecked, setIsChecked] = useState(selected);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
    onSelectedChange(!isChecked)
  };

  return (
    <label className="checkbox-container">
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
      />
      <span className="checkmark">
        {isChecked && <img src={iconImage} alt="Icon" />}
      </span>
      Select Item
    </label>
  );
};

export default Checkbox;