import {
    GET_USER,
    GET_USER_RESULT_SUCCESS,
    GET_USER_RESULT_FAILURE,
    CLEAR_USER_INFO
  } from './types';
  
  
  export const getUser = payload => ({
    type: GET_USER,
    payload,
  });

  export const getUserResultSuccess = payload => ({
    type: GET_USER_RESULT_SUCCESS,
    payload,
  });

  export const getUserResultFailure = payload => ({
    type: GET_USER_RESULT_FAILURE,
    payload,
  });

  export const clearUserInfo = payload => ({
    type: CLEAR_USER_INFO,
    payload,
  });

  