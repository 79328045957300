import React from 'react'
import '../styles/Footer.css'
import MailIcon from '../images/mail_icon.png'
import LocationIcon from '../images/location_icon.png'
const Footer = ({isAlternate}) => {

    const footerClass = isAlternate ? 'footer alternate' : 'footer'
    return (
        <footer className={footerClass}>
            <div className='footer-container d-flex align-items-center justify-content-evenly'>
                <div className='footer-item d-flex justify-content-center align-items-center'>
                    <div className={isAlternate ? 'footer-img-container' : ''}>
                        <img src={MailIcon} alt='mail_icon' />
                    </div>
                    <div className='footer-caption mail-caption'>
                        <p>Mail Us</p>
                        <p>info@renewableenergy.place</p>
                </div>
            </div>
            <div className='footer-item d-flex justify-content-between align-items-center'>
                <div className={isAlternate ? 'footer-img-container' : ''}>
                    <img src={LocationIcon} alt='mail_icon' />
                </div>
                <div className='footer-caption location-caption'>
                    <p>Location</p>
                    <p>Tel Aviv, Israel</p>
                </div>
            </div>
            </div>
            {isAlternate && (
                <div className='footer-extension'>
                    <hr></hr>
                    <div className='copyright-container'>
                        <span>@ Copyright 2023, All Rights Reserved</span>
                    </div>
                </div>
            )

            }
        </footer>

    )
}

export default Footer