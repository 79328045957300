import proj4 from "proj4";
import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { centroid } from "@turf/turf";
import {
  outputLandsResponseSelector,
  isLoadingOutputLandsSelector,
  isLockedSearchEntitySelector,
} from "../redux/selectors/outputLands";

import { getSubRegionsResults } from "../redux/actions/layersActions";
import {
  isLoadingSubRegionSelector,
  subRegionsSelector,
} from "../redux/selectors/subRegion";
import { useDispatch } from "react-redux";
import useAuth0AccessToken from "../hooks/authHook";
import { searchClickSelector } from "../redux/selectors/search";

export const useMap = (setLandID, setReadMore, readMore) => {
  const outputLands = useSelector(outputLandsResponseSelector);
  const isLoadingOutputLands = useSelector(isLoadingOutputLandsSelector);
  const isLockedSearchEntity = useSelector(isLockedSearchEntitySelector);
  const subRegions = useSelector(subRegionsSelector);
  const isLoadingsubRegions = useSelector(isLoadingSubRegionSelector);
  const { accessToken } = useAuth0AccessToken();
  const search = useSelector(searchClickSelector);

  const dispatch = useDispatch();

  const getSubRegion = (data) => {
    dispatch(getSubRegionsResults(data));
  };

  //TODO: Adapt the center to sub region dinamically depending on the user.
  /*const [viewState, setView] = useState({
    longitude: 35.57721, //Center it from the beggining in Israel.
    latitude: 32.69882,
    zoom: 10,
  });*/
  const [viewState, setView] = useState({
    longitude: -119.38611295011886, //Center it from the beggining in California.
    latitude: 36.18217911484724,
    zoom: 4,
  });
  const [once, setOnce] = useState(true);

  //mapRef
  const mapRef = useRef(null);

  const setViewState = useCallback((data) => {
    setView(data);
    const map = mapRef.current?.getMap();
    if (map) {
      const bounds = map.getBounds();
      const formatted = [
        [Number(bounds._ne.lng), Number(bounds._ne.lat)],
        [Number(bounds._sw.lng), Number(bounds._sw.lat)],
      ];
      const converted = formatted.map((coord) =>
        proj4("EPSG:4326", "EPSG:3857", coord)
      );

      /*if (!isLoadingsubRegions) {
        getSubRegion({
          token: accessToken,
          extent: converted,
        });
      }*/

      //[converted[0][0], converted[0][1], converted[1][0], converted[1][1]],
    }
  }, []);

  //Need to use useState with the GeoJSONData because of the highlighting??

  // Coordinate Conversion Function
  const convertCoordinates = useCallback((coordinates) => {
    return coordinates.map((coord) => proj4("EPSG:3857", "EPSG:4326", coord));
  }, []);

  const convertLands = useCallback(
    (selectedLands) => {
      if (!Array.isArray(selectedLands)) {
        return [];
      }

      return selectedLands.map((land) => ({
        ...land,
        geom: {
          ...land.geom,
          coordinates: land.geom?.coordinates.map((polygon) =>
            polygon.map(convertCoordinates)
          ),
        },
      }));
    },
    [convertCoordinates]
  );

  const convertedLands = convertLands(outputLands);
  const sideRef = useRef([]);
  useEffect(() => {
    if (
      !isLoadingOutputLands &&
      search &&
      once &&
      !readMore &&
      !isLockedSearchEntity
    ) {
      const add = (acc, data) => {
        acc[data.id] = React.createRef();
        return acc;
      };
      sideRef.current = convertedLands.reduce(add, {});
      setOnce(false);
    }
  }, [
    sideRef,
    isLoadingOutputLands,
    search,
    convertedLands,
    once,
    readMore,
    isLockedSearchEntity,
  ]);

  const getCentroid = (landID) => {
    if (convertedLands.length > 0) {
      return centroid(
        landID === -1
          ? convertedLands[0]?.geom
          : convertedLands[convertedLands.findIndex((obj) => obj.id === landID)]
              ?.geom
      );
    }
    return -1;
  };

  const onSideChange = (landID) => {
    if (!isLockedSearchEntity && convertedLands.length > 0) {
      const cent = getCentroid(landID);
      setViewState({
        longitude: cent.geometry?.coordinates[0],
        latitude: cent.geometry?.coordinates[1],
        zoom: 16, // zoom applied on selected
      });
    }
  };

  //Selecting a land based on a click on a map:
  const onMapClick = (event) => {
    if (mapRef.current) {
      const map = mapRef.current?.getMap();
      const features = map.queryRenderedFeatures(event.point, {
        layers: [
          "land-polygons-normal",
          "land-polygons-fill",
          "land-polygons-normal-fill",
        ],
      });
      setReadMore(false);

      if (features.length) {
        const feature = features[0];
        const land = outputLands.find((l) => l.id === feature.properties.id);
        if (land) {
          setLandID(land.id);
          if (!readMore) {
            sideRef.current[land.id]?.current.scrollIntoView({
              behavior: "smooth",
              block: "nearest",
            });
          }
        }
      }
    }
  };

  const outputlandsData = {
    type: "FeatureCollection",
    features: convertLands(outputLands).map((land) => ({
      type: "Feature",
      properties: {
        id: land.id,
        ranking: land.ranking,
      },
      geometry: land.geom,
    })),
  };

  //Keep GeoJSON same - don't keep on resetting it. Instead, add variable highlighted that takes the landID of the
  //land to highlight

  //Need to change from outputLands into the geoJSON format thing so that polygons are displayed with proper lines/filled in ness.

  return {
    outputlandsData,
    viewState,
    mapRef,
    onMapClick,
    setViewState,
    isLoadingOutputLands,
    onSideChange,
    sideRef,
    convertLands,
  };
};
