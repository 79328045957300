import React from "react";
import { Box, Typography } from "@mui/material";
import CreateModal from "./CreateModal";
import Text from "./Text";
import { Done } from "./Done";
import { useState } from "react";

const RenameModal = ({ setOpen, changeCollection, inp, setSelected }) => {
  const [val, setVal] = useState("");

  //const {saveAsFavoriteLand} = useFavoriteLandResults();

  const handleDone = () => {
    //addCollection(val)
    changeCollection(inp.id, val);
    setOpen(false);
    setSelected(false);
  };

  return (
    <CreateModal
      height={"230px"}
      width={"450px"}
      setOpen={setOpen}
      content={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            gap: "24px",
            marginTop: "-32px",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <Typography
              sx={{ fontSize: "24px", fontWeight: 700, textAlign: "center" }}
            >
              {" "}
              Rename collection
            </Typography>
          </Box>
          <Text label={"Collection name"} setVal={setVal} />

          <Done val={val} handleDone={handleDone} />
        </Box>
      }
    />
  );
};

export default RenameModal;
