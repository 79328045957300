import React, { useEffect } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Button, Typography } from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import Rep from "../images/icons/Report.svg";
import threeCircle from "../images/icons/threeCircle.svg";
import Mail from "../images/icons/Mail.svg";
import {
  getConditionFromRanking,
  getLandDetails,
  getAcRuleDetails,
  getEcRuleDetails,
  getScoreAndRankingValues,
} from "../utils/functions";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Bookmark from "./Bookmark";
import Report from "./Report";

const ReadMore = ({
  setReadMore,
  landID,
  outputLandsResponse,
  collectionData,
  addCollection,
  addToFolder,
}) => {
  const site = outputLandsResponse.findIndex((obj) => obj.id === landID) + 1;
  const landData = outputLandsResponse.filter((land) => land.id === landID)[0];
  const condition = getConditionFromRanking(landData?.ranking);
  const landDetails = getLandDetails(landData);
  const rankingAndScoreValues = getScoreAndRankingValues(landData);
  const acRulesDetails = getAcRuleDetails(landData);
  const ecRulesDetails = getEcRuleDetails(landData);

  const [more, setMore] = useState(false);
  const [collection, openCollection] = useState(false);
  const [addCollectionModal, setAddCollection] = useState(false);
  const [genReport, setGenReport] = useState(false);

  return (
    <Box
      sx={{
        flex: "0 0 40%",
        maxWidth: "600px",
        overflowY: "scroll",
        display: "flex",
        flexDirection: "column",
        padding: "16px",
        gap: "24px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ fontSize: "16px", color: "#52975C" }}>
            {" "}
            Search results{" "}
          </Typography>
          <ChevronRightIcon sx={{ transform: "scale(0.6)" }} />
          <Typography> Site {site} </Typography>
        </Box>
        <Bookmark
          collection={collection}
          openCollection={openCollection}
          landData={landData}
          setAddCollection={setAddCollection}
          collectionData={collectionData}
          addCollection={addCollection}
          addToFolder={addToFolder}
          addCollectionModal={addCollectionModal}
        />
      </Box>

      <Box sx={{ width: "100%" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <ChevronLeftIcon
              sx={{
                color: "#52975C",
                cursor: "pointer",
                transform: "scale(1.2)",
              }}
              onClick={() => setReadMore(false)}
            />
            <Typography sx={{ fontWeight: 700, fontSize: "24px" }}>
              {" "}
              Site {site}{" "}
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: "#D5E6D8",
              borderRadius: "4px",
              display: "flex",
              gap: "4px",
              padding: "8px 16px",
              alignItems: "center",
            }}
          >
            <img src={Mail} height={"18px"} alt="" />
            <Typography
              sx={{ color: "#95C09B", fontSize: "14px", fontWeight: 500 }}
            >
              {" "}
              Contact Owner{" "}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", gap: "10px" }}>
        <Button
          variant="long"
          sx={{
            height: "51px",
            backgroundColor: "#52975C",
            display: "flex",
            gap: "6px",
          }}
          onClick={() => setGenReport(true)}
        >
          <img src={Rep} height={"14px"} alt="" />
          <Typography>Generate Report </Typography>
        </Button>
        <Box
          sx={{
            border: "2px solid #52975C",
            height: "51px",
            width: "68px",
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <img src={threeCircle} height={"20px"} alt="" />
        </Box>
      </Box>
      <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
        <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
          General Information{" "}
        </Typography>
        {rankingAndScoreValues}
        {landDetails}
      </Box>

      <Box sx={{ display: "flex", gap: "2px" }}>
        <Typography
          sx={{
            color: "#47784E",
            textDecoration: "underline",
            fontSize: "16px",
            fontWeight: 500,
            cursor: "pointer",
          }}
          onClick={() => setMore(!more)}
        >
          View {!more ? "more" : "fewer"} parameters
        </Typography>
        {more ? (
          <KeyboardArrowDownIcon
            sx={{ color: "#576171", cursor: "pointer" }}
            onClick={() => setMore(!more)}
          />
        ) : (
          <KeyboardArrowUpIcon
            sx={{ color: "#576171", cursor: "pointer" }}
            onClick={() => setMore(!more)}
          />
        )}
      </Box>
      {more && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          {acRulesDetails}
          {ecRulesDetails}
        </Box>
      )}
      {genReport && <Report setOpen={setGenReport} data={landData} />}
    </Box>
  );
};

export default ReadMore;
