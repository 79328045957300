import { useEffect, useState } from "react";
import Header from "../components/Header";
import MapResults from "../components/MapResults";
import useAuth0AccessToken from "../hooks/authHook";
import { useMap } from "../hooks/useMap";
import { useLayers } from "../hooks/useLayers";
import MapLibreScreen from "./MapLibreScreen";
import "../styles/MapScreen.css";
import {
  MapResultsButton,
  StyledArrowLeftIcon,
  StyledArrowRighttIcon,
} from "../styles/MapScreen.styled";

/*
In case is needed, this is the Mock of the lands
import GeoData from '../response.json'
*/

const MapScreen = ({ step }) => {
  const {
    selectedLand,
    landDetails,
    rulesDetails,
    showPopup,
    viewState,
    geoJSONData,
    mapRef,
    onMapClick,
    closePopup,
    selectedLandCoord,
    setViewState,
    isLoadingSelectedLands,
    getSelectedResults,
    selectedLands,
    onOutputLandClick,
  } = useMap();

  const { layersStructure, toggleLayer, getDisplayedLayers, activeLayers } =
    useLayers();

  const { storedAccessToken } = useAuth0AccessToken();

  const [showMapResults, setShowMapResults] = useState(true);

  useEffect(() => {
    if (storedAccessToken) {
      getSelectedResults(storedAccessToken);
      getDisplayedLayers(storedAccessToken);
    }
  }, [storedAccessToken, getDisplayedLayers, getSelectedResults]);

  const toggleMapResults = () => {
    setShowMapResults(!showMapResults);
  };

  return (
    <>
      <Header />

      <div>
        <div id="content-container">
          {showMapResults && (
            <MapResults
              isLoading={isLoadingSelectedLands}
              selectedLands={selectedLands}
              onOutputLandClick={onOutputLandClick}
            />
          )}
          <MapResultsButton onClick={toggleMapResults}>
            {showMapResults ? (
              <StyledArrowLeftIcon />
            ) : (
              <StyledArrowRighttIcon />
            )}
          </MapResultsButton>

          <MapLibreScreen
            selectedLand={selectedLand}
            landDetails={landDetails}
            rulesDetails={rulesDetails}
            showPopup={showPopup}
            viewState={viewState}
            geoJSONData={geoJSONData}
            mapRef={mapRef}
            onMapClick={onMapClick}
            closePopup={closePopup}
            selectedLandCoord={selectedLandCoord}
            setViewState={setViewState}
            toggleLayer={toggleLayer}
            layersStructure={layersStructure}
            activeLayers={activeLayers}
          />
        </div>
      </div>
    </>
  );
};

export default MapScreen;
