import styled from "styled-components";

export const SearchResultsContainer = styled.div`
  margin-left: 12.5%;
  height: 100%;
  width: 75%;
`;

export const SearchResultsContent = styled.div`
  display: flex !important;
  flex-direction: column !important;
`;

export const ResultsGrid = styled.div`
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  justify-content: center;
  background-color: none;
`;

export const HorizontalRule = styled.hr`
  height: 3px;
  background: #fffdfa;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 80%;
  margin-left: 10%;
`;

export const CenterButton = styled.div`
  text-align: center;
  margin-top: 100px;
  padding-left: 10px;
`;

export const FilterNavContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
`;

export const ConditionDropdown = styled.select`
  width: 200px;
  height: 35px;
  border-radius: 3px;
  border: none;
  outline: none;
  box-shadow: none;
`;

export const FilterNavRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

export const FilterNavImage = styled.div`
  img {
    width: 28px;
    height: 28px;
    margin-left: 10px;
  }
`;

export const ContinueButton = styled.button`
  border-radius: 4px;
  background: var(
    --gradient-color,
    linear-gradient(90deg, #4e814e 0%, #213727 100%)
  );
  color: white;
  height: 48px;
  padding: 10px 20px;
  cursor: pointer;
`;

export const FilterLabel = styled.label`
  margin-right: 20px;
`;

export const StyledImage = styled.img`
  width: 28px;
  height: 28px;
  margin-left: 10px;
`;

export const StyledDiv = styled.div`
  // Add styles specific to each div here, or leave it generic
`;

export const StyledSpan = styled.span`
  // Add styles specific to each span here, or leave it generic
`;

export const StyledOption = styled.option`
  // Add styles for options if needed
`;
