import React from "react";
import {
  ProjectDetailsContainer,
  ProjectDetail,
  ProjectDetailTitle,
  ProjectDetailSubtitle,
} from "../styles/ProjectDetails.styled";

const ProjectDetails = ({ typeLabel, sizeLabel, regionLabel }) => {
  const isFavoriteLands = !typeLabel && !sizeLabel && !regionLabel;

  return (
    <ProjectDetailsContainer>
      {isFavoriteLands ? (
        <ProjectDetail>
          <ProjectDetailTitle isFavoriteLands>
            Favorite Lands
          </ProjectDetailTitle>
          <ProjectDetailSubtitle>
            Compare the outcomes of different searches
          </ProjectDetailSubtitle>
        </ProjectDetail>
      ) : (
        <>
          <ProjectDetail>
            <ProjectDetailTitle>Type of Project</ProjectDetailTitle>
            <ProjectDetailSubtitle>{typeLabel}</ProjectDetailSubtitle>
          </ProjectDetail>
          <ProjectDetail>
            <ProjectDetailTitle>Project Size</ProjectDetailTitle>
            <ProjectDetailSubtitle>{sizeLabel}</ProjectDetailSubtitle>
          </ProjectDetail>
          <ProjectDetail>
            <ProjectDetailTitle>Region Area</ProjectDetailTitle>
            <ProjectDetailSubtitle>{regionLabel}</ProjectDetailSubtitle>
          </ProjectDetail>
        </>
      )}
    </ProjectDetailsContainer>
  );
};

export default ProjectDetails;
