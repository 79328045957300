import React, { useEffect, useState } from "react";
import "../styles/MapCardResults.css";
import MapCard from "./MapCard";
import {
  StyledSpinnerMapResults,
  MessageContainer,
} from "../styles/MapResults.styled";

const MapResults = ({ selectedLands, isLoading, onOutputLandClick }) => {
  const [mapCardList, setMapCardList] = useState([]);
  const [mapKey, setMapKey] = useState(0);

  useEffect(() => {
    setMapKey((prevKey) => prevKey + 1);
  }, [selectedLands]);

  useEffect(() => {
    setMapCardList(() => {
      let mapcards = [];
      if (!selectedLands || selectedLands.length === 0) {
        return;
      }
      // Order selected lands by ranking values descendent
      const orderedLands = Array.isArray(selectedLands)
        ? selectedLands.sort((a, b) => a.ranking_value - b.ranking_value)
        : [];

      orderedLands?.forEach((landData, index) => {
        mapcards.push(
          <MapCard
            key={mapKey + index}
            landData={landData}
            number={index}
            onOutputLandClick={onOutputLandClick}
          />
        );
      });
      return mapcards;
    });
  }, [selectedLands, mapKey, onOutputLandClick]);

  return (
    <div id="results-container">
      <h3>Showcased Results</h3>
      <hr className="horizontal-bar"></hr>
      {isLoading ? (
        <MessageContainer>
          {isLoading && <StyledSpinnerMapResults />}
          {!isLoading && mapCardList.length === 0 && (
            <span>No results were found</span>
          )}
        </MessageContainer>
      ) : null}
      <div className="result-card-container">
        {mapCardList?.map((item, index) => (
          <div className="map-card-element" key={index}>
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MapResults;
