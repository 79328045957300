import React from "react";
//import { useLayers } from "../hooks/useLayers";
import useAuth0AccessToken from "../hooks/authHook";
import { useEffect } from "react";
import MapScreen from "./MapScreen";
import { useLayers } from "../new_hooks/useLayers";

const MapComp = ({
  landID,
  setLandID,
  search,
  outputlandsData,
  viewState,
  mapRef,
  onMapClick,
  setViewState,
  isLoadingOutputLands,
}) => {
  const { layers, changeLayer, getDisplayedLayers } = useLayers();
  /*const { layersStructure, toggleLayer, getDisplayedLayers, activeLayers } =
    useLayers();*/

  const { accessToken } = useAuth0AccessToken();

  useEffect(() => {
    if (accessToken) {
      //getSelectedResults(storedAccessToken);
      getDisplayedLayers(accessToken);
    }
  }, [accessToken, getDisplayedLayers]);

  return (
    <MapScreen
      mapRef={mapRef}
      onMapClick={onMapClick}
      viewState={viewState}
      layers={layers}
      changeLayer={changeLayer}
      fillData={{
        type: "FeatureCollection",
        features: outputlandsData.features.filter(
          (land) => land.properties.id === landID
        ),
      }}
      lineData={{
        type: "FeatureCollection",
        features: outputlandsData.features.filter(
          (land) => land.properties.id !== landID
        ),
      }}
      setViewState={setViewState}
      search={search}
      isLoadingOutputLands={isLoadingOutputLands}
    />
  );
};

export default MapComp;
