import React from "react";
import { Box, Checkbox, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useState } from "react";

const SelectFilter = ({ subcategories, changeActive, category }) => {
  const [selected, setSelected] = useState(false);
  const dropstyle = (sel) => ({
    position: "absolute",
    right: "7px",
    transition: " all .05s ease-in-out",
    opacity: sel === selected ? 1 : 0,
  });
  const checkstyle = () => ({
    display: "inline-flex",
    background: "white",
    transition: " opacity .1s ease-in-out",
    opacity: selected ? 1 : 0,
    padding: "12px 12px 12px 4px",
    borderRadius: "6px",
    position: "absolute",
    marginTop: "8px",
    zIndex: selected ? 5 : -5,
  });

  const handleChange = () => {
    setSelected(!selected);
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Box>
        <Box>
          <Box
            sx={{
              padding: "6px 28px 6px 14px",
              backgroundColor: "white",
              fontSize: "14px",
              position: "relative",
              cursor: "pointer",
              display: "inline-flex",
              alignItems: "center",
              borderRadius: "6px",
            }}
            onClick={handleChange}
          >
            <Typography sx={{ fontSize: "14px" }}> {category} </Typography>
            <ArrowDropDownIcon sx={dropstyle(false)} />
            <ArrowDropUpIcon sx={dropstyle(true)} />
          </Box>
        </Box>
        <Box sx={checkstyle}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              flexDirection: "column",
            }}
          >
            {Object.keys(subcategories).map((inp, index) => (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  cursor: "pointer",
                  gap: "4px",
                }}
                onClick={(e) => {
                  changeActive(category, inp, !subcategories[inp]["active"]);
                }}
                key={index}
              >
                <Checkbox
                  checked={subcategories[inp]["active"] === true}
                  sx={{
                    width: "24px",
                    height: "24px",
                    borderRadius: "5.33px",
                    color: "#52975C",
                    "&.Mui-checked": { color: "#52975C" },
                  }}
                />
                <Typography sx={{ fontSize: "14px" }}>{inp}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SelectFilter;
