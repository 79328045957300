import LogoutButton from "./LogoutButton";
import BannerLogo from "../images/banner_logo.png";
import { ROUTES } from "../routes/constants";
import useUser from "../hooks/userHook";
import UsernameButton from "./UsernameButton";
import "../styles/header.css";

const Header = () => {
  const { userInfo, onLogout } = useUser();
  const userName = (userInfo && (userInfo.name || userInfo.given_name)) || "";

  return (
    <nav
      className="navbar navbar-expand-md bg-white justify-content-between align-items-center bg-#FFFDA"
      style={{ borderBottom: "1px solid #4e814e" }}
    >
      <a href={ROUTES.SEARCH}>
        <img id="logo" className="h-5" src={BannerLogo} alt="replace-logo" />
      </a>
      <div>
        <label id="user-label" className="mr-3 h-50 text-dark mb-0">
          <span className="ml-3 text-dark">{userName}</span>
        </label>
        <UsernameButton userName={"Username"}/>
        <LogoutButton onLogout={onLogout} />
      </div>
    </nav>
  );
};

export default Header;