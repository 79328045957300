import {
  StyledBox,
  StyledIcon,
} from "../../styles/LandSearchSideBar/SideSearchIcon.styles";

const SideSearchIcon = ({ Icon, iconStyle = {} }) => {
  return (
    <StyledBox>
      <StyledIcon component={Icon} iconStyle={iconStyle} iconSize={14} />
    </StyledBox>
  );
};

export default SideSearchIcon;
