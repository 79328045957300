import React from "react";
import CreateModal from "./CreateModal";
import { Box, Typography } from "@mui/material";
import Text from "./Text";
import { useState } from "react";
const DeleteModal = ({
  setOpen,
  deleteCollection,
  collectionID,
  setSelected,
}) => {
  const [val, setVal] = useState("");

  const handleDone = () => {
    deleteCollection(val, collectionID);
    setOpen(false);
    setSelected(false);
  };

  return (
    <CreateModal
      height={"40vh"}
      width={"580px"}
      setOpen={setOpen}
      content={
        <Box>
          <Box sx={{ textAlign: "center" }}>
            <Typography
              sx={{ fontSize: "24px", fontWeight: 700, textAlign: "center" }}
            >
              Delete collection
            </Typography>
          </Box>
          <Typography
            sx={{ fontSize: "16px", fontWeight: 500, marginTop: "16px" }}
          >
            Are you sure you want to delete this collection?
          </Typography>
          <Box sx={{ marginTop: "16px" }}>
            <Text label={"Collection name"} setVal={setVal} />
          </Box>
          <Typography
            sx={{ fontSize: "12px", fontWeight: 400, color: "#767D86" }}
          >
            After deleting it, all your bookmark sites will stop being grouped.
          </Typography>

          <Box
            sx={{
              position: "absolute",
              bottom: "32px",
              right: "32px",
              display: "flex",
              gap: "8px",
            }}
          >
            <Box
              sx={{ color: "#D23F63", padding: "16px", cursor: "pointer" }}
              onClick={() => setOpen(false)}
            >
              Cancel
            </Box>
            <Box
              sx={{
                color: "white",
                backgroundColor: "#D23F63",
                borderRadius: "4px",
                padding: "16px",
                cursor: "pointer",
              }}
              onClick={handleDone}
            >
              Delete Collection
            </Box>
          </Box>
        </Box>
      }
    />
  );
};

export default DeleteModal;
