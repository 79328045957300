// useSearchScreen.js

import { useState } from "react";
import useAuth0AccessToken from "./authHook";
import { useLands } from "./landsHook";
import { useModal } from "./modalHook";
import { usePreProcessedSearches } from "./usePreProcessedSearches";
import { useTechnologyOptions, useRegionOptions } from "../utils/selectOptions";

export const useSearchScreen = (step) => {
  const { accessToken } = useAuth0AccessToken();
  const [hasFetched, setHasFetched] = useState(false);

  const {
    getOutputLandsResults,
    isLoadingOutputLands,
    getPreProcessedSearchesInputsResults,
    preProcessedSearchData,
    getFavoriteOutputLandsResults,
    isLoadingFavoriteLands,
  } = useLands();

  const { shouldShowModal, onCloseModal } = useModal();

  usePreProcessedSearches(
    accessToken,
    hasFetched,
    setHasFetched,
    getPreProcessedSearchesInputsResults
  );

  const optionsTechnology = useTechnologyOptions(preProcessedSearchData);
  const optionsSize = [];
  const optionsRegion = useRegionOptions(preProcessedSearchData);

  const onSubmit = (data) => {
    const region = data?.region;
    delete data.region;
    const [lat, lng] = region?.value?.split("*");

    const formattedData = {
      ...data,
      region: {
        label: region?.label,
        value: { lat, lng },
      },
      token: accessToken,
    };

    getOutputLandsResults(formattedData);
  };

  return {
    shouldShowModal,
    onCloseModal,
    optionsTechnology,
    optionsSize,
    optionsRegion,
    isLoadingOutputLands,
    isLoadingFavoriteLands,
    onSubmit,
    getFavoriteOutputLandsResults,
    accessToken,
  };
};
