import { ROUTES } from "../../../routes/constants";
import { call, put } from "redux-saga/effects";
import * as api from "../../api";
import {
  getPreProcessedSearchesInputsSuccess,
  getPreProcessedSearchesInputsFailure,
  getOutputLands,
  getOutputLandsSuccess,
  getOutputLandsFailure,
  getSelectedLandResultsSuccess,
  getSelectedLandResultsFailure,
  saveOutputLandsSuccess,
  saveOutputLandsFailure,
  setInputSearchLabels,
  getFavoriteOutputLands,
  getFavoriteOutputLandsSuccess,
  getFavoriteOutputLandsFailure,
  saveFavoriteOutputLandSuccess,
  saveFavoriteOutputLandFailure,
  setLockedSearchEntity,
} from "../../actions/landsActions";
import { setShowModal, setModalMessage } from "../../actions/modalActions";
import { hasGeometries } from "../../../utils/functions";
import { redirectToLogin } from "../../actions/authActions";
import { STATUS_CODES } from "../../../utils/constants";

export function* getPreProcessedSearchesInputsSaga(action) {
  try {
    // Perform API call to fetch output lands
    const response = yield call(
      api.getPreProcessedSearchesInputs,
      action.payload
    );
    // Dispatch success action with output lands data
    yield put(getPreProcessedSearchesInputsSuccess(response.data));
  } catch (error) {
    if (error.response && error.response.status === STATUS_CODES.UNAUTHORIZED) {
      // Redirect the user to the login page if the status is UNAUTHORIZED
      yield put(redirectToLogin());
    } else {
      yield put(getPreProcessedSearchesInputsFailure(error.message));
    }
  }
}

export function* getOutputLandsSaga(action) {
  try {
    // Perform API call to fetch output lands
    const response = yield call(api.fetchOutputLands, action.payload);

    // Check and convert 'response.data' if it is a string.
    if (typeof response.data === "string") {
      try {
        response.data = JSON.parse(response.data);
      } catch (error) {
        if (
          error.response &&
          error.response.status === STATUS_CODES.UNAUTHORIZED
        ) {
          // Redirect user to login if status code is UNAUTHORIZED
          yield put(redirectToLogin());
        } else {
          console.error("Error parsing JSON:", error);
          // Handle the error
          response.data = null; // Assign a default value if parsing fails
          yield put(getOutputLandsFailure(error.message));
          yield put(setShowModal(true));
          return; // Exit if the JSON cannot be parsed
        }
      }
    }

    // Verify empty answer
    if (!response.data || Object.keys(response.data).length === 0) {
      //Set modal error message
      yield put(setModalMessage("Lands not found"));

      // Open modal with error message
      yield put(setShowModal(true));

      // dispatch failure
      yield put(getOutputLandsFailure("Lands not found"));
      return;
    }

    const geometriesExist = hasGeometries(response.data);
    yield put(setLockedSearchEntity(!geometriesExist));

    // Dispatch success action with output lands data
    yield put(getOutputLandsSuccess(response.data));

    // Prevent adding token in store.
    delete action.payload.token;

    yield put(setInputSearchLabels(action.payload));
  } catch (error) {
    // Dispatch failure action with error message
    yield put(getOutputLandsFailure(error.message));

    //Set modal error message
    yield put(setModalMessage("Failed to fetch output lands"));

    // Open modal with the error message
    yield put(setShowModal(true));
  }
}

export function* saveOutputLandsSaga(action) {
  try {
    // Perform API call to fetch output lands
    const response = yield call(api.fetchSaveOutputLands, action.payload);
    if (response.data?.status === "success") {
      if (action.payload && typeof action.payload.navigate === "function") {
        // Dispatch success action with output lands data
        yield put(saveOutputLandsSuccess(response.data));
        //Redirect to next page
        action.payload.navigate(ROUTES.MAP);
      }
    } else {
      yield put(saveOutputLandsFailure(action.payload));
      yield put(setModalMessage(response?.data?.message));
      yield put(setShowModal(true));
    }
  } catch (error) {
    // Dispatch failure action with error message
    yield put(saveOutputLandsFailure(error.message));
  }
}

export function* getSelectedLandResultsSaga(action) {
  try {
    // Perform API call to fetch land results
    const response = yield call(api.fetchSelectedLandResults, action.payload);

    // Dispatch success action with land results data
    yield put(getSelectedLandResultsSuccess(response.data));
  } catch (error) {
    // Dispatch failure action with error message
    yield put(getSelectedLandResultsFailure(error.message));
  }
}

export function* getFavoriteOutputLandsSaga(action) {
  try {
    // Perform API call to fetch land results
    const response = yield call(api.fetchFavoriteOutputLands, action.payload);

    // Dispatch success action with land results data
    yield put(getFavoriteOutputLandsSuccess(response.data));

    if (response.data?.length > 0) {
      if (action.payload && typeof action.payload.navigate === "function") {
        action.payload.navigate(ROUTES.SEARCH_FAV_LAND_RESULTS);
      }
    } else {
      yield put(setShowModal(true));
    }
  } catch (error) {
    // Dispatch failure action with error message
    yield put(getFavoriteOutputLandsFailure(error.message));
  }
}

export function* saveFavoriteOutputLandSaga(action) {
  try {
    // Perform API call to fetch land results
    const response = yield call(
      api.fetchSaveFavoriteOutputLand,
      action.payload
    );

    // Dispatch success action with land results data
    yield put(saveFavoriteOutputLandSuccess(response.data));

    // It should get favorite output lands only when is coming from favorite land's screen to refresh them after the change is done.
    if (action?.payload?.shouldGetAllFavoriteLands) {
      yield put(getFavoriteOutputLands({ token: action?.payload?.token }));
    } else {
      yield put(getOutputLands({ ...action.payload }));
    }
  } catch (error) {
    // Dispatch failure action with error message
    yield put(saveFavoriteOutputLandFailure(error.message));
  }
}
