import React from "react";
import { Box, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CreateCollectionModal from "./CreateCollectionModal";
import { useState } from "react";
import Folder from "./Folder";
const CollectionsPage = ({
  collectionData,
  addCollection,
  changeCollection,
  deleteCollection,
}) => {
  const [add, setAdd] = useState(false);
  return (
    <Box
      sx={{
        backgroundColor: "#F7FAFC",
        borderTop: "1px solid #C3CCD7",
        position: "relative",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {add && (
        <CreateCollectionModal
          setAddOpen={() => {}}
          setOpen={setAdd}
          addCollection={addCollection}
        />
      )}

      <Box sx={{ width: "800px" }}>
        <Typography
          sx={{
            fontSize: "40px",
            fontWeight: 700,
            marginTop: "40px",
            paddingLeft: "8px",
          }}
        >
          {" "}
          Collections{" "}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "32px",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "12px",
              alignItems: "center",
              cursor: "pointer",
              flexDirection: "column",
            }}
            onClick={() => setAdd(true)}
          >
            <Box
              sx={{
                borderRadius: "4px",
                backgroundColor: "#D5E6D8",
                width: "160px",
                height: "90px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <AddCircleOutlineIcon sx={{ color: "#52975C" }} />
            </Box>
            <Typography
              sx={{ color: "#212121", fontSize: "16px", fontWeight: 500 }}
            >
              {" "}
              Create new collection{" "}
            </Typography>
          </Box>
          {collectionData?.map((inp, index) => (
            <Folder
              inp={inp}
              fDirection={"column"}
              changeCollection={changeCollection}
              deleteCollection={deleteCollection}
              key={index}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default CollectionsPage;
