import { call, put } from "redux-saga/effects";
import * as api from "../../api";
import {
  getUserResultSuccess,
  getUserResultFailure,
} from "../../actions/userActions";
import { redirectToLogin } from "../../actions/authActions";
import { STATUS_CODES } from "../../../utils/constants";

export function* getUser(action) {
  try {
    // Perform API call to fetch land results
    const response = yield call(api.getUser, action.payload);

    // Dispatch success action with land results data
    yield put(getUserResultSuccess(response.data));
  } catch (error) {
    if (error.response && error.response.status === STATUS_CODES.UNAUTHORIZED) {
      // Redirect user to login if status code is UNAUTHORIZED
      yield put(redirectToLogin());
    } else {
      yield put(getUserResultFailure(error.message));
    }
  }
}
