import { takeLatest } from "redux-saga/effects";
import {
  GET_SEARCH_ENTITIES,
  SEARCH_CONTINUE,
  START_SEARCH,
} from "../../actions/types";
import {
  fetchSearchResults,
  continueSearchResults,
  getSearchEntitiesSaga,
} from "./searchSagas";

export default function* searchSaga() {
  yield takeLatest(START_SEARCH, fetchSearchResults);
  yield takeLatest(SEARCH_CONTINUE, continueSearchResults);
  yield takeLatest(GET_SEARCH_ENTITIES, getSearchEntitiesSaga);
}
