import { useDispatch, useSelector } from "react-redux";
import {
  shouldShowModalSelector,
  modalMessageSelector,
} from "../redux/selectors/modal";
import { setShowModal } from "../redux/actions/modalActions";

export const useModal = () => {
  const dispatch = useDispatch();

  const shouldShowModal = useSelector(shouldShowModalSelector);
  const modalMessage = useSelector(modalMessageSelector);

  const onCloseModal = () => {
    dispatch(setShowModal(false));
  };

  return {
    shouldShowModal,
    modalMessage,
    onCloseModal,
  };
};
