import React, { useEffect /*, useState*/ } from "react";
import { LayerMenu } from "../styles/MapLibreScreen.styled";
import Map from "react-map-gl/maplibre";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "maplibre-gl/dist/maplibre-gl.css";
import {
  LayerControls,
  MapPopup,
  DynamicLayers,
  StaticLayers,
} from "../components";

const MapLibreScreen = ({
  selectedLand,
  landDetails,
  rulesDetails,
  showPopup,
  viewState,
  geoJSONData,
  mapRef,
  onMapClick,
  closePopup,
  selectedLandCoord,
  setViewState,
  toggleLayer,
  layersStructure,
  activeLayers,
}) => {
  // const [mapLoaded, setMapLoaded] = useState(false); // Useful for drawing. Uncomment if needed.

  // TODO: Uncomment to show drawing options
  // Hook to handle the ability to draw in map
  // useMapLibreDraw(mapRef, mapLoaded);

  useEffect(() => {
    if (mapRef?.current && geoJSONData) {
      // Get the map instance using mapRef
      const map = mapRef.current?.getMap();

      // Update the map source data with the new geoJSONData
      map.getSource("source-data")?.setData(geoJSONData);
    }
  }, [geoJSONData, mapRef]);

  // Component Render
  return (
    <Map
      ref={mapRef}
      {...viewState}
      // onLoad={() => setMapLoaded(true)} //Useful for drawing. Uncomment if needed.
      onMove={(evt) => setViewState(evt.viewState)} // Use setViewState from the custom hook
      style={{ width: "100vw", height: "100vh" }} // Set width and height to cover full viewport
      mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
      onClick={onMapClick}
    >
      {/* Use StaticLayers to handle static sources and layers */}
      <StaticLayers geoJSONData={geoJSONData} />

      {/* Dynamic layer rendering using the new DynamicLayers component */}
      <DynamicLayers
        layersStructure={layersStructure.regions}
        activeLayers={activeLayers}
      />

      <MapPopup
        selectedLand={selectedLand}
        showPopup={showPopup}
        selectedLandCoord={selectedLandCoord}
        closePopup={closePopup}
        landDetails={landDetails}
        rulesDetails={rulesDetails}
      />

      <LayerMenu>
        <LayerControls
          layersStructure={layersStructure.regions}
          toggleLayer={toggleLayer}
          activeLayers={activeLayers}
        />
      </LayerMenu>
    </Map>
  );
};

export default MapLibreScreen;
