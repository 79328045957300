import styled from "styled-components";
import { ReactComponent as StarIcon } from "../images/star.svg";

export const CardContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 2px 2px 2px rgba(148, 145, 145, 0.25);
  border-radius: 10px;
  min-width: 310px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: 100%;
  max-width: 100%;
  flex: 1;
`;

export const CardOptionSpan = styled.span`
  width: 93px;
  height: 16px;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  margin-left: 5px;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  color: #4d4d4d;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #4d4d4d;
  padding: 10px;
  text-align: center;
`;

export const ShowDetailsLink = styled.a`
  color: #007bff;
  cursor: pointer;
  margin: 10px 0;
  text-decoration: none;
  text-align: left;
  padding-left: 15px;
  &:hover {
    text-decoration: underline;
  }
`;

export const ConditionText = styled.span`
  font-family: "Helvetica";
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  color: #4d4d4d;
`;

export const ResultCardTitleText = styled.h5`
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
`;

export const ResultCardTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #4d4d4d;
  align-items: center;
  padding: 10px;
  text-align: center;
`;

export const StyledStarIcon = styled(StarIcon)`
  cursor: pointer;
  color: ${(props) =>
    props.isSelected
      ? "#ffd700"
      : "#ccc"}; // Dorado si está seleccionado, gris si no
  margin-left: 10px; // Ajusta según sea necesario

  &:hover {
    color: #ffd700; // Cambia el color al pasar el mouse
  }
`;

export const InlineDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 20px;
`;
