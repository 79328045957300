import React from "react";
import Header from "../components/Header";
import Hero from "../components/Hero";
import Footer from "../components/Footer";
import SearchForm from "../components/SearchForm";
import Modal from "../components/Modal";
import { useSearchScreen } from "../hooks/searchScreenHook"; // Import the custom hook
import { FavoriteOutputLands, StyledLink } from "../styles/SearchScreen.styled";
import "../styles/SearchScreen.css";

const SearchScreen = ({ step }) => {
  const {
    shouldShowModal,
    onCloseModal,
    optionsTechnology,
    optionsSize,
    optionsRegion,
    isLoadingOutputLands,
    isLoadingFavoriteLands,
    onSubmit,
    getFavoriteOutputLandsResults,
    accessToken,
  } = useSearchScreen(step);

  return (
    <div className="search-screen-container">
      <Header />
      <Hero
        activeStep={step}
        title="Identify optimal sites to develop your renewable energy project!"
        subtitle="With REplace your project has more chances to get ready to build, and will have maximum ROI"
        gradient={{
          title: { start: "#4E8143", end: "#213727" },
          subtitle: { start: "#4D4D4D", end: "#4D4D4D" },
        }}
      />

      {shouldShowModal && (
        <Modal isOpen={shouldShowModal} onClose={() => onCloseModal()}>
          <h2>Result not found</h2>
          <p>No results were found for this search.</p>
        </Modal>
      )}
      <div className="content d-flex flex-column mt-5">
        <div id="content-container">
          <div id="selection-box">
            <SearchForm
              options={{
                technology: optionsTechnology,
                size: optionsSize,
                region: optionsRegion,
              }}
              isLoading={isLoadingOutputLands || isLoadingFavoriteLands}
              //onSubmit={onSubmit}
            />
            <FavoriteOutputLands>
              <StyledLink
                onClick={() =>
                  getFavoriteOutputLandsResults({ token: accessToken })
                }
              >
                Go to Favorite Lands Section
              </StyledLink>
            </FavoriteOutputLands>
          </div>
        </div>
      </div>
      <Footer isAlternate={true} />
    </div>
  );
};

export default SearchScreen;
