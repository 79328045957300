import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/UsernameButton.css";
import UserIcon from "../images/user_icon.png";
import { Card } from "@mui/material";
import DropDown from "../new_components/DropDown";
import { ROUTES } from "../routes/constants";
const UsernameButton = ({ userName, onClick }) => {
  const [selected, setSelected] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  // Function to handle navigation
  const handleNavigation = (route) => {
    navigate(route);
  };
  //TODO: Add routes while creating new screens, and remove harcoded ones.
  const dropdownData = {
    "My Profile": () => handleNavigation("/profile"),
    "Map Page": () => handleNavigation(ROUTES.MAPPAGE),
    "My Collections": () => handleNavigation(ROUTES.COLLECTIONS),
    Settings: () => handleNavigation("/settings"),
    Help: () => handleNavigation("/help"),
    Logout: () => {
      onClick();
    },
  };

  const handleButtonClick = () => {
    setSelected(!selected);
  };

  return (
    <>
      <Card
        sx={{
          gap: "11px",
          padding: "8px",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          display: "inline-block",
          marginRight: "10px",
          cursor: "pointer",
        }}
        ref={dropdownRef}
        onClick={handleButtonClick}
      >
        <img
          src={UserIcon}
          alt="user-icon"
          className="user-icon"
          style={{ marginRight: "10px" }}
        />
        <span className="user-name">{userName}</span>
        <span className="dropdown-icon">⋮</span>
      </Card>
      <DropDown selected={selected} data={dropdownData} top={"45px"} />
    </>
  );
};

export default UsernameButton;
