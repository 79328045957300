import { Box } from "@mui/material";
import styled from "styled-components";

export const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  background-color: #eeeeee;
  border-radius: 4px;
`;

export const StyledIcon = styled(({ component: Component, ...props }) => (
  <Component {...props} />
))`
  color: #52975c;
  width: ${({ iconSize }) => iconSize}px !important;
  height: ${({ iconSize }) => iconSize}px !important;
  ${({ iconStyle }) => iconStyle && { ...iconStyle }};
`;
