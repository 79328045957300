import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { ChevronLeft } from "@mui/icons-material";
import CardSearch from "./CardSearch";
import CardSearchDetails from "./CardSearchDetails";
import { AnimatePresence, motion } from "framer-motion";
import {
  CardsContainer,
  PaginationWrapper,
  SidebarHeader,
  SidebarHeaderTitle,
  StyledDrawer,
  StyledPagination,
} from "../../styles/LandSearchSideBar/LandSearchSideBar.styles";
import useLandSearchResults from "../../new_hooks/useLandSearchResults";

const LandSearchSideBar = ({ landSearches }) => {
  const { isLandSearchSidebarOpen, toggleLandSearchSideBar } =
    useLandSearchResults();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedSearch, setSelectedSearch] = useState(null);
  const [showDetails, setShowDetails] = useState(false);

  const searchesPerPage = 9;
  const totalPages = Math.ceil(landSearches.length / searchesPerPage);
  const indexOfLastSearch = currentPage * searchesPerPage;
  const indexOfFirstSearch = indexOfLastSearch - searchesPerPage;
  const currentSearches = landSearches.slice(
    indexOfFirstSearch,
    indexOfLastSearch
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleSeeSearch = (search) => {
    setSelectedSearch(search);
    setShowDetails(true);
  };

  const handleBack = () => {
    setShowDetails(false);
    setSelectedSearch(null);
  };
  const variants = {
    hidden: {
      opacity: 0,
      x: 50,
      transition: {
        type: "tween",
        duration: 0.25,
      },
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        type: "tween",
        duration: 0.25,
      },
    },
    exit: {
      opacity: 0,
      x: -50,
      transition: {
        type: "tween",
        duration: 0.25,
      },
    },
  };

  return (
    <StyledDrawer
      variant="persistent"
      anchor="left"
      open={isLandSearchSidebarOpen}
    >
      {/* Sidebar Content */}
      <AnimatePresence mode="wait">
        {showDetails ? (
          <motion.div
            key="details"
            variants={variants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <CardSearchDetails
              search={selectedSearch}
              handleBack={handleBack}
            />
          </motion.div>
        ) : (
          <>
            <SidebarHeader>
              <Box>
                <SidebarHeaderTitle variant="h6" gutterBottom>
                  Search History
                </SidebarHeaderTitle>
                <Typography variant="body2">
                  {landSearches.length} Searches
                </Typography>
              </Box>
              <Tooltip title="Close">
                <IconButton
                  sx={{ marginRight: 3 }}
                  onClick={toggleLandSearchSideBar}
                >
                  <ChevronLeft />
                </IconButton>
              </Tooltip>
            </SidebarHeader>

            <CardsContainer>
              {currentSearches && currentSearches.length > 0 ? (
                currentSearches.map((search, index) => (
                  <CardSearch
                    search={search}
                    index={index}
                    onSeeSearch={handleSeeSearch}
                    key={index}
                  />
                ))
              ) : (
                <Typography variant="body2">No land searches found.</Typography>
              )}
            </CardsContainer>
            <PaginationWrapper>
              <StyledPagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
              />
            </PaginationWrapper>
          </>
        )}
      </AnimatePresence>
    </StyledDrawer>
  );
};

export default LandSearchSideBar;
