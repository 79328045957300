import { takeLatest } from "redux-saga/effects";
import {
  GET_DISPLAYED_LAYERS_RESULTS,
  GET_SUBREGION_RESULTS,
} from "../../actions/types";
import { getDisplayedLayersSaga, getSubRegionSaga } from "./layersSagas";

export default function* layersSaga() {
  yield takeLatest(GET_DISPLAYED_LAYERS_RESULTS, getDisplayedLayersSaga);
  yield takeLatest(GET_SUBREGION_RESULTS, getSubRegionSaga);
}
