import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAuth0AccessToken from "../hooks/authHook";
import {
  saveOutputLands,
  clearResponse,
  saveFavoriteOutputLand,
  getOutputLands,
} from "../redux/actions/landsActions";
import { useModal } from "./modalHook";

import {
  outputLandsResponseSelector,
  saveLandsResponseSelector,
  isLoadingOutputLandsSelector,
  selectedLandsInputsSelector,
  outputLandsRequestSelector,
} from "../redux/selectors/outputLands";

import { isLoadingFavoriteLandsSelector } from "../redux/selectors/favoriteLands";
import { setModalMessage, setShowModal } from "../redux/actions/modalActions";
import { searchParamsSelector } from "../redux/selectors/search";

export const useSearchLandResults = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [shouldShowQuestionInModal, setShouldShowQuestionInModal] =
    useState(false);

  const { accessToken } = useAuth0AccessToken();
  const { shouldShowModal, onCloseModal, modalMessage } = useModal();

  const isLoadingOutputLands = useSelector(isLoadingOutputLandsSelector);
  const outputLandsResponse = useSelector(outputLandsResponseSelector);

  const saveLandsResponse = useSelector(saveLandsResponseSelector);
  const selectedLandsInputs = useSelector(selectedLandsInputsSelector);
  const outputLandsRequest = useSelector(outputLandsRequestSelector);
  const isLoadingFavoriteLands = useSelector(isLoadingFavoriteLandsSelector);
  const searchParams = useSelector(searchParamsSelector);

  const saveOutputLandsSelection = (data) => {
    // navigate is being sent to go to next step on success.
    dispatch(saveOutputLands({ ...data, navigate }));
  };

  const handleNoClick = () => {
    dispatch(setShowModal(false));
    setShouldShowQuestionInModal(false);
  };

  const handleYesClick = () => {
    setShouldShowQuestionInModal(false);
    dispatch(getOutputLands({ ...searchParams, unlockSearchEntity: true }));
    dispatch(setShowModal(false));
  };

  const setQuestion = () => {
    // navigate is being sent to go to next step on success.
    setShouldShowQuestionInModal(true);
    dispatch(setShowModal(true));
    dispatch(
      setModalMessage(
        `Would you like to unlock this and the other ${
          outputLandsResponse?.length - 1
        } lands from this search?`
      )
    );
  };

  useEffect(() => {
    if (
      saveLandsResponse?.status === "error" ||
      saveLandsResponse?.status === "success"
    ) {
      dispatch(clearResponse());
    }
  }, [saveLandsResponse, dispatch]);

  const saveAsFavoriteLand = (data) => {
    dispatch(
      saveFavoriteOutputLand({
        ...outputLandsRequest, // sending initial search inputs to refresh the search, once favorite land has been saved successfully.
        token: accessToken,
        is_favorite: data.is_favorite,
        output_land_id: data.output_land_id,
      })
    );
  };

  return {
    saveOutputLandsSelection,
    isLoadingOutputLands,
    outputLandsResponse,
    saveLandsResponse,
    selectedLandsInputs,
    saveAsFavoriteLand,
    isLoadingFavoriteLands,
    onCloseModal,
    shouldShowModal,
    shouldShowQuestionInModal,
    modalMessage,
    setQuestion,
    handleNoClick,
    handleYesClick,
  };
};
