import React from "react";
import HeaderBar from "../new_components/HeaderBar";
import SearchBar from "../new_components/SearchBar";

const PageLayout = ({
  children,
  tab,
  changeTab,
  search,
  isSearching,
  setViewState,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        minWidth: "800px",
        overflowX: "scroll",
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <HeaderBar
        tab={tab}
        changeTab={changeTab}
        search={search}
        isSearching={isSearching}
        setViewState={setViewState}
      />
      {!search && (
        <SearchBar
          search={search}
          isSearching={isSearching}
          setViewState={setViewState}
        />
      )}
      {children}
    </div>
  );
};

export default PageLayout;
