import { call, put } from "redux-saga/effects";
import * as api from "../../api";
import {
  getDisplayedLayersResultsSuccess,
  getDisplayedLayersResultsFailure,
  getSubRegionResultsSuccess,
  getSubRegionResultsFailure,
} from "../../actions/layersActions";
import { redirectToLogin } from "../../actions/authActions";
import { STATUS_CODES } from "../../../utils/constants";

export function* getDisplayedLayersSaga(action) {
  try {
    // Perform API call to fetch output lands
    const response = yield call(api.fetchDisplayedLayers, action.payload);
    // Dispatch success action with output lands data
    yield put(getDisplayedLayersResultsSuccess(response.data));

    // Prevent adding token in store.
    delete action.payload.token;
  } catch (error) {
    if (error.response && error.response.status === STATUS_CODES.UNAUTHORIZED) {
      // Redirect the user to the login page if the status is UNAUTHORIZED
      yield put(redirectToLogin());
    } else {
      yield put(getDisplayedLayersResultsFailure(error.message));
    }
  }
}

export function* getSubRegionSaga(action) {
  try {
    const response = yield call(api.fetchSubRegion, action.payload);

    yield put(getSubRegionResultsSuccess(response.data));

    delete action.payload.token;
  } catch (error) {
    yield put(getSubRegionResultsFailure(error.message));
  }
}
