// LayerControls.js
import React, { useState } from "react";

const LayerControls = ({ layersStructure, toggleLayer, activeLayers }) => {
  const [expandedItems, setExpandedItems] = useState({});

  // Toggle expansion of menu items
  const toggleExpand = (itemId) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [itemId]: !prevState[itemId],
    }));
  };

  // Render checkbox for each layer
  const LayerCheckbox = ({ layerName, isActive, onToggle }) => {
    return (
      <label>
        <input
          type="checkbox"
          checked={isActive}
          onChange={() => onToggle(layerName)}
        />
        {layerName}
      </label>
    );
  };

  // Recursive function to render a structured layer menu
  const renderMenuRecursive = (structure, path = "") => {
    return Object.entries(structure).map(([key, value]) => {
      const itemId = path ? `${path}.${key}` : key;
      const isExpanded = expandedItems[itemId];

      if (typeof value === "object" && value !== null) {
        if (Array.isArray(value)) {
          // If value is an array, render checkboxes for each layer
          return (
            <div key={itemId} style={{ paddingLeft: "20px" }}>
              <button onClick={() => toggleExpand(itemId)}>
                {key} {isExpanded ? "-" : "+"}
              </button>
              {isExpanded &&
                value.map((layer) => (
                  <div key={layer.id}>
                    <LayerCheckbox
                      layerName={layer.name}
                      isActive={activeLayers.includes(layer.name)}
                      onToggle={toggleLayer}
                    />
                  </div>
                ))}
            </div>
          );
        } else {
          // If value is an object, render a nested menu
          return (
            <div key={itemId}>
              <button onClick={() => toggleExpand(itemId)}>
                {key} {isExpanded ? "-" : "+"}
              </button>
              {isExpanded && (
                <div style={{ paddingLeft: "20px" }}>
                  {renderMenuRecursive(value, itemId)}
                </div>
              )}
            </div>
          );
        }
      }
      return null;
    });
  };

  return <div>{renderMenuRecursive(layersStructure)}</div>;
};

export default LayerControls;
