const landSearchSelector = (state) => state.landSearch;

export const selectLandSearches = (state) =>
  landSearchSelector(state)?.landSearches;
export const selectLandSearchStatus = (state) =>
  landSearchSelector(state)?.status;
export const selectLandSearchError = (state) =>
  landSearchSelector(state)?.error;
export const selectIsLandSearchSidebarOpen = (state) =>
  landSearchSelector(state)?.openedLandsSearchSideBar;
