import { Box, Button, Card, Typography } from "@mui/material";
import styled from "styled-components";

export const StyledCardContainer = styled(Card)`
  && {
    margin-bottom: 16px;
    box-shadow: none;
    border: 1px solid #dadada;
    border-radius: 4px;
  }
`;

export const StyledCardItem = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
`;

export const SpacedStyledCardItemContainer = styled(StyledCardItem)`
  justify-content: space-between;
`;

export const SpacedStyledCardItem = styled(Box)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const StyledSeeSearchButton = styled(Button)`
  width: 100%;
  background-color: #52975c !important;
  color: #ffffff;

  &:active {
    background-color: #99c29f !important;
  }

  &:hover {
    background-color: #335738 !important;
  }
`;

export const LocationTypography = styled(Typography)`
  max-width: 390px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
