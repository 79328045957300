import { all, fork } from "redux-saga/effects";
import landsSaga from "./outputLands";
import searchSaga from "./search";
import layersSaga from "./layers";
import userSaga from "./user";
import collectionsSagas from "./collections";
import analyzeLandSaga from "./analyzeLand";
import landSearchSaga from "./landSearches";

export default function* rootSaga() {
  yield all([
    fork(landsSaga),
    fork(layersSaga),
    fork(userSaga),
    fork(searchSaga),
    fork(collectionsSagas),
    fork(analyzeLandSaga),
    // Add other sagas here
    fork(landSearchSaga),
  ]);
}
