import React from "react";
import BannerLogo from "../images/new-logo.png";
import BannerBlackLogo from "../images/new-black-logo.png";
import { useState, useEffect } from "react";
import Back1 from "../images/back1.mp4";
import Back2 from "../images/back2.mp4";
import Back3 from "../images/back3.mp4";
import Back4 from "../images/back4.mp4";
import { Box, Typography, Button } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PlaceIcon from "@mui/icons-material/Place";
import { useAuth0 } from "@auth0/auth0-react";
const LandingPage = () => {
  const [filled, setFilled] = useState([0, 0, 0, 0]);
  const [isRunning, setIsRunning] = useState(0);
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (isRunning === 3 && filled[isRunning] >= 100) {
      var vid1 = document.getElementById("vid1");
      vid1.currentTime = 0;
      setIsRunning(0);
      setFilled([0, 0, 0, -0.5]); //Probably should find a better solution to this phenomenon
    } else if (isRunning < 3 && filled[isRunning] >= 100) {
      var vid = document.getElementById("vid" + (isRunning + 2));
      vid.currentTime = 0;
      setIsRunning((prevRun) => prevRun + 1);
    }

    setTimeout(
      () =>
        setFilled((prev) => {
          const newFilled = [...prev];
          newFilled[isRunning] += 0.5;
          return newFilled;
        }),
      20
    );

    return () => clearTimeout();
  }, [filled, isRunning]);

  const style = {
    height: "9px",
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    borderRadius: "1000px",
    flex: 1,
  };
  const progress = (num) => {
    return {
      height: "100%",
      width: `${filled[num]}%`,
      transition: "width 10ms",
      borderRadius: "1000px",
      opacity: 1,
      backgroundColor: "white",
    };
  };
  const vidstyle = (num) => ({
    position: "absolute",
    height: "100%",
    width: "100%",
    objectFit: "cover",
    zIndex: 1,
    borderRadius: "16px",
    transition: " all .3s ease-in-out",
    opacity: num - 1 === isRunning ? 1 : 0,
  });

  const titles = [
    "Specify the Type of Project",
    "Indicate the Project Size",
    "Select the Desired Region",
    "Optimize your Site Prospection",
  ];
  const subtitles = [
    "Insert your desired project type—solar, wind, storage, or more. REplace's software accommodates a wide range of renewable energy project types.",
    "Specify the size or capacity of your project, from small-scale to utility-scale. With this information, REplace provides customized suggestions that align with your unique requirements.",
    "Select your project's implementation region. REplace considers this input to offer you the best-suited site options.",
    "REplace enables comprehensive site prospecting, allowing you to explore, compare, and select the ideal sites. Make informed decisions to enhance your project's success and portfolio value.",
  ];

  const textstyle = (num) => ({
    transition: " all .3s ease-in-out",
    opacity: num === isRunning ? 1 : 0,
  });

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        bgcolor: "#EAECE3",
        display: "flex",
        minHeight: "580px",
        overflowY: "scroll",
      }}
    >
      <Box
        sx={{
          height: "100%",
          width: "45vw",
          borderTopRightRadius: "34px",
          padding: "12px",
          borderBottomRightRadius: "34px",
          backgroundColor: "#DDE6C0",
          overflow: "hidden",
          display: { xs: "none", md: "block" },
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            overflow: "hidden",
            zIndex: 0,
            position: "relative",
          }}
        >
          <video
            src={Back1}
            id="vid1"
            data-testid="vid1"
            autoPlay
            loop
            muted
            style={vidstyle(1)}
          />
          <video
            src={Back2}
            id="vid2"
            autoPlay
            loop
            muted
            style={vidstyle(2)}
          />
          <video
            src={Back3}
            id="vid3"
            autoPlay
            loop
            muted
            style={vidstyle(3)}
          />
          <video
            src={Back4}
            id="vid4"
            autoPlay
            loop
            muted
            style={vidstyle(4)}
          />

          <Box
            sx={{
              width: "100%",
              height: "100%",
              padding: "20px",
              overflow: "hidden",
              position: "relative",
              borderRadius: "16px",
              backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%)`,
              backgroundSize: "cover",
              zIndex: 1,
              backgroundPosition: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              {[0, 1, 2, 3].map((inp) => (
                <Box
                  sx={{
                    display: "flex",
                    gap: "30px",
                    flexDirection: "column",
                    position: "absolute",
                    bottom: "80px",
                    zIndex: 5,
                  }}
                  key={inp}
                >
                  <Typography
                    color={"white"}
                    fontSize={"60px"}
                    fontWeight={700}
                    style={textstyle(inp)}
                  >
                    {titles[inp]}
                  </Typography>
                  <Typography
                    color={"white"}
                    fontSize={"25px"}
                    fontWeight={400}
                    style={textstyle(inp)}
                  >
                    {" "}
                    {subtitles[inp]}
                  </Typography>
                </Box>
              ))}

              <Box sx={{ zIndex: 5 }}>
                <img
                  src={BannerLogo}
                  width={"250px"}
                  alt="replace-logo"
                  style={{ position: "relative" }}
                />
              </Box>

              <Box sx={{ marginTop: "8vh", display: "flex", gap: "12px" }}>
                {[0, 1, 2, 3].map((inp) => (
                  <div style={style} key={inp}>
                    <div style={progress(inp)} />
                  </div>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          position: "relative",
          width: { xs: "100vw", md: "55vw" },
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: { xs: "block", md: "none" },
            position: "absolute",
            top: "16px",
            left: "8px",
          }}
        >
          <img
            src={BannerBlackLogo}
            width={"115px"}
            alt="replace-logo"
            style={{ position: "relative" }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: { xs: "60vw", md: "30vw" },
            flexDirection: "column",
            alignItems: "center",
            gap: "40px",
          }}
        >
          <Typography variant="h3" fontWeight={600} fontSize={"32px"}>
            {" "}
            Get started{" "}
          </Typography>
          <Button variant="long" onClick={() => loginWithRedirect()}>
            {" "}
            Log in{" "}
          </Button>
          <Typography color={"#47784E"}>
            {" "}
            Don't have access?{" "}
            <a>
              {" "}
              <u>Request here </u>{" "}
            </a>{" "}
          </Typography>
        </Box>
        <Typography
          sx={{ marginTop: "40px", position: "relative", color: "#636363" }}
        >
          {" "}
          <MailOutlineIcon /> info@renewableenergy.place | <PlaceIcon /> Tel
          Aviv, Israel
        </Typography>

        <Typography
          sx={{
            position: "absolute",
            bottom: "4vh",
            width: "100%",
            textAlign: "center",
            fontSize: "16px",
            color: "#636363",
          }}
        >
          {" "}
          © Copyright 2024 REplace, All Rights Reserved{" "}
        </Typography>
      </Box>
    </Box>
  );
};

export default LandingPage;
