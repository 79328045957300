import styled from "styled-components";

export const FavoriteOutputLands = styled.div`
  text-align: center;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledLink = styled.a`
  cursor: pointer;
  text-decoration: underline;
  color: #4e8143;
  font-weight: bold;
`;
