import {
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { StyledSpinner } from "../styles/Spinner.styled";

const SelectSearch = ({
  val,
  changeVal,
  textHeader,
  textSelect,
  last = false,
  items,
  small,
  load,
}) => {
  return (
    <Box
      sx={{
        flex: 1,
        position: "relative",
        display: "flex",
        gap: "0px",
        flexDirection: "column",
        width: small ? "10vw" : null,
        minWidth: small ? "130px" : null,
        maxWidth: small ? "200px" : null,
      }}
    >
      <Typography
        sx={{
          fontSize: "12px",
          color: "#212121",
          paddingLeft: small ? "12px" : null,
          padding: !small ? "0px 12px" : null,
          opacity: items.length === 0 ? 0.5 : 1,
        }}
      >
        {" "}
        {textHeader}{" "}
      </Typography>
      {!load ? (
        <FormControl
          fullWidth
          size={small ? "small" : null}
          disabled={items.length === 0}
          sx={{
            "& fieldset": { border: "none" },
            opacity: items.length === 0 ? 0.5 : 1,
          }}
        >
          {val === undefined && !small && items.length > 0 && (
            <InputLabel
              id="project-type"
              shrink={false}
              focused={false}
              disableAnimation
              sx={{ color: "#818992", fontSize: "14px" }}
            >
              {" "}
              {textSelect}
            </InputLabel>
          )}
          <Select
            labelId="project-type"
            label="Select project type"
            onChange={changeVal}
            value={val !== undefined ? val : ""}
            sx={{
              ".MuiSvgIcon-root ": { fill: "#2D3748 !important" },
              fontSize: "14px",
            }}
          >
            {items.map((inp, index) => (
              <MenuItem value={index} sx={{ fontSize: "14px" }} key={index}>
                {" "}
                {inp.label}{" "}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Box sx={{ justifyContent: "center", display: "flex" }}>
          <StyledSpinner
            style={{
              height: small ? "28px" : "32px",
              width: small ? "28px" : "32px",
              color: "#52975C",
              opacity: 0.7,
            }}
          />
        </Box>
      )}
      {!last && (
        <div
          style={{
            position: "absolute",
            right: small ? "-12px" : "-8px",
            width: "1px",
            height: small ? "100%" : "70%",
            backgroundColor: "#DDDDDD",
            top: small ? null : "15%",
          }}
        />
      )}
    </Box>
  );
};

export default SelectSearch;
