import styled from "styled-components";

// Styled component for the LayerMenu
export const LayerMenu = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999;
  background-color: white;
  padding: 10px;
  max-height: calc(
    100vh - 20px
  ); /* Subtract 20px to leave space for the menu borders. */
  overflow-y: auto; /* Enable vertical scrolling when the content exceeds the maximum height. */
`;
