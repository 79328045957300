import React from "react";
import { Box, Typography, Slider } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { StyledSpinner } from "../styles/Spinner.styled";
import { PROJECT_SIZES_UNIT_NAMES } from "../utils/constants";

const ProjectSize = ({
  small,
  selected,
  setSelected,
  projectType,
  config,
  sizeUnit,
  toggleSizeUnit,
}) => {
  const getAriaValueText = (value) => {
    return sizeUnit === PROJECT_SIZES_UNIT_NAMES.MW
      ? `${value} MW`
      : `${value} acres`;
  };

  const getCurrentValue = () => {
    const currentConfig = config[sizeUnit?.toLowerCase()];

    return currentConfig.value !== undefined
      ? `${currentConfig.value} ${sizeUnit}`
      : "Select project size";
  };

  return (
    <Box sx={{ flex: 1, position: "relative" }}>
      <Box
        sx={{
          flex: 1,
          position: "relative",
          display: "flex",
          gap: "0px",
          flexDirection: "column",
          width: small ? "10vw" : null,
          minWidth: small ? "130px" : null,
          maxWidth: small ? "200px" : null,
          cursor: "pointer",
        }}
        onClick={() => setSelected(!selected)}
      >
        <Typography
          sx={{
            fontSize: "12px",
            color: "#212121",
            paddingLeft: small ? "12px" : null,
            padding: !small ? "0px 12px" : null,
          }}
        >
          Project Size
        </Typography>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "12px",
          }}
        >
          <Typography
            sx={{
              color: "#818992",
              fontSize: "14px",
              paddingLeft: small ? "12px" : null,
              padding: !small ? "0px 12px" : null,
            }}
          >
            {getCurrentValue()}
          </Typography>

          {selected ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </Box>
      </Box>
      {selected && (
        <Box
          sx={{
            position: "absolute",
            backgroundColor: "white",
            marginTop: "12px",
            zIndex: 100,
            padding: "12px 12px 12px 12px",
            borderRadius: "6px",
            marginLeft: "-11px",
            width: "115%",
            minWidth: "200px",
          }}
        >
          <Box
            sx={{
              display: "inline-flex",
              background: "white",
              marginTop: "8px",
              width: "100%",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "inline-flex",
                  borderRadius: "1000px",
                  backgroundColor: "lightgrey",
                  padding: "4px 4px",
                  gap: "8px",
                }}
              >
                <Typography
                  sx={{
                    padding: "6px 24px",
                    backgroundColor:
                      sizeUnit === PROJECT_SIZES_UNIT_NAMES.MW ? "white" : null,
                    borderRadius: "1000px",
                    fontSize: "14px",
                    fontWeight: 500,
                    cursor: "pointer",
                  }}
                  onClick={() => toggleSizeUnit()}
                >
                  MW
                </Typography>
                <Typography
                  sx={{
                    padding: "6px 24px",
                    backgroundColor:
                      sizeUnit === PROJECT_SIZES_UNIT_NAMES.ACRES
                        ? "white"
                        : null,
                    borderRadius: "1000px",
                    fontSize: "14px",
                    fontWeight: 500,
                    cursor: "pointer",
                  }}
                  onClick={() => toggleSizeUnit()}
                >
                  Acres
                </Typography>
              </Box>
            </Box>
            {sizeUnit === PROJECT_SIZES_UNIT_NAMES.MW &&
              (projectType !== undefined ? (
                <Box sx={{ padding: "25px 12px" }}>
                  <Slider
                    value={
                      config.mw.value !== undefined
                        ? config.mw.value
                        : config.mw.min
                    }
                    defaultValue={config.mw.min}
                    onChange={(e, newValue) => config.mw.setValue(newValue)}
                    min={config.mw.min}
                    step={config.mw.step}
                    max={config.mw.max}
                    marks={config.mw.marks}
                    valueLabelDisplay="on"
                    getAriaValueText={getAriaValueText}
                    aria-label="Always visible"
                    sx={{
                      "& .MuiSlider-rail": { color: "#52975C" },
                      "& .MuiSlider-track": { color: "#52975C" },
                      "& .MuiSlider-thumb": { color: "white" },
                      "& .MuiSlider-valueLabel": {
                        backgroundColor: "#52975C",
                        color: "white",
                        fontSize: "0.75rem",
                        padding: "2px 4px",
                        top: -6,
                        "&:before": {
                          height: 6,
                        },
                      },
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 500,
                      textAlign: "center",
                      marginTop: "-5px",
                    }}
                  >
                    Megawatts
                  </Typography>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <StyledSpinner
                    style={{ color: "#52975C", width: "24px", height: "24px" }}
                  />
                </Box>
              ))}
            {sizeUnit === PROJECT_SIZES_UNIT_NAMES.ACRES && (
              <Box sx={{ padding: "25px 12px" }}>
                <Slider
                  onChange={(e, newValue) => config.acres.setValue(newValue)}
                  value={
                    config.acres.value !== undefined
                      ? config.acres.value
                      : config.acres.min
                  }
                  defaultValue={config.acres.min}
                  min={config.acres.min}
                  step={config.acres.step}
                  max={config.acres.max}
                  valueLabelDisplay="on"
                  marks={config.acres.marks}
                  getAriaValueText={getAriaValueText}
                  sx={{
                    "& .MuiSlider-rail": { color: "#52975C" },
                    "& .MuiSlider-track": { color: "#52975C" },
                    "& .MuiSlider-thumb": { color: "white" },
                    "& .MuiSlider-valueLabel": {
                      backgroundColor: "#52975C",
                      color: "white",
                      fontSize: "0.75rem",
                      padding: "2px 4px",
                      top: -6,
                      "&:before": {
                        height: 6,
                      },
                    },
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ProjectSize;
