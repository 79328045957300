import BannerLogo from "../images/banner_logo.png";
import LightningVector from "../images/Vector.png";
import LoginButton from "../components/LoginButton";
import "../styles/Login.css";

const Login = () => {
  return (
    <div className="login-screen">
      <div className="left-column">
        <img id="rectangle-vector" alt="" src={LightningVector} />
      </div>
      <div className="right-column">
        <div className="login-container">
          <img id="login-form-logo" src={BannerLogo} alt="replace-logo" />
          <div>
            <p className="login-banner-title">
              Login to Your <br />
              Account
            </p>
            <p className="login-banner-subtitle">
              Please enter your credentials to access your account. Your journey
              begins now!
            </p>
          </div>
          <LoginButton />
        </div>
      </div>
    </div>
  );
};

export default Login;
