import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import {
  getCollectionResults,
  getUserCollectionResults,
  addLandCollection,
  deleteLandCollection,
  deleteCollection,
  renameCollection,
  addCollection,
} from "../redux/actions/collectionActions";

import {
  userCollectionSelector,
  collectionSelector,
  deleteIsLoadingSelector,
  renameIsLoadingSelector,
} from "../redux/selectors/collections";

import { useCallback, useEffect } from "react";
import useAuth0AccessToken from "../hooks/authHook";

export const useCollections = () => {
  const dispatch = useDispatch();
  const { accessToken } = useAuth0AccessToken();

  // TODO: Missing to implement getCollection logic to get lands from a specific collection --> checkout api/getCollection to see what params should send. A new screen should be created with the lands that we receive from this API call.
  const getCollection = (params) =>
    dispatch(getCollectionResults({ ...params }));
  const addLandCollect = (params) =>
    dispatch(addLandCollection({ ...params, token: accessToken }));
  const deleteLandCollect = (params) =>
    dispatch(deleteLandCollection({ ...params }));
  const deleteCollect = (params) =>
    dispatch(deleteCollection({ ...params, token: accessToken }));
  const renameCollect = (params) =>
    dispatch(renameCollection({ ...params, token: accessToken }));
  const addCollect = (params) =>
    dispatch(addCollection({ ...params, token: accessToken }));

  const userCollection = useSelector(userCollectionSelector);

  // TODO: Missing to implement getCollection logic to receive values in collectionSelector
  const collections = useSelector(collectionSelector);
  const getUserCollection = useCallback(() => {
    if (accessToken) {
      dispatch(getUserCollectionResults({ token: accessToken }));
    }
  }, [accessToken, dispatch]);

  useEffect(() => {
    if (accessToken) {
      getUserCollection();
    }
  }, [accessToken, dispatch, getUserCollection]);

  const aCollection = (folderName, description) => {
    addCollect({ collection_name: folderName, description: description });
  };

  const addToFolder = (collection_id, land_id) => {
    addLandCollect({ collection_id: collection_id, land_id: land_id });
  };

  const changeCollection = (id, new_name) => {
    renameCollect({ collection_id: id, new_name: new_name });
  };

  const delCollection = (name, id) => {
    const col = userCollection.filter((inp) => inp.id === id);
    if (col.length > 0 && col[0]["name"] === name) {
      deleteCollect({ collection_id: id });
    }
  };

  return {
    userCollection,
    aCollection,
    delCollection,
    changeCollection,
    addToFolder,
  };
};
