import React from "react";
import { Box, Typography } from "@mui/material";
import BannerBlackLogo from "../images/new-black-logo.png";
import CreateModal from "./CreateModal";

const AnalyzeLandModal = ({ setOpen, landID }) => {
  return (
    <CreateModal
      height={"45vh"}
      width={"450px"}
      setOpen={setOpen}
      content={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            height: "100%",
            gap: "24px",
          }}
        >
          <img src={BannerBlackLogo} width={"200px"} alt="Banner Black Logo" />
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "24px",
              color: "#212121",
              textAlign: "center",
            }}
          >
            {" "}
            Analyze data coming soon!{" "}
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "16px",
              color: "#212121",
              textAlign: "center",
            }}
          >
            {" "}
            We are working on this feature and it should be available soon in
            the future. With this feature you will be able to analyze a piece of
            land to see if it is suitable to create a project.{" "}
          </Typography>
        </Box>
      }
    />
  );
};

export default AnalyzeLandModal;
