import {
  ADD_LAND_COLLECTION_FAILURE,
  ADD_LAND_COLLECTION_SUCCESS,
  GET_COLLECTION_FAILURE,
  GET_COLLECTION_RESULTS,
  GET_COLLECTION_SUCCESS,
  GET_USER_COLLECTION_FAILURE,
  GET_USER_COLLECTION_RESULTS,
  GET_USER_COLLECTION_SUCCESS,
  ADD_COLLECTION,
  ADD_COLLECTION_SUCCESS,
  ADD_COLLECTION_FAILURE,
  ADD_LAND_COLLECTION,
  DELETE_COLLECTION,
  DELETE_COLLECTION_SUCCESS,
  DELETE_COLLECTION_FAILURE,
  DELETE_LAND_COLLECTION,
  DELETE_LAND_COLLECTION_SUCCESS,
  DELETE_LAND_COLLECTION_FAILURE,
  RENAME_COLLECTION,
  RENAME_COLLECTION_SUCCESS,
  RENAME_COLLECTION_FAILURE,
} from "../actions/types";

function collectionsReducer(state = {}, action) {
  switch (action.type) {
    case ADD_COLLECTION:
      return {
        ...state,
        addCollection: {
          ...state.addCollection,
          request: action.payload,
          isLoading: true,
        },
      };
    case ADD_COLLECTION_SUCCESS:
      return {
        ...state,
        addCollection: {
          ...state.addCollection,
          responseSave: action.payload,
          isLoading: false,
        },
      };
    case ADD_COLLECTION_FAILURE:
      return {
        ...state,
        addCollection: {
          ...state.addCollection,
          isLoading: false,
        },
      };
    case ADD_LAND_COLLECTION:
      return {
        ...state,
        addLandCollection: {
          ...state.addLandCollection,
          request: action.payload,
          isLoading: true,
        },
      };
    case ADD_LAND_COLLECTION_SUCCESS:
      return {
        ...state,
        addLandCollection: {
          ...state.addLandCollection,
          responseSave: action.payload,
          isLoading: false,
        },
      };
    case ADD_LAND_COLLECTION_FAILURE:
      return {
        ...state,
        addLandCollection: {
          ...state.addLandCollection,
          isLoading: false,
        },
      };
    case GET_COLLECTION_RESULTS:
      return {
        ...state,
        getCollection: {
          ...state.getCollection,
          request: action.payload,
          isLoading: true,
        },
      };
    case GET_COLLECTION_SUCCESS:
      return {
        ...state,
        getCollection: {
          ...state.getCollection,
          response: action.payload,
          isLoading: false,
        },
      };
    case GET_COLLECTION_FAILURE:
      return {
        ...state,
        getCollection: {
          ...state.getCollection,
          response: [],
          isLoading: false,
        },
      };
    case GET_USER_COLLECTION_RESULTS:
      return {
        ...state,
        getUserCollection: {
          ...state.getUserCollection,
          request: action.payload,
          isLoading: true,
        },
      };
    case GET_USER_COLLECTION_SUCCESS:
      return {
        ...state,
        getUserCollection: {
          ...state.getUserCollection,
          response: action.payload,
          isLoading: false,
        },
      };
    case GET_USER_COLLECTION_FAILURE:
      return {
        ...state,
        getUserCollection: {
          ...state.getUserCollection,
          response: [],
          isLoading: false,
        },
      };
    case DELETE_COLLECTION:
      return {
        ...state,
        deleteCollection: {
          ...state.deleteCollection,
          isLoading: true,
        },
      };
    case DELETE_COLLECTION_SUCCESS:
      return {
        ...state,
        deleteCollection: {
          ...state.deleteCollection,
          response: action.payload,
          isLoading: false,
        },
      };
    case DELETE_COLLECTION_FAILURE:
      return {
        ...state,
        deleteCollection: {
          ...state.deleteCollection,
          response: [],
          isLoading: false,
        },
      };
    case DELETE_LAND_COLLECTION:
      return {
        ...state,
        deleteLandCollection: {
          ...state.deleteLandCollection,
          isLoading: true,
        },
      };
    case DELETE_LAND_COLLECTION_SUCCESS:
      return {
        ...state,
        deleteLandCollection: {
          ...state.deleteLandCollection,
          response: action.payload,
          isLoading: false,
        },
      };
    case DELETE_LAND_COLLECTION_FAILURE:
      return {
        ...state,
        deleteLandCollection: {
          ...state.deleteLandCollection,
          response: [],
          isLoading: false,
        },
      };
    case RENAME_COLLECTION:
      return {
        ...state,
        renameCollection: {
          ...state.renameCollection,
          isLoading: true,
        },
      };
    case RENAME_COLLECTION_SUCCESS:
      return {
        ...state,
        renameCollection: {
          ...state.renameCollection,
          response: action.payload,
          isLoading: false,
        },
      };
    case RENAME_COLLECTION_FAILURE:
      return {
        ...state,
        renameCollection: {
          ...state.renameCollection,
          response: [],
          isLoading: false,
        },
      };

    default:
      return state;
  }
}

export default collectionsReducer;
