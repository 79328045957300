import styled, { keyframes } from "styled-components";
import { StyledSpinner } from "../styles/Spinner.styled";

export const SpinnerAnimation = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const StyledSpinnerMapResults = styled(StyledSpinner)`
  margin-bottom: 15px;
`;

export const MessageContainer = styled.div`
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
