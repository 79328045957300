import { withAuthenticationRequired } from "@auth0/auth0-react";
import { ROUTES } from "./constants";
import SearchLandResults from "../pages/SearchLandResults";
import FavoriteLandResults from "../pages/FavoriteLandResults";
import Login from "../pages/Login";
import MapScreen from "../pages/MapScreen";
import SearchScreen from "../pages/SearchScreen";
import LandingPage from "../pages/LandingPage";
import MapPage from "../pages/MapPage";
import Collections from "../pages/Collections";
const ProtectedRoute = ({ component, ...args }) => {
  const Component = withAuthenticationRequired(component, args);
  return <Component {...args} />;
};

export const routesArray = [
  {
    id: ROUTES.LOGIN,
    path: ROUTES.LOGIN,
    element: <Login />,
  },
  {
    id: ROUTES.SEARCH,
    path: ROUTES.SEARCH,
    element: <ProtectedRoute component={MapPage} step={1} />,
    // element: <ProtectedRoute component={SearchScreen} step={1} />,
  },
  {
    //TODO: Remove
    id: ROUTES.SEARCH_LAND_RESULTS,
    path: ROUTES.SEARCH_LAND_RESULTS,
    element: <ProtectedRoute component={SearchLandResults} step={2} />,
  },
  {
    id: ROUTES.SEARCH_FAV_LAND_RESULTS,
    path: ROUTES.SEARCH_FAV_LAND_RESULTS,
    element: <ProtectedRoute component={FavoriteLandResults} step={2} />,
  },
  {
    id: ROUTES.MAP,
    path: ROUTES.MAP,
    element: <ProtectedRoute component={MapScreen} step={3} />,
  },
  {
    id: ROUTES.LANDINGPAGE,
    path: ROUTES.LANDINGPAGE,
    element: <LandingPage />,
  },
  {
    id: ROUTES.MAPPAGE,
    path: ROUTES.MAPPAGE,
    element: <ProtectedRoute component={MapPage} step={0} />,
  },
  {
    id: ROUTES.OLDSEARCH,
    path: ROUTES.OLDSEARCH,
    element: <ProtectedRoute component={SearchScreen} />,
  },
  {
    id: ROUTES.COLLECTIONS,
    path: ROUTES.COLLECTIONS,
    element: <ProtectedRoute component={Collections} />,
  },
];
