import styled from "styled-components";

const CardConditionDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  text-align: center;

  width: 69px;
  height: 34px;

  background: ${(props) => {
    let color = "";
    switch (props.condition) {
      case "Excellent":
        color = "#A3DBA0";
        break;
      case "Medium":
        color = "#F7F286";
        break;
      case "Low":
        color = "#FFADAD";
        break;
      default:
        color = "#000000";
    }
    return color;
  }};
  border-radius: 5px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
`;

export default CardConditionDiv;
