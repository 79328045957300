import { SET_SHOW_MODAL, SET_MODAL_MESSAGE } from "./types";

export const setShowModal = (payload) => ({
  type: SET_SHOW_MODAL,
  payload,
});
export const setModalMessage = (payload) => ({
  type: SET_MODAL_MESSAGE,
  payload,
});
