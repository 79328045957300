import React from "react";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CreateModal = ({ content, height, width, setOpen }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        inset: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        position: "fixed",
        zIndex: 10,
      }}
    >
      <Box
        sx={{
          height: { height },
          width: { width },
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: "24px 32px",
          backgroundColor: "white",
          zIndex: 20,
          borderRadius: "16px",
        }}
      >
        <CloseIcon
          sx={{ cursor: "pointer", color: "#21212180", opacity: 0.5 }}
          onClick={() => setOpen(false)}
        />
        {content}
      </Box>
    </Box>
  );
};

export default CreateModal;
