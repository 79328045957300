import {
  REDIRECT_TO_LOGIN,
  CLEAR_REDIRECT_TO_LOGIN,
  RESET_STORE,
} from "./types";

export const resetStore = (payload) => ({
  type: RESET_STORE,
  payload,
});
export const redirectToLogin = (payload) => ({
  type: REDIRECT_TO_LOGIN,
  payload,
});
export const clearRedirectToLogin = (payload) => ({
  type: CLEAR_REDIRECT_TO_LOGIN,
  payload,
});
