// MapPopup.js
import React from "react";
import { Popup, Marker } from "react-map-gl/maplibre";

const MapPopup = ({
  selectedLand,
  showPopup,
  selectedLandCoord,
  closePopup,
  landDetails,
  rulesDetails,
}) => {
  if (!selectedLand || !showPopup) {
    return null; // Do not render anything if there is no selected land or popup is not to be shown.
  }

  return (
    <Marker
      longitude={selectedLandCoord[0][0]}
      latitude={selectedLandCoord[0][1]}
    >
      <Popup
        longitude={selectedLandCoord[0][0]}
        latitude={selectedLandCoord[0][1]}
        closeButton={true}
        closeOnClick={false}
        onClose={closePopup}
        anchor="bottom"
        offset={40} // Adjusts the popup position to be slightly above the marker.
      >
        <div>
          <strong>Land Details:</strong> {landDetails}
          <br />
          <strong>Rules:</strong> {rulesDetails}
        </div>
      </Popup>
    </Marker>
  );
};

export default MapPopup;
