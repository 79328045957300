import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAuth0AccessToken from "./authHook";
import { getUser, clearUserInfo } from "../redux/actions/userActions";
import { userInfoSelector } from "../redux/selectors/user";

const useUser = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector(userInfoSelector);
  const { accessToken, logoutAuth0 } = useAuth0AccessToken();

  useEffect(() => {
    if (accessToken && (!userInfo || userInfo?.length === 0))
      dispatch(getUser({ token: accessToken }));
  }, [accessToken, dispatch, userInfo]);

  const onLogout = () => {
    dispatch(clearUserInfo());
    logoutAuth0();
  };

  return { userInfo, onLogout };
};

export default useUser;
