import { useSearchLandResults } from "../hooks/searchLandResultsHook";
import SearchResults from "../components/SearchResults";
/*
In case is needed, this is the Mock of the lands
import GeoData from '../response.json'
*/

const SearchLandResults = ({ step }) => {
  const {
    saveOutputLandsSelection,
    isLoadingOutputLands,
    outputLandsResponse,
    selectedLandsInputs,
    saveAsFavoriteLand,
    isLoadingFavoriteLands,
    onCloseModal,
    shouldShowModal,
    modalMessage,
  } = useSearchLandResults();

  return (
    <SearchResults
      activeStep={step}
      onSaveOutputLandsSelection={saveOutputLandsSelection}
      isLoadingLands={isLoadingOutputLands || isLoadingFavoriteLands}
      outputLandsResponse={outputLandsResponse}
      selectedLandsInputs={selectedLandsInputs}
      saveAsFavoriteLand={saveAsFavoriteLand}
      shouldShowModal={shouldShowModal}
      onCloseModal={onCloseModal}
      modalMessage={modalMessage}
    ></SearchResults>
  );
};

export default SearchLandResults;
