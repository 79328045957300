import React from "react";
import { Box } from "@mui/material";
import AddToCollectionModal from "./AddToCollectionModal";
import CreateCollectionModal from "./CreateCollectionModal";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";

const Bookmark = ({
  collection,
  openCollection,
  landData,
  setAddCollection,
  collectionData,
  addToFolder,
  addCollection,
  addCollectionModal,
}) => {
  return (
    <Box>
      {collection && (
        <AddToCollectionModal
          setOpen={openCollection}
          landID={landData.id}
          setAddCollection={setAddCollection}
          collectionData={collectionData}
          addToFolder={addToFolder}
        />
      )}
      {addCollectionModal && (
        <CreateCollectionModal
          setOpen={setAddCollection}
          setAddOpen={openCollection}
          addCollection={addCollection}
        />
      )}
      <BookmarkBorderIcon
        sx={{ cursor: "pointer" }}
        onClick={() => openCollection(true)}
      />
    </Box>
  );
};

export default Bookmark;
