import {
  ADD_LAND_COLLECTION,
  ADD_LAND_COLLECTION_FAILURE,
  ADD_LAND_COLLECTION_SUCCESS,
  DELETE_LAND_COLLECTION,
  ADD_COLLECTION,
  RENAME_COLLECTION,
  DELETE_COLLECTION,
  GET_COLLECTION_RESULTS,
  GET_COLLECTION_SUCCESS,
  GET_COLLECTION_FAILURE,
  GET_USER_COLLECTION_FAILURE,
  GET_USER_COLLECTION_RESULTS,
  GET_USER_COLLECTION_SUCCESS,
  ADD_COLLECTION_SUCCESS,
  ADD_COLLECTION_FAILURE,
  DELETE_COLLECTION_SUCCESS,
  DELETE_LAND_COLLECTION_FAILURE,
  DELETE_LAND_COLLECTION_SUCCESS,
  DELETE_COLLECTION_FAILURE,
  RENAME_COLLECTION_SUCCESS,
  RENAME_COLLECTION_FAILURE,
} from "./types";

export const getCollectionResults = (payload) => ({
  type: GET_COLLECTION_RESULTS,
  payload,
});
export const getCollectionSuccess = (payload) => ({
  type: GET_COLLECTION_SUCCESS,
  payload,
});
export const getCollectionFailure = (payload) => ({
  type: GET_COLLECTION_FAILURE,
  payload,
});

export const getUserCollectionResults = (payload) => {
  return {
    type: GET_USER_COLLECTION_RESULTS,
    payload,
  };
};
export const getUserCollectionSuccess = (payload) => ({
  type: GET_USER_COLLECTION_SUCCESS,
  payload,
});
export const getUserCollectionFailure = (payload) => ({
  type: GET_USER_COLLECTION_FAILURE,
  payload,
});

export const addLandCollection = (payload) => ({
  type: ADD_LAND_COLLECTION,
  payload,
});
export const addLandCollectionSuccess = (payload) => ({
  type: ADD_LAND_COLLECTION_SUCCESS,
  payload,
});
export const addLandCollectionFailure = (payload) => ({
  type: ADD_LAND_COLLECTION_FAILURE,
  payload,
});
export const deleteLandCollectionSuccess = (payload) => ({
  type: DELETE_COLLECTION_SUCCESS,
  payload,
});
export const deleteLandCollection = (payload) => ({
  type: DELETE_LAND_COLLECTION,
  payload,
});
export const deleteLandCollectionFailure = (payload) => ({
  type: DELETE_LAND_COLLECTION_FAILURE,
  payload,
});
export const addCollection = (payload) => ({
  type: ADD_COLLECTION,
  payload,
});
export const addCollectionSuccess = (payload) => ({
  type: ADD_COLLECTION_SUCCESS,
  payload,
});
export const addCollectionFailure = (payload) => ({
  type: ADD_COLLECTION_FAILURE,
  payload,
});
export const deleteCollectionSuccess = (payload) => ({
  type: DELETE_LAND_COLLECTION_SUCCESS,
  payload,
});
export const deleteCollectionFailure = (payload) => ({
  type: DELETE_COLLECTION_FAILURE,
  payload,
});
export const deleteCollection = (payload) => ({
  type: DELETE_COLLECTION,
  payload,
});
export const renameCollection = (payload) => ({
  type: RENAME_COLLECTION,
  payload,
});
export const renameCollectionSuccess = (payload) => ({
  type: RENAME_COLLECTION_SUCCESS,
  payload,
});
export const reanmeCollectionFailure = (payload) => ({
  type: RENAME_COLLECTION_FAILURE,
  payload,
});
