import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import proj4 from "proj4";
import {
  projectTypeSelector,
  subRegionSelector,
  projectSizeInMWSelector,
  acresSelector,
  loadSearchSelector,
  searchParamsSelector,
  searchIdSelector,
  statusUrlSelector,
  searchEntitiesAdmLevel1ListSelector,
  searchEntitiesAdmLevel1LayerPublicIdSelector,
  searchEntitiesSelector,
  searchEntitiesInputsSelector,
  searchEntitiesAdmLevel1ValueSelector,
  searchEntityValueSelector,
  searchEntitiesLoaderSelector,
  searchEntitiesListSelector,
  searchEntitiesPropertiesSelector,
} from "../redux/selectors/search";
import {
  startSearch,
  searchContinue,
  setProjectSizeInMw,
  setProjectType,
  setSubregion,
  setSearchEntity,
  setSearchEntityAdmLevel1,
  setSearchParams,
  getSearchEntities,
  setAcre,
} from "../redux/actions/searchesActions";
import {
  useTechnologyOptions,
  useSubregionOptions,
  useRegionOptions,
  useSearchEntitiesOptions,
  useSearchEntitiesAdmLevel1Options,
} from "../utils/selectOptions";
import { searchPreProcessedLandsInputsSelector } from "../redux/selectors/outputLands";
import useAuth0AccessToken from "../hooks/authHook";
import { isLoadingOutputLandsSelector } from "../redux/selectors/outputLands";
import { isLoadingsearchPreProcessedLandsInputsSelector } from "../redux/selectors/outputLands";
import { getPreProcessedSearchesInputs } from "../redux/actions/landsActions";
import { PROJECT_SIZES, PROJECT_SIZES_UNIT_NAMES } from "../utils/constants";

export const useSearch = (setViewState = () => {}) => {
  const dispatch = useDispatch();

  const [once, setOnce] = useState(false);
  const [sizeUnit, setSizeUnit] = useState(PROJECT_SIZES_UNIT_NAMES.MW);

  const { accessToken } = useAuth0AccessToken();

  const projectType = useSelector(projectTypeSelector);
  const selectedSubregion = useSelector(subRegionSelector);
  const searchEntityAdmLevel1Value = useSelector(
    searchEntitiesAdmLevel1ValueSelector
  );
  const searchEntity = useSelector(searchEntityValueSelector);

  const projectSizeInMW = useSelector(projectSizeInMWSelector);
  const acre = useSelector(acresSelector);

  const isLoadingSearchInputs = useSelector(
    isLoadingsearchPreProcessedLandsInputsSelector
  );

  const searchId = useSelector(searchIdSelector);
  const statusUrl = useSelector(statusUrlSelector);

  const preProcessedSearchData = useSelector(
    searchPreProcessedLandsInputsSelector
  );
  const searchEntitiesAdmLevel1 = useSelector(
    searchEntitiesAdmLevel1ListSelector
  );
  const searchEntitiesAdmLevel1LayerPublicId = useSelector(
    searchEntitiesAdmLevel1LayerPublicIdSelector
  );
  const optionsSearchEntitiesAdmLevel1 = useSearchEntitiesAdmLevel1Options(
    searchEntitiesAdmLevel1
  );
  const searchEntitiesList = useSelector(searchEntitiesListSelector);
  const searchEntityInputs = useSelector(searchEntitiesInputsSelector);

  const searchEntitiesProperties = useSelector(
    searchEntitiesPropertiesSelector
  );
  const optionsSearchEntities = useSearchEntitiesOptions(searchEntitiesList);

  const optionsTechnology = useTechnologyOptions(preProcessedSearchData);
  const optionsSubregion = useSubregionOptions(preProcessedSearchData);
  const optionsSize = [];
  const optionsRegion = useRegionOptions(preProcessedSearchData);
  const loadOutputlands = useSelector(isLoadingOutputLandsSelector);
  const loadSearch = useSelector(loadSearchSelector);
  const searchParams = useSelector(searchParamsSelector);
  const isLoadingOutputLands = loadSearch || loadOutputlands;
  const searchEntitiesLoader = useSelector(searchEntitiesLoaderSelector);

  useEffect(() => {
    if (!once && loadSearch) {
      dispatch(
        searchContinue({
          searchId: searchId,
          statusUrl: statusUrl,
          searchParams: searchParams,
        })
      );
      setOnce(true);
    }
  }, [dispatch, loadSearch, once, searchId, searchParams, statusUrl]);

  const isSPV = optionsTechnology[projectType]?.label === "SPV";
  const currentSizes = isSPV ? PROJECT_SIZES.SPV : PROJECT_SIZES.CPV;

  const sProjectSizeInMW = useCallback(
    (size) => {
      dispatch(setProjectSizeInMw(size));
    },
    [dispatch]
  );

  const sAcre = useCallback(
    (acre) => {
      dispatch(setAcre(acre));
    },
    [dispatch]
  );

  const projectSizeConfig = {
    mw: {
      value: projectSizeInMW,
      setValue: sProjectSizeInMW,
      min: currentSizes.MW[0],
      max: currentSizes.MW[currentSizes.MW.length - 1],
      step: isSPV ? 5 : 1,
      marks: currentSizes.MW.map((value) => ({ value, label: `${value}` })),
    },
    acres: {
      value: acre,
      setValue: sAcre,
      min: currentSizes.ACRES[0],
      max: currentSizes.ACRES[currentSizes.ACRES.length - 1],
      step: isSPV ? 28 : 1,

      marks: currentSizes.ACRES.map((value) => ({
        value,
        label: value,
      })),
    },
  };

  const toggleSizeUnit = () => {
    setSizeUnit((prevUnit) =>
      prevUnit === PROJECT_SIZES_UNIT_NAMES.MW
        ? PROJECT_SIZES_UNIT_NAMES.ACRES
        : PROJECT_SIZES_UNIT_NAMES.MW
    );
  };

  const sProjectType = (event) => {
    const newProjectType = event.target.value;
    dispatch(setProjectType(newProjectType));

    // Reset project size based on the new project type
    const initialSize =
      optionsTechnology[event.target.value].label === "CPV" ||
      optionsTechnology[event.target.value].label == "APV"
        ? 1
        : 10;
    dispatch(setProjectSizeInMw(initialSize));
  };
  const sSubregion = (event) => {
    dispatch(setSubregion(event.target.value));
  };
  const sSearchEntityAdmLevel1 = (event) => {
    dispatch(setSearchEntityAdmLevel1(event.target.value));
  };

  const convertCoordinates = useCallback((coordinates) => {
    const [x, y] = coordinates;
    // Check if both x and y are finite numbers
    if (isFinite(x) && isFinite(y)) {
      return proj4("EPSG:3857", "EPSG:4326", coordinates);
    } else {
      throw new Error("Coordinates must be finite numbers");
    }
  }, []);

  const sSearchEntity = (event) => {
    dispatch(setSearchEntity(event.target.value));
    const selectedSearchEntity = optionsSearchEntities[event.target.value];
    const centroid = selectedSearchEntity.centroid;
    const [x, y] = centroid.split(",").map((coord) => parseFloat(coord.trim()));

    const [lon, lat] = convertCoordinates([x, y]);

    setViewState({
      longitude: lon,
      latitude: lat,
      zoom: 16,
    });
  };

  const handleAutocompleteEntity = (
    newValue,
    optionsArray,
    isAdmLevel1,
    shouldUpdateMap = false
  ) => {
    if (newValue) {
      const index = optionsArray.findIndex(
        (entity) => entity.value === newValue.value
      );

      if (index !== -1) {
        if (isAdmLevel1) {
          dispatch(setSearchEntityAdmLevel1(index));
        } else {
          dispatch(setSearchEntity(index));
        }

        if (shouldUpdateMap) {
          const centroid = newValue.centroid;
          const [x, y] = centroid
            .split(",")
            .map((coord) => parseFloat(coord.trim()));

          const [lon, lat] = convertCoordinates([x, y]);
          setViewState({
            longitude: lon,
            latitude: lat,
            zoom: 16,
          });
        }
      }
    }
  };

  const onSubmit = (data) => {
    const formattedData = {
      ...data,
      token: accessToken,
      projectSize:
        sizeUnit === PROJECT_SIZES_UNIT_NAMES.MW ? projectSizeInMW : acre,
      projectSizeUnit:
        sizeUnit === PROJECT_SIZES_UNIT_NAMES.MW
          ? PROJECT_SIZES_UNIT_NAMES.MW
          : PROJECT_SIZES_UNIT_NAMES.ACRES,
    };

    dispatch(setSearchParams({ ...formattedData }));

    dispatch(startSearch({ ...formattedData }));
  };

  useEffect(() => {
    let technologyId;
    let subregionId;
    let searchEntityAdmLevel1EntityId;
    let layerPublicId;

    // Determine technologyId
    if (
      optionsTechnology &&
      optionsTechnology[projectType] &&
      optionsTechnology[projectType].value
    ) {
      technologyId = optionsTechnology[projectType].value;
    }

    // Determine subregionId
    if (
      optionsSubregion &&
      optionsSubregion[selectedSubregion] &&
      optionsSubregion[selectedSubregion].value
    ) {
      subregionId = optionsSubregion[selectedSubregion].value;
    }
    // Determine entityId
    if (searchEntityAdmLevel1Value) {
      const entity = searchEntitiesAdmLevel1?.[searchEntityAdmLevel1Value];
      if (entity?.entity_id) {
        searchEntityAdmLevel1EntityId = entity.entity_id;
      }
    }

    // Determine layerPublicId
    if (searchEntitiesAdmLevel1LayerPublicId) {
      layerPublicId = searchEntitiesAdmLevel1LayerPublicId;
    }

    // Build params
    const params = {
      token: accessToken,
    };

    if (subregionId) {
      params.sub_region_id = subregionId;
    }

    if (technologyId) {
      params.technology_id = technologyId;
    }

    if (searchEntityAdmLevel1EntityId) {
      params.search_entity_adm_level_1_entity_id =
        searchEntityAdmLevel1EntityId;
    }

    if (layerPublicId) {
      params.search_entity_adm_level_1_layer_public_id = layerPublicId;
    }

    // Dispatch getSearchEntities if subregionId and technologyId are different from previous search and have values
    const previousSelectedSubRegionId = searchEntityInputs?.sub_region_id;
    const previousSelectedTechnologyId = searchEntityInputs?.technology_id;
    const previousSelectedAdmLevel1EntityId =
      searchEntityInputs?.search_entity_adm_level_1_entity_id;

    if (
      subregionId &&
      technologyId &&
      (subregionId !== previousSelectedSubRegionId ||
        technologyId !== previousSelectedTechnologyId ||
        searchEntityAdmLevel1EntityId !== previousSelectedAdmLevel1EntityId)
    ) {
      dispatch(getSearchEntities(params));
    }
  }, [
    selectedSubregion,
    projectType,
    accessToken,
    optionsSubregion,
    optionsTechnology,
    searchEntitiesAdmLevel1,
    searchEntityAdmLevel1Value,
    searchEntitiesAdmLevel1LayerPublicId,
    searchEntityInputs,
    dispatch,
  ]);

  useEffect(() => {
    const getPreProcessedSearchesInputsResult = (data) => {
      dispatch(getPreProcessedSearchesInputs({ ...data }));
    };
    if (
      (preProcessedSearchData === null ||
        typeof preProcessedSearchData === "string") &&
      accessToken
    ) {
      getPreProcessedSearchesInputsResult({ token: accessToken });
    }
  }, [dispatch, accessToken, preProcessedSearchData]);

  return {
    projectType,
    searchEntityAdmLevel1Value,
    searchEntity,
    selectedSubregion,
    projectSizeInMW,
    sProjectSizeInMW,
    sAcre,
    acre,
    sSearchEntity,
    sProjectType,
    sSubregion,
    optionsSearchEntities,
    searchEntitiesProperties,
    sSearchEntityAdmLevel1,
    optionsSearchEntitiesAdmLevel1,
    optionsTechnology,
    optionsSubregion,
    optionsRegion,
    optionsSize,
    onSubmit,
    isLoadingOutputLands,
    loadSearch,
    searchEntitiesLoader,
    isLoadingSearchInputs,
    projectSizeConfig,
    sizeUnit,
    toggleSizeUnit,
    handleAutocompleteEntity,
  };
};
