import CollectionsPage from "../new_components/CollectionsPage";

export const ROUTES = {
  NOT_FOUND: "/not-found",
  LOGIN: "/login",
  SEARCH: "/",
  SEARCH_LAND_RESULTS: "/SearchLandResults", //TODO: Remove
  SEARCH_FAV_LAND_RESULTS: "/FavoriteLandResults",
  MAP: "/map",
  MAPPAGE: "/mappage",
  LANDINGPAGE: "/landingpage",
  OLDSEARCH: "/oldsearch",
  COLLECTIONS: "/collections",
};
