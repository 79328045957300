import proj4 from "proj4";
import { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedLandResults } from "../redux/actions/landsActions";

import { centroid } from "@turf/turf";
import { getLandDetails, getAcRuleDetails } from "../utils/functions";
import {
  isLoadingSelectedLandsSelector,
  selectedLandsSelector,
} from "../redux/selectors/selectedLands";

export const useMap = (landID) => {
  const dispatch = useDispatch();

  const isLoadingSelectedLands = useSelector(isLoadingSelectedLandsSelector);
  const selectedLands = useSelector(selectedLandsSelector);

  const getSelectedResults = useCallback(
    (data) => {
      const params = {
        token: data,
      };
      dispatch(getSelectedLandResults(params));
    },
    [dispatch]
  );

  // State Hooks
  const [selectedLand, setSelectedLand] = useState(null);
  const [landDetails, setLandDetails] = useState(null);
  const [rulesDetails, setRulesDetails] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [viewState, setViewState] = useState({
    longitude: 0,
    latitude: 0,
    zoom: 14,
  });
  const [geoJSONData, setGeoJSONData] = useState({
    type: "FeatureCollection",
    features: [],
  });
  const [land, setLand] = useState(-1);

  // Coordinate Conversion Function
  const convertCoordinates = useCallback((coordinates) => {
    return coordinates.map((coord) => proj4("EPSG:3857", "EPSG:4326", coord));
  }, []);

  const convertLands = useCallback(
    (selectedLands) => {
      if (!Array.isArray(selectedLands)) {
        return [];
      }

      return selectedLands.map((land) => ({
        ...land,
        geom: {
          ...land.geom,
          coordinates: land.geom?.coordinates.map((polygon) =>
            polygon.map(convertCoordinates)
          ),
        },
      }));
    },
    [convertCoordinates]
  );

  // Refs
  const mapRef = useRef(null);
  // reference added to get the updated geoJSONData on onOutputLandClick function
  const geoJSONDataRef = useRef(geoJSONData);

  // Reference geoJSONData to ensure it's updated and accessible in the onOutputLandClick function.
  useEffect(() => {
    geoJSONDataRef.current = geoJSONData;
  }, [geoJSONData]);

  const onOutputLandClick = useCallback(
    (
      e, // Event object, typically from a map event listener.
      geom, // Geometry data of the clicked feature.
      id, // Unique identifier of the clicked feature.
      setLandConversion = true, // Flag to determine if the geometry should be converted.
      setZoom = true, // Flag to control whether to update the zoom level in the view state.
      setCentroid = true // Flag to control whether to update the map center based on the feature's centroid.
    ) => {
      const currentGeoJSONData = geoJSONDataRef.current;

      // Create a copy of the geoJSONData state
      const updatedGeoJSONData = { ...currentGeoJSONData };
      // Find the feature you want to highlight based on its properties
      const featureToHighlight = updatedGeoJSONData.features.find(
        (feature) => feature.properties.id === id // Replace with the actual feature id you want to highlight
      );
      // Check if the feature was found
      // Check if the feature was found
      if (featureToHighlight) {
        // Update the 'highlighted' property for the selected feature
        featureToHighlight.properties.highlighted = true;

        // Set 'highlighted' to false for all other features
        updatedGeoJSONData.features.forEach((feature) => {
          if (feature.properties.id !== id) {
            feature.properties.highlighted = false;
          }
        });

        // Update the geoJSONData state with the modified data
        setGeoJSONData(updatedGeoJSONData);
        let convertedLands;
        const multiGeom = [{ geom: geom }];
        if (setLandConversion) {
          convertedLands = convertLands(multiGeom);
        } else {
          convertedLands = multiGeom;
        }

        const firstLandCentroid = getCentroid(convertedLands[0]?.geom);

        // Construct the new view state based on parameters
        const newViewState = {};
        if (setCentroid) {
          newViewState.longitude = firstLandCentroid.geometry?.coordinates[0];
          newViewState.latitude = firstLandCentroid.geometry?.coordinates[1];
        }
        if (setZoom) {
          newViewState.zoom = 14;
        }

        setViewState(newViewState);
      }
    },
    [convertLands]
  );

  const handleFeatureSelect = useCallback(
    (feature) => {
      const land = selectedLands.find(
        (l) => l.output_land_id === feature.properties.id
      );
      if (land) {
        setLandDetails(getLandDetails(land));
        setRulesDetails(getAcRuleDetails(land));
        setSelectedLand({
          properties: feature.properties,
          coordinates: centroid(land.geom).geometry?.coordinates,
        });
        setShowPopup(true);
      }
    },
    [selectedLands, setSelectedLand, setShowPopup]
  );

  // Map Click Handler
  const onMapClick = useCallback(
    (event) => {
      if (mapRef.current) {
        const map = mapRef.current?.getMap();
        const features = map.queryRenderedFeatures(event.point, {
          layers: [
            "land-polygons-normal",
            "land-polygons-highlighted",
            "land-polygons-fill",
          ],
        });
        if (features.length) {
          const feature = features[0];
          handleFeatureSelect(feature);
          onOutputLandClick(
            event,
            feature.geometry,
            feature.properties.id,
            false, //landConversion
            false, //setZoom
            false //setCentroid
          );
        }
      }
    },
    [onOutputLandClick, handleFeatureSelect]
  );

  const closePopup = () => setShowPopup(false);

  const getCentroid = (geom) => {
    return centroid(geom);
  };

  useEffect(() => {
    const convertedLands = convertLands(selectedLands);

    if (convertedLands.length > 0) {
      const firstLandCentroid = getCentroid(
        landID === -1
          ? convertedLands[0]?.geom
          : convertedLands[convertedLands.findIndex((obj) => obj.id === land)]
              ?.geom
      );
      setViewState({
        longitude: firstLandCentroid.geometry?.coordinates[0],
        latitude: firstLandCentroid.geometry?.coordinates[1],
        zoom: 14, // zoom applied on selected
      });

      setGeoJSONData({
        type: "FeatureCollection",
        features: convertedLands.map((land) => ({
          type: "Feature",
          properties: {
            id: land.output_land_id,
            ranking: land.ranking,
            highlighted: false,
          },
          geometry: land.geom,
        })),
      });
    }
  }, [selectedLands, convertLands, landID, land]);

  // Selected Land Coordinate Calculation
  let selectedLandCoord = selectedLand
    ? convertCoordinates([
        [selectedLand?.coordinates[0], selectedLand?.coordinates[1]],
      ])
    : null;

  return {
    selectedLand,
    landDetails,
    rulesDetails,
    showPopup,
    viewState,
    geoJSONData,
    mapRef,
    onMapClick,
    closePopup,
    selectedLandCoord,
    setViewState,
    isLoadingSelectedLands,
    getSelectedResults,
    selectedLands,
    onOutputLandClick,
  };
};
