// StaticLayers.js
import React from "react";
import { Source, Layer } from "react-map-gl/maplibre";

const StaticLayers = ({ geoJSONData }) => {
  return (
    <>
      {/* ESA-Eox Source and Layer */}
      <Source
        id="esa-eox-source"
        type="raster"
        tileSize={256}
        tiles={[
          "https://tiles.maps.eox.at/wmts?layer=s2cloudless-2020_3857&style=default&tilematrixset=GoogleMapsCompatible&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image/png&TileMatrix={z}&TileCol={x}&TileRow={y}",
        ]}
      >
        <Layer
          id="esa-eox-layer"
          type="raster"
          source="esa-eox-source"
          minzoom={0}
          maxzoom={22}
        />
      </Source>

      {/* GeoJSON Data Source and Associated Layers */}
      <Source id="source-data" type="geojson" data={geoJSONData}>
        {/* Fill layer for better click interaction */}
        <Layer
          id="land-polygons-fill"
          type="fill"
          source="source-data"
          layout={{}}
          paint={{
            "fill-color": "#000000", // Black, but can be any color since it's nearly transparent
            "fill-opacity": 0.01, // Extremely low opacity
          }}
        />

        {/* Layer for non-highlighted polygons (Lines) */}
        <Layer
          id="land-polygons-normal"
          type="line"
          filter={["!=", ["get", "highlighted"], true]}
          paint={{
            "line-color": [
              "case",
              ["<=", ["get", "ranking"], 3],
              "#A3DBA0",
              [
                "all",
                [">=", ["get", "ranking"], 4],
                ["<=", ["get", "ranking"], 8],
              ],
              "#F7F286",
              [">", ["get", "ranking"], 8],
              "#FFADAD",
              "#000000",
            ],
            "line-width": 2,
            "line-opacity": 0.7,
          }}
        />
        {/* Layer for highlighted polygons */}
        <Layer
          id="land-polygons-highlighted"
          type="line"
          filter={["==", ["get", "highlighted"], true]}
          paint={{
            "line-color": "red",
            "line-width": 3,
            "line-opacity": 1,
          }}
        />
      </Source>
    </>
  );
};

export default StaticLayers;
