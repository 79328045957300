import React from "react";
import Stepper from "./Stepper";

import "../styles/hero.css";

const Hero = (props) => {
  const steps = ["Search", "Results", "Map"];
  const { title, subtitle, gradient, activeStep } = props;
  const titleGradientStyle = {
    background: `linear-gradient(to right, ${gradient.title.start} 18.1%, ${gradient.title.end} 96.15%)`,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  };
  const subtitleGradientStyle = {
    background: `linear-gradient(to right, ${gradient.subtitle.start} 18.1%, ${gradient.subtitle.end} 96.15%)`,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  };
  return (
    <div className="hero-container d-flex flex-column justify-content-center ">
      <Stepper steps={steps} activeStep={activeStep} />
      <div className="hero-title-container">
        <div style={titleGradientStyle}>
          <p className="hero-title">{title}</p>
        </div>
        <div style={subtitleGradientStyle}>
          <p className="hero-subtitle">{subtitle}</p>
        </div>
      </div>
      <br></br>
    </div>
  );
};

export default Hero;
