import { REDIRECT_TO_LOGIN, CLEAR_REDIRECT_TO_LOGIN } from "../actions/types";

function authReducer(state = {}, action) {
  switch (action.type) {
    case REDIRECT_TO_LOGIN:
      return {
        ...state,
        redirectToLogin: true,
      };
    case CLEAR_REDIRECT_TO_LOGIN:
      return {
        ...state,
        redirectToLogin: false,
      };
    default:
      return state;
  }
}

export default authReducer;
