import React from "react";
import styled from "styled-components";

const ModalContainer = styled.div`
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
`;
const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  width: 80%; /* Adjust the width as needed */
  max-width: 500px; /* Set a maximum width if desired */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 20px; /* Add your desired top margin */
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const ModalTitle = styled.h2`
  margin: 0;
`;

const ModalCloseButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 18px;
`;

const Modal = ({ isOpen, title, onClose, children }) => {
  return (
    <ModalContainer isOpen={isOpen}>
      <ModalContent>
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
        </ModalHeader>
        {children}
        <ModalFooter>
          <ModalCloseButton onClick={onClose}>Close</ModalCloseButton>
        </ModalFooter>
      </ModalContent>
    </ModalContainer>
  );
};

export default Modal;
