import {
  GET_USER,
  GET_USER_RESULT_SUCCESS,
  GET_USER_RESULT_FAILURE,
  CLEAR_USER_INFO
} from '../actions/types';

function userReducer(state = {}, action) {
  switch (action.type) {
    case GET_USER:
        return {
            ...state,
            request: action.payload,
            isLoading: true,
          };
    case GET_USER_RESULT_SUCCESS:
        return {
            ...state,
            response: action.payload,
            isLoading: false,
          };
    case GET_USER_RESULT_FAILURE:
        return {
            ...state,
            response: action.payload,
            isLoading: false,
          };
    case CLEAR_USER_INFO:
        return {
            ...state,
            response: [],
          };
          
    default:
      return state;
  }
}

export default userReducer;
