import React from "react";
import proj4 from "proj4";
import { centroid } from "@turf/turf";
import "../styles/MapCard.css";
import CardConditionDiv from "./CardConditionDiv";
import {
  getConditionFromRanking,
  getLandDetails,
  getAcRuleDetails,
  getEcRuleDetails,
  getCoordinatesDetails,
} from "../utils/functions";

const MapCard = ({ landData, number, onOutputLandClick }) => {
  // `condition` will have the ranking name, such as "excellent" or "medium".
  const condition = getConditionFromRanking(landData?.ranking);

  // Use the utility functions to get land details and rule details
  const landDetails = getLandDetails(landData);
  const acRulesDetails = getAcRuleDetails(landData);
  const ecRulesDetails = getEcRuleDetails(landData);

  // Calculate the centroid of the geometry stored in landData.geom
  const coordinates = centroid(landData.geom).geometry?.coordinates;
  // Reproject the coordinates from EPSG:3857 to EPSG:4326 using proj4 library
  const reprojectedCoordinates = proj4("EPSG:3857", "EPSG:4326", coordinates);
  // Get additional details or perform operations on the reprojected coordinates
  const coordinatesDetails = getCoordinatesDetails(
    reprojectedCoordinates[1],
    reprojectedCoordinates[0]
  );
  return (
    <div
      className="card-container"
      onClick={(e) =>
        onOutputLandClick(e, landData?.geom, landData?.output_land_id)
      }
    >
      <div id="card-title-container" className="row">
        <div className="col-sm-12 card-title-text">
          Site {number + 1} - {landData.technology_name} -{" "}
          {landData.project_size_in_mw}MW - {landData.reverse_geocoding_address}
          {}
        </div>
        <CardConditionDiv condition={condition}>
          <span id="condition-text">{condition}</span>
        </CardConditionDiv>
      </div>
      <div className="card">
        {landDetails}
        {coordinatesDetails}
        {acRulesDetails}
        {ecRulesDetails}
      </div>
      <hr id="horizontal-bar"></hr>
      <div className="button-container">
        <button className="see-details">See Details</button>
      </div>
    </div>
  );
};

export default MapCard;
