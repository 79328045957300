import React from "react";
import { Box, Typography, Button, Paper } from "@mui/material";
import {
  getConditionFromRanking,
  getLandDetails,
  getAcRuleDetails,
  getEcRuleDetails,
  getScoreAndRankingValues,
} from "../utils/functions";
import StarRateIcon from "@mui/icons-material/StarRate";
import MapCard from "./MapCard";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { useState } from "react";
import AddToCollectionModal from "./AddToCollectionModal";
import CreateCollectionModal from "./CreateCollectionModal";

const SiteCard = ({
  landData,
  number,
  collectionData,
  addCollection,
  addToFolder,
  borderLand,
  setReadMore,
}) => {
  const condition = getConditionFromRanking(landData?.ranking);
  const landDetails = getLandDetails(landData);
  const rankingAndScoreValues = getScoreAndRankingValues(landData);
  // Special handling for AC & EC rules data
  /*const acRulesDetails = getAcRuleDetails(landData);
  const ecRulesDetails = getEcRuleDetails(landData);*/
  const [collection, openCollection] = useState(false);
  const [addCollectionModal, setAddCollection] = useState(false);
  //const [readMore, setReadMore] = useState(false);

  const conditionColors = {
    Excellent: ["#D5E6D8", "#47784E"],
    Medium: ["#ECEDC7", "#6E6236"],
    Low: ["#ECEDC7", "#6E6236"],
  }; // Should be one for low?

  return (
    <Paper
      sx={{
        border: borderLand ? "4px solid #52975C" : "3px solid transparent",
      }}
      elevation={borderLand ? 10 : 0}
    >
      <Box
        sx={{
          borderRadius: "4px",
          display: "flex",
          position: "relative",
          border: borderLand ? "" : "1px solid #DADADA",
        }}
      >
        {collection && (
          <AddToCollectionModal
            setOpen={openCollection}
            landID={landData.id}
            setAddCollection={setAddCollection}
            collectionData={collectionData}
            addToFolder={addToFolder}
          />
        )}
        {addCollectionModal && (
          <CreateCollectionModal
            setOpen={setAddCollection}
            setAddOpen={openCollection}
            addCollection={addCollection}
          />
        )}
        {landData.geom && (
          <Box sx={{ width: "45%", cursor: "default" }}>
            <MapCard geoJSON={{ geom: landData.geom }} />
          </Box>
        )}
        <Box
          sx={{
            padding: "16px",
            width: landData?.geom ? "55%" : "100%",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Typography
                sx={{ fontSize: "24px", fontWeight: 700, display: "inline" }}
              >
                {" "}
                {"Site " + (number + 1)}{" "}
              </Typography>
              <Box
                sx={{
                  display: "inline-flex",
                  padding: "2px 8px 2px 2px",
                  gap: "4px",
                  backgroundColor: conditionColors[condition][0],
                  borderRadius: "500px",
                  alignItems: "center",
                }}
              >
                <StarRateIcon
                  sx={{
                    transform: "scale(0.6)",
                    color: conditionColors[condition][1],
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 500,
                    color: conditionColors[condition][1],
                  }}
                >
                  {" "}
                  {condition}{" "}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 500,
                    color: conditionColors[condition][1],
                  }}
                >
                  {" "}
                  {landData["ac_score"].toFixed(2)}
                  {"/100"}
                </Typography>
              </Box>
            </Box>
            <BookmarkBorderIcon
              sx={{ cursor: "pointer" }}
              onClick={() => openCollection(true)}
            />
          </Box>

          <Box>
            {rankingAndScoreValues}
            {landDetails}
            <Button
              variant="long"
              onClick={() => setReadMore(true)}
              sx={{
                backgroundColor: "#52975C",
                borderRadius: "4px",
                width: "100%",
                fontSize: "14px",
                height: "40px",
                color: "white",
                textAlign: "center",
                marginTop: "16px",
              }}
            >
              {" "}
              Read More{" "}
            </Button>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default SiteCard;
