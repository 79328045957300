import {
  GET_DISPLAYED_LAYERS_RESULTS,
  GET_DISPLAYED_LAYERS_RESULTS_SUCCESS,
  GET_DISPLAYED_LAYERS_RESULTS_FAILURE,
  GET_SUBREGION_RESULTS,
  GET_SUBREGION_RESULTS_FAILURE,
  GET_SUBREGION_RESULTS_SUCCESS,
} from "./types";

export const getDisplayedLayersResults = (payload) => ({
  type: GET_DISPLAYED_LAYERS_RESULTS,
  payload,
});

export const getDisplayedLayersResultsSuccess = (payload) => ({
  type: GET_DISPLAYED_LAYERS_RESULTS_SUCCESS,
  payload,
});

export const getDisplayedLayersResultsFailure = (error) => ({
  type: GET_DISPLAYED_LAYERS_RESULTS_FAILURE,
  payload: error,
});

export const getSubRegionsResults = (payload) => ({
  type: GET_SUBREGION_RESULTS,
  payload,
});

export const getSubRegionResultsSuccess = (payload) => ({
  type: GET_SUBREGION_RESULTS_SUCCESS,
  payload,
});

export const getSubRegionResultsFailure = (error) => ({
  type: GET_SUBREGION_RESULTS_FAILURE,
  payload: error,
});
