import {
  GET_SELECTED_LANDS_RESULTS,
  GET_SELECTED_LANDS_RESULTS_FAILURE,
  GET_SELECTED_LANDS_RESULTS_SUCCESS,
} from "../actions/types";

function selectedLandsReducer(state = {}, action) {
  switch (action.type) {
    case GET_SELECTED_LANDS_RESULTS:
      return {
        ...state,
        request: action.payload,
        isLoading: true,
      };
    case GET_SELECTED_LANDS_RESULTS_SUCCESS:
      return {
        ...state,
        response: action.payload,
        isLoading: false,
      };
    case GET_SELECTED_LANDS_RESULTS_FAILURE:
      return {
        ...state,
        response: [],
        isLoading: false,
      };
    default:
      return state;
  }
}

export default selectedLandsReducer;
