import React, { useEffect, useMemo } from "react";
import { LayerMenu } from "../styles/MapLibreScreen.styled";
import Map, { AttributionControl } from "react-map-gl/maplibre";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "maplibre-gl/dist/maplibre-gl.css";
import { LayerControls, DynamicLayers } from "../components";
import StaticLayers from "./StaticLayers";
import { Source, Layer } from "react-map-gl/maplibre";
import { Box, Typography, Tooltip } from "@mui/material";
import SelectFilter from "./SelectFilter";
import LayerIcon from "../images/icons/Layers.png";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Satellite from "../images/Satellite.png";
import MapSty from "../images/Map.png";
import MapStyle from "./MapStyle";
import { useSelector } from "react-redux";
import { ROUTES } from "../routes/constants";
import { ChevronRight } from "@mui/icons-material";
import {
  StyledChevronRightIcon,
  StyledMotionBox,
  WrapperSelectFilter,
} from "../styles/MapScreen.styled";
import useLandSearchResults from "../new_hooks/useLandSearchResults";
import { selectMapStyle } from "../redux/selectors/mapSelectors";

const MapScreen = ({
  mapRef,
  fillData,
  lineData,
  onMapClick,
  viewState,
  layers,
  changeLayer,
  setViewState,
  search,
  isLoadingOutputLands,
}) => {
  const [selected, setSelected] = useState(false);
  const mapStyle = useSelector(selectMapStyle);
  const {
    isLandSearchSidebarOpen,
    landSearchesStatus,
    toggleLandSearchSideBar,
  } = useLandSearchResults();
  const isMapPageRoute = window.location.pathname.endsWith(ROUTES.MAPPAGE);

  const selectsGroupVariants = useMemo(
    () => ({
      open: {
        left: "424px",
        transition: {
          type: "spring",
          stiffness: 300,
          damping: 30,
        },
      },
      closed: {
        left: search || isMapPageRoute ? "24px" : "54px",
        transition: {
          type: "spring",
          stiffness: 300,
          damping: 30,
        },
      },
    }),
    [search, isMapPageRoute]
  );

  const maps = {
    satellite: {},
    map: "https://basemaps.cartocdn.com/gl/positron-gl-style/style.json",
  };

  const layerelements = Object.keys(layers).map((category) => {
    return Object.keys(layers[category]).map((name) => {
      if (layers[category][name]["active"]) {
        return (
          <Source
            key={`${name}-source`}
            id={`${name}-source`}
            type="raster"
            tiles={[layers[category][name].tileUrl]}
            tileSize={256}
          >
            <Layer
              key={`${name}-layer`}
              id={`${name}-layer`}
              type="raster"
              source={`${name}-source`}
            />
          </Source>
        );
      } else {
        return <React.Fragment key={`${category}-${name}-empty`} />;
      }
    });
  });

  useEffect(() => {
    if (mapRef.current && mapRef.current.getMap) {
      const map = mapRef.current.getMap();

      // Function to add layers back after style change
      const reapplyLayers = () => {
        Object.keys(layers).forEach((category) => {
          Object.keys(layers[category]).forEach((name) => {
            if (layers[category][name]["active"]) {
              const sourceId = `${name}-source`;
              const layerId = `${name}-layer`;

              // Re-add source and layer if they don't exist
              if (!map.getSource(sourceId)) {
                map.addSource(sourceId, {
                  type: "raster",
                  tiles: [layers[category][name].tileUrl],
                  tileSize: 256,
                });
              }

              if (!map.getLayer(layerId)) {
                map.addLayer(
                  {
                    id: layerId,
                    type: "raster",
                    source: sourceId,
                    paint: {
                      "raster-opacity": 1,
                    },
                  },
                  "esa-eox-layer"
                );
              }
            }
          });
        });

        // Piece of code that moves all custom layers above the satellite layer
        Object.keys(layers).forEach((category) => {
          Object.keys(layers[category]).forEach((name) => {
            const layerId = `${name}-layer`;
            if (map.getLayer(layerId)) {
              map.moveLayer(layerId);
            }
          });
        });
      };

      map.on("styledata", reapplyLayers);

      return () => {
        map.off("styledata", reapplyLayers);
      };
    }
  }, [mapStyle, layers]);

  //ensure that satellite tiles are at the bottom, but for the globe, specially at /mappage
  useEffect(() => {
    const map = mapRef.current?.getMap();
    if (map && mapStyle === "satellite" && map.getLayer("land-polygons-fill")) {
      if (map.getLayer("esa-eox-layer")) {
        const layers = map.getStyle().layers;
        const landPolygonsFillIndex = layers.findIndex(
          (layer) => layer.id === "land-polygons-fill"
        );
        map.moveLayer(
          "esa-eox-layer",
          map.getStyle().layers[landPolygonsFillIndex].id
        );
      }
    }
  }, [mapStyle]);

  return (
    <Map
      ref={mapRef}
      {...viewState}
      onMove={(evt) => setViewState(evt.viewState)}
      // onLoad={() => setMapLoaded(true)} //Useful for drawing. Uncomment if needed.
      style={{ width: "100vw", height: "100vh" }} // Set width and height to cover full viewport
      mapStyle={maps.map}
      onClick={onMapClick}
    >
      {mapStyle === "satellite" ? (
        <>
          <Source
            id="esa-eox-source"
            type="raster"
            tileSize={256}
            tiles={[
              "https://tiles.maps.eox.at/wmts?layer=s2cloudless-2020_3857&style=default&tilematrixset=GoogleMapsCompatible&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image/png&TileMatrix={z}&TileCol={x}&TileRow={y}",
            ]}
            //"http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}",
          >
            <Layer
              id="esa-eox-layer"
              type="raster"
              source="esa-eox-source"
              minzoom={0}
              maxzoom={22}
            />
          </Source>
          <AttributionControl
            compact
            position="bottom-right"
            customAttribution='Satellite imagery Rendering © <a href="https://s2maps.eu"> Sentinel-2 cloudless - https://s2maps.eu</a> by <a href="https://eox.at/">EOX IT Services GmbH</a> (Contains modified Copernicus Sentinel data 2018-2023)'
            style={{
              position: "fixed",
              bottom: "0",
              right: "0px",
            }}
          />
        </>
      ) : null}

      <StyledMotionBox
        variants={selectsGroupVariants}
        initial={
          isLandSearchSidebarOpen && !(search || isMapPageRoute)
            ? "open"
            : "closed"
        }
        animate={
          isLandSearchSidebarOpen && !(search || isMapPageRoute)
            ? "open"
            : "closed"
        }
        transition={
          search && isMapPageRoute
            ? { duration: 0 }
            : { type: "spring", stiffness: 300, damping: 30 }
        }
      >
        {isLandSearchSidebarOpen ? null : (
          <Tooltip title="Open Search History">
            <span>
              <StyledChevronRightIcon
                disabled={landSearchesStatus === "success" ? false : true}
                onClick={toggleLandSearchSideBar}
                isMapPageRoute={isMapPageRoute}
              >
                <ChevronRight />
              </StyledChevronRightIcon>
            </span>
          </Tooltip>
        )}
      </StyledMotionBox>

      <WrapperSelectFilter
        isMapPageRoute={isMapPageRoute}
        isLandSearchSidebarOpen={isLandSearchSidebarOpen}
      >
        {Object.keys(layers).map((category, index) => {
          return (
            <SelectFilter
              category={category}
              changeActive={changeLayer}
              subcategories={layers[category]}
              key={`${category}-${index}`}
            />
          );
        })}
      </WrapperSelectFilter>

      {!selected ? (
        <Box
          sx={{
            position: "fixed",
            top: isMapPageRoute && !!search ? "120px" : "190px",
            right: "24px",
            height: "48px",
            width: "48px",
            backgroundColor: "white",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={() => setSelected(true)}
        >
          <img src={LayerIcon} height={"24px"} width={"24px"} alt="LayerIcon" />
        </Box>
      ) : (
        <Box
          sx={{
            position: "fixed",
            top: isMapPageRoute && !!search ? "120px" : "190px",
            right: "24px",
            backgroundColor: "white",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            width: "110px",
            padding: "12px",
          }}
        >
          <Box
            sx={{
              marginBottom: "10px",
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography sx={{ fonstSize: "16px", fontWeight: 500 }}>
              {" "}
              Layers
            </Typography>
            <CloseIcon
              sx={{ cursor: "pointer", color: "black" }}
              onClick={() => setSelected(false)}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Typography sx={{ fontSize: "12px", fontWeight: 500 }}>
              Visual Style
            </Typography>
            <MapStyle
              iMG={MapSty}
              text={"Map"}
              setter={"base"}
              color="black"
              key={"map"}
            />
            <MapStyle
              iMG={Satellite}
              text={"Satellite"}
              setter={"satellite"}
              color="white"
              key={"satellite"}
            />
          </Box>
        </Box>
      )}
      {!isLoadingOutputLands && search && (
        <StaticLayers fillData={fillData} lineData={lineData} />
      )}

      <>{layerelements} </>

      {/*<DynamicLayers
        layersStructure={layersStructure.regions}
        activeLayers={activeLayers}
      />

      <LayerMenu>
        <LayerControls
          layersStructure={layersStructure.regions}
          toggleLayer={toggleLayer}
          activeLayers={activeLayers}
        />
      </LayerMenu>*/}
    </Map>
  );
};

export default MapScreen;
