import {
  UPLOAD_KMZ,
  UPLOAD_KMZ_FAILURE,
  UPLOAD_KMZ_SUCCESS,
} from "../actions/types";

function analyzeLandReducer(state = {}, action) {
  switch (action.type) {
    case UPLOAD_KMZ:
      return {
        ...state,
        request: action.payload,
        isLoading: true,
      };
    case UPLOAD_KMZ_SUCCESS:
      return {
        ...state,
        responseSave: action.payload,
        isLoading: false,
      };
    case UPLOAD_KMZ_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    default:
      return state;
  }
}

export default analyzeLandReducer;
