import React, { useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { StyledSpinner } from "../styles/Spinner.styled";

const SearchForm = ({ options, isLoading, onSubmit }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const customStyles = useMemo(
    () => ({
      option: (provided, state) => ({
        ...provided,
        background: state.isFocused
          ? "linear-gradient(90deg, #EBEFDE 0%, #C3DFC3 100%)"
          : state.isSelected
          ? "linear-gradient(90deg, #EBEFDE 0%, #C3DFC3 100%)"
          : "white",
        color: state.isFocused || state.isSelected ? "#4D4D4D" : "#4D4D4D",
        // Add any other styles you want to apply to the options
      }),
    }),
    []
  );
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* Technology Selection */}
      <div className="selection-option">
        <label>Type of Project</label>
        <Controller
          name="technology"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              options={options.technology}
              styles={customStyles}
            />
          )}
        />
        {errors.technology && <span>Please select a project type</span>}
      </div>

      {/* Size Selection */}
      <div className="selection-option">
        <label>Project Size</label>
        <Controller
          name="projectSize"
          control={control}
          rules={{ required: true }}
          render={({ field }) => <Select {...field} options={options.size} />}
        />
        {errors.size && <span>Please select a project size</span>}
      </div>

      {/* Region Selection */}
      <div className="selection-option">
        <label>Region Area</label>
        <Controller
          name="region"
          control={control}
          rules={{ required: true }}
          render={({ field }) => <Select {...field} options={options.region} />}
        />
        {errors.region && <span>Please select a region</span>}
      </div>

      {/* Search Button */}
      <button className="search-screen-primary-btn">
        Search {isLoading && <StyledSpinner />}
      </button>
    </form>
  );
};

export default SearchForm;
