import React from "react";
import { useCollections } from "../new_hooks/useCollections";
import HeaderBar from "../new_components/HeaderBar";
import SearchBar from "../new_components/SearchBar";
import CollectionsPage from "../new_components/CollectionsPage";
import NoCollectionPage from "../new_components/NoCollectionPage";
import { useSearchControls } from "../new_hooks/useSearchControls";
import PageLayout from "../components/PageLayout";
const Collections = () => {
  const {
    userCollection,
    aCollection,
    delCollection,
    changeCollection,
    addToFolder,
  } = useCollections();
  const { search, tab, sTab, sSearch } = useSearchControls();

  return (
    <PageLayout
      tab={tab}
      changeTab={sTab}
      search={search}
      isSearching={sSearch}
    >
      {userCollection === undefined ||
      userCollection?.length === 0 ||
      Object.keys(userCollection).length === 0 ? (
        <NoCollectionPage addCollection={aCollection} />
      ) : (
        <CollectionsPage
          collectionData={userCollection !== undefined ? userCollection : []}
          addCollection={aCollection}
          changeCollection={changeCollection}
          deleteCollection={delCollection}
        />
      )}
    </PageLayout>
  );
};

export default Collections;
