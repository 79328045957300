import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getOutputLands,
  getPreProcessedSearchesInputs,
  getFavoriteOutputLands,
} from "../redux/actions/landsActions";
import {
  isLoadingOutputLandsSelector,
  searchPreProcessedLandsInputsSelector,
  outputLandsResponseSelector,
} from "../redux/selectors/outputLands";
import { isLoadingFavoriteLandsSelector } from "../redux/selectors/favoriteLands";
import { loadSearchSelector } from "../redux/selectors/search";
export const useLands = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loadOutputlands = useSelector(isLoadingOutputLandsSelector);
  const loadSearch = useSelector(loadSearchSelector);
  const isLoadingOutputLands = loadSearch || loadOutputlands;

  const isLoadingFavoriteLands = useSelector(isLoadingFavoriteLandsSelector);
  const outputLandsResponse = useSelector(outputLandsResponseSelector);

  const preProcessedSearchData = useSelector(
    searchPreProcessedLandsInputsSelector
  );

  const getOutputLandsResults = (data) => {
    dispatch(getOutputLands({ ...data, navigate }));
  };

  const getPreProcessedSearchesInputsResults = (data) => {
    dispatch(getPreProcessedSearchesInputs({ ...data }));
  };

  const getFavoriteOutputLandsResults = (data) => {
    dispatch(getFavoriteOutputLands({ ...data, navigate }));
  };

  return {
    getPreProcessedSearchesInputsResults,
    getOutputLandsResults,
    isLoadingOutputLands,
    preProcessedSearchData,
    getFavoriteOutputLandsResults,
    isLoadingFavoriteLands,
    outputLandsResponse,
  };
};
