import { Typography } from "@mui/material";
import SideSearchIcon from "./SideSearchIcon";
import {
  CalendarMonth,
  ElectricBolt,
  LocationOn,
  SolarPower,
  Terrain,
} from "@mui/icons-material";
import { capitalizeFirstLetter, formatDate } from "../../utils/functions";
import {
  BackButton,
  HeaderText,
  ItemBox,
  StartAlignedItemBox,
  StyledChevronLeft,
  WrapperBox,
} from "../../styles/LandSearchSideBar/CardSearchDetails.styles";

const CardSearchDetails = ({ search, handleBack }) => {
  return (
    <WrapperBox>
      <BackButton onClick={handleBack} disableRipple disableFocusRipple>
        <StyledChevronLeft /> Search History
      </BackButton>
      <HeaderText variant="h6" gutterBottom>
        General Search Information
      </HeaderText>
      <ItemBox>
        <SideSearchIcon Icon={CalendarMonth} />
        <Typography variant="body1">
          {formatDate(search.created_date)}
        </Typography>
      </ItemBox>
      <StartAlignedItemBox>
        <SideSearchIcon Icon={LocationOn} />
        <Typography>{`${capitalizeFirstLetter(search.sub_region)}, ${
          search.entity_name
        }`}</Typography>
      </StartAlignedItemBox>
      <ItemBox>
        <SideSearchIcon Icon={SolarPower} />
        <Typography variant="body1">{search.technology_name}</Typography>
      </ItemBox>
      <ItemBox>
        <SideSearchIcon Icon={ElectricBolt} />
        <Typography variant="body1">
          {search.project_size}{" "}
          {search.project_size_unit === "acres"
            ? capitalizeFirstLetter(search.project_size_unit)
            : search.project_size_unit.toUpperCase()}
        </Typography>
      </ItemBox>
      <ItemBox>
        <SideSearchIcon Icon={Terrain} />
        <Typography variant="body1">
          {search.number_of_lands_found} Suitable Lands
        </Typography>
      </ItemBox>
    </WrapperBox>
  );
};

export default CardSearchDetails;
