import {
  GET_PRE_PROCESSED_SEARCHES_INPUTS,
  GET_PRE_PROCESSED_SEARCHES_INPUTS_SUCCESS,
  GET_PRE_PROCESSED_SEARCHES_INPUTS_FAILURE,
} from "../actions/types";

const initialState = {
  inputs: {
    request: null,
    response: null,
  },
  isLoading: false,
};

function searchPreProcessedLandsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PRE_PROCESSED_SEARCHES_INPUTS:
      return {
        ...state,
        inputs: {
          ...state.inputs,
          request: action.payload,
        },
        isLoading: true,
      };
    case GET_PRE_PROCESSED_SEARCHES_INPUTS_SUCCESS:
      return {
        ...state,
        inputs: {
          ...state.inputs,
          response: action.payload,
        },
        isLoading: false,
      };
    case GET_PRE_PROCESSED_SEARCHES_INPUTS_FAILURE:
      return {
        ...state,
        inputs: {
          ...state.inputs,
          response: action.payload,
        },
        isLoading: false,
      };
    default:
      return state;
  }
}

export default searchPreProcessedLandsReducer;
