import { INCREMENT_STEP, DECREMENT_STEP } from '../actions/types';

const initialState = {
  currentStep: 1,
  totalSteps: 5,
};

const stepperReducer = (state = initialState, action) => {
  switch (action.type) {
    case INCREMENT_STEP:
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };
    case DECREMENT_STEP:
      return {
        ...state,
        currentStep: state.currentStep - 1,
      };
    default:
      return state;
  }
};

export default stepperReducer;
