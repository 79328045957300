import {
  GET_OUTPUT_LANDS,
  GET_OUTPUT_LANDS_SUCCESS,
  SAVE_OUTPUT_LANDS,
  SAVE_OUTPUT_LANDS_SUCCESS,
  SAVE_OUTPUT_LANDS_FAILURE,
  CLEAR_RESULTS,
  GET_OUTPUT_LANDS_FAILURE,
  SET_INPUT_SEARCH_LABELS,
  SET_LOCKED_SEARCH_ENTITY,
} from "../actions/types";

function landsReducer(state = {}, action) {
  switch (action.type) {
    case GET_OUTPUT_LANDS:
      return {
        ...state,
        request: action.payload,
        isLoading: true,
      };
    case GET_OUTPUT_LANDS_SUCCESS:
      return {
        ...state,
        response: action.payload,
        isLoading: false,
      };

    case GET_OUTPUT_LANDS_FAILURE:
      return {
        ...state,
        response: [],
        isLoading: false,
      };
    case SAVE_OUTPUT_LANDS:
      return {
        ...state,
        requestSave: action.payload,
        isLoading: true,
      };
    case SAVE_OUTPUT_LANDS_SUCCESS:
      return {
        ...state,
        responseSave: action.payload,
        isLoading: false,
      };
    case SAVE_OUTPUT_LANDS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case CLEAR_RESULTS:
      return {
        ...state,
        responseSave: "",
      };

    case SET_INPUT_SEARCH_LABELS:
      return {
        ...state,
        selectedInputs: action.payload,
      };
    case SET_LOCKED_SEARCH_ENTITY:
      return {
        ...state,
        isLocked: action.payload,
      };
    default:
      return state;
  }
}

export default landsReducer;
