import {
  SET_PROJECT_TYPE,
  SET_SUBREGION,
  SET_SEARCH_ENTITY_ADM_LEVEL_1,
  SET_SEARCH_ENTITY,
  SET_PROJECT_SIZE_IN_MW,
  START_SEARCH,
  SEARCH_COMPLETED,
  SEARCH_FAILED,
  SEARCH_CONTINUE,
  SAVE_SEARCH,
  SET_SEARCH_PARAMS,
  GET_SEARCH_ENTITIES,
  GET_SEARCH_ENTITIES_SUCCESS,
  GET_SEARCH_ENTITIES_FAILURE,
  SET_SEARCH,
  SET_TAB,
  SET_ACRE,
} from "./types";

export const getSearchEntities = (params) => ({
  type: GET_SEARCH_ENTITIES,
  payload: params,
});

export const getSearchEntitiesSuccess = ({ data, inputs }) => ({
  type: GET_SEARCH_ENTITIES_SUCCESS,
  payload: { data, inputs },
});

export const getSearchEntitiesFailure = (error) => ({
  type: GET_SEARCH_ENTITIES_FAILURE,
  payload: error,
});

export const startSearch = ({
  token,
  projectSize,
  technology,
  searchEntity,
  subRegion,
  projectSizeUnit,
}) => ({
  type: START_SEARCH,
  payload: {
    token,
    projectSize,
    technology,
    searchEntity,
    subRegion,
    projectSizeUnit,
  },
});

export const searchCompleted = (result) => ({
  type: SEARCH_COMPLETED,
  payload: result,
});

export const searchFailed = (error) => ({
  type: SEARCH_FAILED,
  payload: error,
});

export const searchContinue = (payload) => ({
  type: SEARCH_CONTINUE,
  payload,
});

export const saveSearch = (payload) => ({
  type: SAVE_SEARCH,
  payload,
});

export const setProjectType = (payload) => ({
  type: SET_PROJECT_TYPE,
  payload,
});
export const setSubregion = (payload) => ({
  type: SET_SUBREGION,
  payload,
});
export const setSearchEntityAdmLevel1 = (payload) => ({
  type: SET_SEARCH_ENTITY_ADM_LEVEL_1,
  payload,
});
export const setSearchEntity = (payload) => ({
  type: SET_SEARCH_ENTITY,
  payload,
});

export const setProjectSizeInMw = (payload) => ({
  type: SET_PROJECT_SIZE_IN_MW,
  payload,
});

export const setAcre = (payload) => ({
  type: SET_ACRE,
  payload,
});

export const setSearchParams = (payload) => {
  return {
    type: SET_SEARCH_PARAMS,
    payload,
  };
};

export const setSearch = (payload) => ({
  type: SET_SEARCH,
  payload,
});
export const setTab = (payload) => ({
  type: SET_TAB,
  payload,
});
