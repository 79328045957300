export const outputLandsResponseSelector = (state) => state.lands?.response;
export const outputLandsRequestSelector = (state) => state.lands?.request;
export const selectedLandsSelector = (state) => state.selectedLands?.response;
export const saveLandsResponseSelector = (state) => state.lands?.responseSave;
export const isLoadingOutputLandsSelector = (state) => {
  return state.lands?.isLoading;
};
export const selectedLandsInputsSelector = (state) =>
  state.lands?.selectedInputs;

export const searchPreProcessedLandsInputsSelector = (state) =>
  state.searchPreProcessedLands?.inputs?.response;
export const isLoadingsearchPreProcessedLandsInputsSelector = (state) =>
  state.searchPreProcessedLands?.isLoading;
export const accessTokenSelector = (state) =>
  state.searchPreProcessedLands?.inputs?.request?.token;

export const isLockedSearchEntitySelector = (state) => state.lands?.isLocked;
