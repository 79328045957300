import { ChevronLeft } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import styled from "styled-components";

export const WrapperBox = styled(Box)`
  padding: 0px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const BackButton = styled(Button)`
  && {
    color: #52975c;
    text-transform: none;
    justify-content: flex-start;
    padding: 0;
    text-align: left;
    width: 100%;
    margin-left: -4px;
    font-size: 16px;

    &:hover {
      background-color: transparent;
    }

    &:active {
      background-color: transparent;
    }
  }
`;

export const ItemBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StartAlignedItemBox = styled(ItemBox)`
  align-items: flex-start;
`;

export const HeaderText = styled(Typography)`
  color: #000000;
  font-size: 16px;
`;

export const StyledChevronLeft = styled(ChevronLeft)`
  padding: 0px;
`;
