import React from "react";
import { Source, Layer } from "react-map-gl/maplibre";

const StaticLayers = ({ fillData, lineData }) => {
  return (
    <>
      {/* ESA-Eox Source and Layer */}

      {/* GeoJSON Data Source and Associated Layers */}
      <Source id="source-data" type="geojson" data={fillData}>
        {/* Fill layer for better click interaction */}
        <Layer
          id="land-polygons-fill"
          type="fill"
          layout={{}}
          paint={{
            "fill-color": "#35A344B2",
            "fill-opacity": 0.7,
          }}
        />
      </Source>

      <Source id="source-data-2" type="geojson" data={lineData}>
        {/* Layer for non-highlighted polygons (Lines) */}
        <Layer
          id="land-polygons-normal"
          type="line"
          filter={["!=", ["get", "highlighted"], true]}
          paint={{
            "line-color": [
              "case",
              ["<=", ["get", "ranking"], 3],
              "#A3DBA0",
              [
                "all",
                [">=", ["get", "ranking"], 4],
                ["<=", ["get", "ranking"], 8],
              ],
              "#F7F286",
              [">", ["get", "ranking"], 8],
              "#FFADAD",
              "#000000",
            ],
            "line-width": 2,
            "line-opacity": 0.7,
          }}
        />
      </Source>
      <Source id="source-data-3" type="geojson" data={lineData}>
        <Layer
          id="land-polygons-normal-fill"
          type="fill"
          layout={{}}
          paint={{
            "fill-color": "#051244B2",
            "fill-opacity": 0,
          }}
        />
      </Source>
    </>
  );
};

export default StaticLayers;
