import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import styled from "styled-components";
import { StyledSpinner } from "../Spinner.styled";

export const StyledBox = styled(Box)`
  flex: 1;
  position: relative;
  display: flex;
  gap: 0px;
  flex-direction: column;
  width: ${({ small }) => (small ? "15vw" : "auto")};
  min-width: ${({ small }) => (small ? "130px" : "auto")};
`;

export const StyledTypography = styled(Typography)`
  font-size: 12px !important;
  color: #212121;
  padding-left: ${({ small }) => (small ? "12px" : "auto")};
  padding: ${({ small }) => (!small ? "0px 12px" : "auto")};
  opacity: ${({ itemsLength }) => (itemsLength === 0 ? 0.5 : 1)};
`;

export const StyledAutocomplete = styled(Autocomplete)`
  font-size: 14px !important;
  .MuiAutocomplete-endAdornment .MuiSvgIcon-root {
    color: #212121;
  }
  & .MuiAutocomplete-clearIndicator svg {
    font-size: 16px;
  }
`;

export const StyledTextField = styled(TextField)`
  border: none;
  & fieldset {
    border: none;
  }
  font-size: 12px;
  && .MuiInputLabel-root {
    font-size: ${({ val }) => (val ? "0px" : "13px")};
    color: #818992;
  }
  && .MuiInputLabel-shrink {
    font-size: 0px;
  }
`;

export const VerticalLine = styled.div`
  position: absolute;
  right: ${({ small }) => (small ? "-12px" : "-8px")};
  width: 1px;
  height: ${({ small }) => (small ? "100%" : "70%")};
  background-color: #dddddd;
  top: ${({ small }) => (!small ? "15%" : "auto")};
`;

export const StyledOption = styled.li`
  font-size: 14px;
`;

export const SpinnerContainer = styled(Box)`
  justify-content: center;
  display: flex;
`;

export const ExtendedStyledSpinner = styled(StyledSpinner)`
  width: ${({ small }) => (small ? "28px" : "32px")};
  height: ${({ small }) => (small ? "28px" : "32px")};
  color: #52975c;
  opacity: 0.7;
`;
