import { useState } from "react";
import Header from "../components/Header";
import Hero from "../components/Hero";
import Footer from "../components/Footer";
import OutputLandMapCard from "../components/OutputLandMapCard";
import ProjectDetails from "../components/ProjectDetails";
import BoxFilterIcon from "../images/box_filter.png";
import ListFilterIcon from "../images/list_filter.png";
import useAuth0AccessToken from "../hooks/authHook";
import Modal from "../components/Modal";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../routes/constants";
import { StyledSpinner } from "../styles/Spinner.styled";
import {
  SearchResultsContainer,
  ResultsGrid,
  HorizontalRule,
  CenterButton,
  FilterNavContainer,
  SearchResultsContent,
  ConditionDropdown,
  FilterNavRight,
  FilterNavImage,
  ContinueButton,
  FilterLabel,
  StyledImage,
  StyledDiv,
  StyledSpan,
  StyledOption,
} from "../styles/SearchResults.styled";
/*
In case is needed, this is the Mock of the lands
import GeoData from '../response.json'
*/

const SearchResults = ({
  activeStep,
  outputLandsResponse,
  selectedLandsInputs,
  isLoadingLands,
  onSaveOutputLandsSelection,
  saveAsFavoriteLand,
  shouldShowModal,
  onCloseModal,
  modalMessage,
}) => {
  const [selectedMapCards, setSelectedMapCards] = useState([]);
  const { accessToken } = useAuth0AccessToken();
  const navigate = useNavigate();

  const onSubmit = () => {
    if (selectedMapCards.length > 0) {
      const params = {
        token: accessToken,
        selectedMapCards,
      };
      onSaveOutputLandsSelection(params);
    } else {
      // If selectedMapCards is empty, navigate to the map screen
      navigate(ROUTES.MAP);
    }
  };

  const handleMapCardCheckboxChange = (mapCardId, isChecked) => {
    if (isChecked) {
      setSelectedMapCards([...selectedMapCards, mapCardId]);
    } else {
      setSelectedMapCards(
        selectedMapCards.filter((id) => {
          return id !== mapCardId;
        })
      );
    }
  };

  return (
    <>
      <Header />

      <Hero
        activeStep={activeStep}
        title="See Your Findings"
        subtitle="After evaluating all potential parcels of land in the selected area, we identified the most suitable project locations for your envisioned projects, presented below."
        gradient={{
          title: { start: "#EBEFDE", end: "#C3DFC3" },
          subtitle: { start: "#EBEFDE", end: "#C3DFC3" },
        }}
      />

      {shouldShowModal && (
        <Modal isOpen={shouldShowModal} onClose={() => onCloseModal()}>
          <h2>Warning</h2>
          <p>{modalMessage}</p>
        </Modal>
      )}
      <HorizontalRule />
      <SearchResultsContent>
        <ProjectDetails
          typeLabel={selectedLandsInputs?.technology?.label}
          sizeLabel={selectedLandsInputs?.projectSize?.label}
          regionLabel={selectedLandsInputs?.region?.label}
        />

        <HorizontalRule />
        <SearchResultsContainer>
          <FilterNavContainer>
            <StyledDiv>
              <StyledSpan>
                Selected {selectedMapCards.length}/{outputLandsResponse?.length}
              </StyledSpan>
            </StyledDiv>
            <FilterNavRight>
              <FilterLabel>Sort By </FilterLabel>
              <ConditionDropdown>
                <StyledOption value="excellent">Excellent</StyledOption>
                <StyledOption value="medium">Medium</StyledOption>
                <StyledOption value="low">Low</StyledOption>
              </ConditionDropdown>
              <FilterNavImage>
                <StyledImage src={ListFilterIcon} alt="list-filter" />
              </FilterNavImage>
              <FilterNavImage>
                <StyledImage src={BoxFilterIcon} alt="box-filter" />
              </FilterNavImage>
            </FilterNavRight>
          </FilterNavContainer>
          <ResultsGrid>
            {Array.isArray(outputLandsResponse) &&
            outputLandsResponse?.length > 0 ? (
              outputLandsResponse
                .sort((a, b) => b.ac_score - a.ac_score)
                .map((landData, index) => {
                  return (
                    <StyledDiv key={index}>
                      <OutputLandMapCard
                        landData={landData}
                        mapCardId={landData.output_land_id}
                        onCheckboxChange={handleMapCardCheckboxChange}
                        onSaveOutputLandsSelection={onSaveOutputLandsSelection}
                        saveAsFavoriteLand={saveAsFavoriteLand}
                        number={index}
                      />
                    </StyledDiv>
                  );
                })
            ) : (
              <p>No se encontraron resultados.</p> // Puedes personalizar este mensaje.
            )}
          </ResultsGrid>
          <CenterButton>
            <ContinueButton onClick={() => onSubmit()}>
              Continue {isLoadingLands && <StyledSpinner />}
            </ContinueButton>
          </CenterButton>
        </SearchResultsContainer>
      </SearchResultsContent>

      <Footer />
    </>
  );
};

export default SearchResults;
