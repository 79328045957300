import {
  GET_FAVORITE_OUTPUT_LANDS,
  GET_FAVORITE_OUTPUT_LANDS_SUCCESS,
  GET_FAVORITE_OUTPUT_LANDS_FAILURE,
  SAVE_FAVORITE_OUTPUT_LAND,
  SAVE_FAVORITE_OUTPUT_LAND_SUCCESS,
  SAVE_FAVORITE_OUTPUT_LAND_FAILURE,
} from "../actions/types";

function favoriteLandsReducer(state = {}, action) {
  switch (action.type) {
    case GET_FAVORITE_OUTPUT_LANDS:
      return {
        ...state,
        request: action.payload,
        isLoading: true,
      };
    case GET_FAVORITE_OUTPUT_LANDS_SUCCESS:
      return {
        ...state,
        response: action.payload,
        isLoading: false,
      };

    case GET_FAVORITE_OUTPUT_LANDS_FAILURE:
      return {
        ...state,
        response: [],
        isLoading: false,
      };

    case SAVE_FAVORITE_OUTPUT_LAND:
      return {
        ...state,
        requestSave: action.payload,
        isLoading: true,
      };
    case SAVE_FAVORITE_OUTPUT_LAND_SUCCESS:
      return {
        ...state,
        responseSave: action.payload,
        isLoading: false,
      };
    case SAVE_FAVORITE_OUTPUT_LAND_FAILURE:
      return {
        ...state,
        responseSave: [],
        isLoading: false,
      };

    default:
      return state;
  }
}

export default favoriteLandsReducer;
