import styled from "styled-components";
import { motion } from "framer-motion";

export const StyledMotionWrapperDiv = styled(motion.div)`
  position: fixed;
  top: 166px;
  left: 0;
  height: 100vh;
  z-index: 1000;
`;
