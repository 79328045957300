import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useState } from "react";
import CreateCollectionModal from "./CreateCollectionModal";

const NoCollectionPage = ({ addCollection }) => {
  const [add, setAdd] = useState(false);

  return (
    <Box
      sx={{
        backgroundColor: "#F7FAFC",
        borderTop: "1px solid #C3CCD7",
        position: "relative",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {add && (
        <CreateCollectionModal
          setAddOpen={() => {}}
          setOpen={setAdd}
          addCollection={addCollection}
        />
      )}
      <Box>
        <Typography
          sx={{
            fontSize: "40px",
            fontWeight: 700,
            marginTop: "40px",
            paddingLeft: "8px",
          }}
        >
          {" "}
          Collections{" "}
        </Typography>
        <Box
          sx={{
            borderRadius: "28px",
            padding: "119px 174px",
            gap: "22px",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            borderBottom: "1px solid #C3CCD7",
            width: "850px",
            marginTop: "40px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontSize: "24px", fontWeight: 500 }}>
            {" "}
            Create your first collection{" "}
          </Typography>
          <Typography sx={{ fontSize: "16px", fontWeight: 400 }}>
            {" "}
            AS you search, click the bookmark icon to save the sites your
            interested in and want to group with others to makee a collection{" "}
          </Typography>
          <Button
            variant="long"
            sx={{ height: "48px", width: "310px", backgroundColor: "#52975C" }}
            onClick={() => setAdd(true)}
          >
            {" "}
            Done{" "}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default NoCollectionPage;
