import React from "react";
import { Box, ToggleButtonGroup, ToggleButton } from "@mui/material";
import QuestionModal from "./QuestionModal";
import { useState } from "react";
import BannerBlackLogo from "../images/new-black-logo.png";
import SearchBar from "./SearchBar";
import AnalyzeLandModal from "./AnalyzeLandModal";
import UsernameButton from "../components/UsernameButton";
import useUser from "../hooks/userHook";

const HeaderBar = ({
  tab,
  changeTab,
  search,
  setToCollection,
  isSearching,
  setViewState,
}) => {
  const [help, setHelp] = useState(false);
  const [analyze, setAnalyze] = useState(false);
  const { userInfo, onLogout } = useUser();
  const userName = (userInfo && (userInfo.name || userInfo.given_name)) || "";

  //Search stuff should be done in redux probably!! Honestly need access to it here!

  function refreshPage() {
    window.location.reload();
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        paddingX: "24px",
        paddingY: "16px",
        width: "100%",
        position: "relative",
      }}
    >
      {help && <QuestionModal setHelp={setHelp} />}
      {analyze && <AnalyzeLandModal setOpen={setAnalyze} />}

      <Box sx={{ position: "absolute", top: "16px", left: "24px" }}>
        <img
          src={BannerBlackLogo}
          width={"115px"}
          style={{ cursor: "pointer" }}
          onClick={refreshPage}
          alt="Banner Logo"
        />
      </Box>
      {search ? (
        <SearchBar
          search={search}
          isSearching={isSearching}
          setToCollection={setToCollection}
          setViewState={setViewState}
        />
      ) : (
        <ToggleButtonGroup
          value={tab}
          exclusive
          onChange={changeTab}
          sx={{
            height: "40px",
            display: "flex",
            justifyContent: "center",
            gap: "24px",
            alignItems: "center",
          }}
        >
          <ToggleButton variant="out" value="CreateProject">
            {" "}
            Create Project{" "}
          </ToggleButton>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ToggleButton
              variant="out"
              value="AnalyzeLand"
              sx={{ color: "#818992" }}
              onClick={() => setAnalyze(true)}
            >
              {" "}
              Analyze Land{" "}
            </ToggleButton>
            <div
              style={{
                borderRadius: "40px",
                color: "#52975C",
                backgroundColor: "#D5E6D8",
                fontSize: "12px",
                padding: "4px 8px",
                textTransform: "uppercase",
              }}
            >
              Coming Soon
            </div>
          </Box>
        </ToggleButtonGroup>
      )}

      <Box>
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
            position: "absolute",
            right: "24px",
            top: "16px",
            cursor: "pointer",
          }}
        >
          {/*<HelpOutlineIcon
            sx={{ cursor: "pointer" }}
            onClick={() => setHelp(!help)}
          />*/}
          <UsernameButton userName={userName} onClick={onLogout} />
        </Box>
      </Box>
    </Box>
  );
};

export default HeaderBar;
