import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mapStyle: "base",
};

const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    setMapStyle: (state, action) => {
      state.mapStyle = action.payload;
    },
  },
});

export const { setMapStyle } = mapSlice.actions;
export default mapSlice.reducer;
