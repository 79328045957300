import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

import landsReducer from "./landsReducer";
import layersReducer from "./layersReducer";
import favoriteLandsReducer from "./favoriteLandsReducer";
import searchPreProcessedLandsReducer from "./searchPreProcessedLandsReducer";
import selectedLandsReducer from "./selectedLandsReducer";
import stepperReducer from "./stepperReducer";
import userReducer from "./userReducer";
import modalReducer from "./modalReducer";
import searchReducer from "./searchReducer";
import subRegionReducer from "./subRegionReducer";
import analyzeLandReducer from "./analyzeLandReducer";
import authReducer from "./authReducer";
import collectionsReducer from "./collectionsReducer";
import mapReducer from "./mapSlice";
import { RESET_STORE } from "../actions/types";
import landSearchReducer from "./landSearchReducer";

const excludeLoading = (namers) => ({
  key: namers,
  storage,
  blacklist: ["isLoading"],
});

const includeLoading = (namers) => ({
  key: namers,
  storage,
});

const appReducer = combineReducers({
  lands: persistReducer(excludeLoading("lands"), landsReducer),
  layers: persistReducer(includeLoading("layers"), layersReducer),
  favoriteLands: persistReducer(
    includeLoading("favoriteLands"),
    favoriteLandsReducer
  ),
  searchPreProcessedLands: persistReducer(
    includeLoading("searchPreProcessedLands"),
    searchPreProcessedLandsReducer
  ),
  selectedLands: persistReducer(
    includeLoading("selectedLands"),
    selectedLandsReducer
  ),
  stepper: persistReducer(includeLoading("stepper"), stepperReducer),
  user: persistReducer(includeLoading("user"), userReducer),
  modal: persistReducer(includeLoading("modal"), modalReducer),
  search: persistReducer(includeLoading("search"), searchReducer),
  subRegion: persistReducer(includeLoading("subRegion"), subRegionReducer),
  analyzeLand: persistReducer(
    includeLoading("analyzeLand"),
    analyzeLandReducer
  ),
  auth: persistReducer(includeLoading("auth"), authReducer),
  collections: persistReducer(
    includeLoading("collections"),
    collectionsReducer
  ),
  map: mapReducer,
  landSearch: landSearchReducer,
});

// Root reducer to handle global reset
const rootReducer = (state, action) => {
  // Handle the RESET_STORE action to reset the store
  if (action.type === RESET_STORE) {
    state = undefined; // Set state to undefined to reset all reducers
  }

  return appReducer(state, action);
};

export default rootReducer;
