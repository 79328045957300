import { call, put, delay } from "redux-saga/effects";
import {
  searchCompleted,
  searchFailed,
  getSearchEntitiesSuccess,
  getSearchEntitiesFailure,
} from "../../actions/searchesActions";
import * as api from "../../api";
import { STATUS_CODES } from "../../../utils/constants";
import { GET_OUTPUT_LANDS, SAVE_SEARCH } from "../../actions/types";
import { setShowModal, setModalMessage } from "../../actions/modalActions";
import { redirectToLogin } from "../../actions/authActions";

const GET_SEARCH_STATUS_DELAY_MS = 3000;

export function* getSearchEntitiesSaga(action) {
  try {
    const response = yield call(api.getSearchEntities, action.payload);
    const inputs = {
      sub_region_id: action.payload.sub_region_id,
      technology_id: action.payload.technology_id,
      search_entity_adm_level_1_entity_id:
        action.payload.search_entity_adm_level_1_entity_id,
    };
    yield put(
      getSearchEntitiesSuccess({ data: response.data, inputs: inputs })
    );
  } catch (error) {
    if (error.response && error.response.status === STATUS_CODES.UNAUTHORIZED) {
      // Redirect the user to the login page if the status is UNAUTHORIZED
      yield put(redirectToLogin());
    } else {
      yield put(getSearchEntitiesFailure(error.message));
    }
  }
}

export function* fetchSearchResults(action) {
  try {
    const response = yield call(api.fetchSearchOrchestrator, action.payload);
    const statusQueryGetUri = response.data.statusQueryGetUri;
    let isSearchCompleted = false;

    yield put({
      type: SAVE_SEARCH,
      payload: {
        searchId: response.data.id,
        statusUrl: statusQueryGetUri,
      },
    });

    while (!isSearchCompleted) {
      yield delay(GET_SEARCH_STATUS_DELAY_MS);

      const statusResponse = yield call(
        api.fetchStatusQuery,
        statusQueryGetUri
      );

      if (statusResponse?.data?.runtimeStatus === "Completed") {
        isSearchCompleted = true;
        yield put(searchCompleted());
        // Calling to the action of getting lands. Inside it manages it's success and failure
        yield put({ type: GET_OUTPUT_LANDS, payload: action.payload });
      }
    }
  } catch (error) {
    if (error.response && error.response.status === STATUS_CODES.UNAUTHORIZED) {
      // Redirect the user to the login page if the status is UNAUTHORIZED
      yield put(redirectToLogin());
    } else {
      yield put(searchFailed(error.message));
    }
  }
}

export function* continueSearchResults(action) {
  try {
    let isSearchCompleted = false;
    if (action?.payload?.statusUrl) {
      let statusResponse = yield call(
        api.fetchStatusQuery,
        action.payload.statusUrl
      );

      while (!isSearchCompleted) {
        if (
          statusResponse?.data?.runtimeStatus === "Completed" ||
          statusResponse?.data?.runtimeStatus === "Failed"
        ) {
          isSearchCompleted = true;
          yield put(searchCompleted());
          yield put({
            type: GET_OUTPUT_LANDS,
            payload: action.payload.searchParams,
          }); //Does this work??? action.payload?
        }

        yield delay(GET_SEARCH_STATUS_DELAY_MS);

        statusResponse = yield call(
          api.fetchStatusQuery,
          action.payload.statusUrl
        );
      }
    }
  } catch (error) {
    //Set modal error message
    yield put(
      setModalMessage("An error has occurred while trying to resume the search")
    );

    // Open modal with error message
    yield put(setShowModal(true));

    // dispatch searchFailed
    yield put(searchFailed(error.message));
  }
}
