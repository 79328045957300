import { CardContent, Typography } from "@mui/material";
import SideSearchIcon from "./SideSearchIcon";
import {
  CalendarMonth,
  ElectricBolt,
  LocationOn,
  SolarPower,
  Terrain,
} from "@mui/icons-material";
import { capitalizeFirstLetter, formatDate } from "../../utils/functions";
import {
  LocationTypography,
  SpacedStyledCardItem,
  SpacedStyledCardItemContainer,
  StyledCardContainer,
  StyledCardItem,
  StyledSeeSearchButton,
} from "../../styles/LandSearchSideBar/CardSearch.styles";

const CardSearch = ({ search, index, onSeeSearch }) => {
  return (
    <>
      <StyledCardContainer key={index}>
        <CardContent>
          <StyledCardItem>
            <SideSearchIcon Icon={CalendarMonth} />

            <Typography variant="body1">
              {formatDate(search.created_date)}
            </Typography>
          </StyledCardItem>
          <StyledCardItem>
            <SideSearchIcon Icon={LocationOn} />
            <LocationTypography variant="body1">{`${capitalizeFirstLetter(
              search.sub_region
            )}, ${search.entity_name}`}</LocationTypography>
          </StyledCardItem>
          <SpacedStyledCardItemContainer>
            <SpacedStyledCardItem>
              <SideSearchIcon Icon={SolarPower} />
              <Typography variant="body1">{search.technology_name}</Typography>
            </SpacedStyledCardItem>

            <SpacedStyledCardItem>
              <SideSearchIcon Icon={ElectricBolt} />
              <Typography variant="body1">
                {search.project_size}{" "}
                {search.project_size_unit === "acres"
                  ? capitalizeFirstLetter(search.project_size_unit)
                  : search.project_size_unit.toUpperCase()}
              </Typography>
            </SpacedStyledCardItem>

            <SpacedStyledCardItem>
              <SideSearchIcon Icon={Terrain} />
              <Typography variant="body1">
                {search.number_of_lands_found} Suitable Lands
              </Typography>
            </SpacedStyledCardItem>
          </SpacedStyledCardItemContainer>
          <StyledSeeSearchButton
            variant="contained"
            onClick={() => onSeeSearch(search)}
          >
            See Search
          </StyledSeeSearchButton>
        </CardContent>
      </StyledCardContainer>
    </>
  );
};

export default CardSearch;
