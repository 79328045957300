import React from "react";
import "../styles/Stepper.css";
import Step1 from "../images/step1.png";
import Step2 from "../images/step2.png";
import Step3 from "../images/step3.png";

const Stepper = ({ steps, activeStep }) => {
  const images = [Step1, Step2, Step3];

  const getStepClass = (step) => {
    let cls = "step";
    if (activeStep === step) {
      cls += " step-active";
    } else if (activeStep > step) {
      cls += " step-done";
    } else {
      cls += " step-inactive";
    }
    return cls;
  };

  return (
    <div className="steps-container">
      {steps?.map((label, index) => (
        <div className={getStepClass(index)} key={index}>
          {/* <div><div className="circle">{index + 1}</div></div> */}
          <img src={images[index]} alt="" />
          {/* <div className="label">{label}</div> */}
          {index < steps.length - 1 && <div className="line"></div>}
        </div>
      ))}
    </div>
  );
};

export default Stepper;
