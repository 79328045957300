const searchSelector = (state) => state.search;

export const projectSizeInMWSelector = (state) =>
  searchSelector(state)?.projectSizeInMW;

export const acresSelector = (state) => searchSelector(state)?.acre;

export const projectTypeSelector = (state) =>
  searchSelector(state)?.projectType;

export const subRegionSelector = (state) => searchSelector(state)?.subRegion;

export const loadSearchSelector = (state) => {
  return searchSelector(state)?.loading;
};

export const searchIdSelector = (state) => searchSelector(state)?.searchId;

export const statusUrlSelector = (state) => searchSelector(state)?.statusUrl;

export const searchParamsSelector = (state) =>
  searchSelector(state)?.searchParams;

export const searchEntitiesInfoSelector = (state) =>
  searchSelector(state)?.searchEntities;

export const searchEntitiesInputsSelector = (state) =>
  searchEntitiesInfoSelector(state)?.inputs;

export const searchEntitiesDataSelector = (state) =>
  searchEntitiesInfoSelector(state)?.data;

export const searchEntityValueSelector = (state) =>
  searchSelector(state)?.searchEntity;

export const searchEntitiesAdmLevel1ValueSelector = (state) =>
  searchSelector(state)?.searchEntityAdmLevel1;

export const searchEntitiesSelector = (state) =>
  searchEntitiesDataSelector(state)?.search_entities;

export const searchEntitiesListSelector = (state) =>
  searchEntitiesSelector(state)?.list;

export const searchEntitiesPropertiesSelector = (state) =>
  searchEntitiesSelector(state)?.properties;

export const searchEntitiesLoaderSelector = (state) =>
  searchSelector(state)?.searchEntitiesLoading;

export const admLevelEntitiesSelector = (state) =>
  searchEntitiesDataSelector(state)?.adm_level_entities;

// Create more administrative levels out from admLevelEntitiesSelector as needed (check out limit in BE. Actually it is 3)
export const searchEntitiesAdmLevel1Selector = (state) =>
  admLevelEntitiesSelector(state)?.search_entities_adm_level_1;

export const searchEntitiesAdmLevel1ListSelector = (state) =>
  searchEntitiesAdmLevel1Selector(state)?.list;

export const searchEntitiesAdmLevel1LayerPublicIdSelector = (state) =>
  searchEntitiesAdmLevel1Selector(state)?.layer_public_id;

export const searchClickSelector = (state) => searchSelector(state)?.search;
export const tabSelector = (state) => searchSelector(state)?.tab;
