import {
  CLEAR_RESULTS,
  SAVE_OUTPUT_LANDS,
  SAVE_OUTPUT_LANDS_SUCCESS,
  SAVE_OUTPUT_LANDS_FAILURE,
  GET_OUTPUT_LANDS,
  GET_OUTPUT_LANDS_SUCCESS,
  GET_OUTPUT_LANDS_FAILURE,
  GET_SELECTED_LANDS_RESULTS,
  GET_SELECTED_LANDS_RESULTS_SUCCESS,
  GET_SELECTED_LANDS_RESULTS_FAILURE,
  GET_PRE_PROCESSED_SEARCHES_INPUTS,
  GET_PRE_PROCESSED_SEARCHES_INPUTS_SUCCESS,
  GET_PRE_PROCESSED_SEARCHES_INPUTS_FAILURE,
  GET_FAVORITE_OUTPUT_LANDS,
  GET_FAVORITE_OUTPUT_LANDS_SUCCESS,
  GET_FAVORITE_OUTPUT_LANDS_FAILURE,
  SET_INPUT_SEARCH_LABELS,
  SAVE_FAVORITE_OUTPUT_LAND,
  SAVE_FAVORITE_OUTPUT_LAND_SUCCESS,
  SAVE_FAVORITE_OUTPUT_LAND_FAILURE,
  SET_LOCKED_SEARCH_ENTITY,
} from "./types";

export const clearResponse = (payload) => ({
  type: CLEAR_RESULTS,
  payload,
});

export const saveOutputLands = (payload) => ({
  type: SAVE_OUTPUT_LANDS,
  payload,
});

export const saveOutputLandsSuccess = (payload) => ({
  type: SAVE_OUTPUT_LANDS_SUCCESS,
  payload,
});

export const saveOutputLandsFailure = (error) => ({
  type: SAVE_OUTPUT_LANDS_FAILURE,
  payload: error,
});

export const getPreProcessedSearchesInputs = (payload) => ({
  type: GET_PRE_PROCESSED_SEARCHES_INPUTS,
  payload,
});

export const getPreProcessedSearchesInputsSuccess = (payload) => ({
  type: GET_PRE_PROCESSED_SEARCHES_INPUTS_SUCCESS,
  payload,
});

export const getPreProcessedSearchesInputsFailure = (error) => ({
  type: GET_PRE_PROCESSED_SEARCHES_INPUTS_FAILURE,
  payload: error,
});

export const getOutputLands = (payload) => ({
  type: GET_OUTPUT_LANDS,
  payload,
});

export const getOutputLandsSuccess = (lands) => ({
  type: GET_OUTPUT_LANDS_SUCCESS,
  payload: lands,
});

export const setLockedSearchEntity = (isLocked) => ({
  type: SET_LOCKED_SEARCH_ENTITY,
  payload: isLocked,
});

export const setInputSearchLabels = (lands) => ({
  type: SET_INPUT_SEARCH_LABELS,
  payload: lands,
});

export const getOutputLandsFailure = (error) => ({
  type: GET_OUTPUT_LANDS_FAILURE,
  payload: error,
});

export const getSelectedLandResults = (payload) => ({
  type: GET_SELECTED_LANDS_RESULTS,
  payload,
});

export const getSelectedLandResultsSuccess = (lands) => ({
  type: GET_SELECTED_LANDS_RESULTS_SUCCESS,
  payload: lands,
});

export const getSelectedLandResultsFailure = (error) => ({
  type: GET_SELECTED_LANDS_RESULTS_FAILURE,
  payload: error,
});

export const getFavoriteOutputLands = (payload) => ({
  type: GET_FAVORITE_OUTPUT_LANDS,
  payload,
});

export const getFavoriteOutputLandsSuccess = (lands) => ({
  type: GET_FAVORITE_OUTPUT_LANDS_SUCCESS,
  payload: lands,
});

export const getFavoriteOutputLandsFailure = (error) => ({
  type: GET_FAVORITE_OUTPUT_LANDS_FAILURE,
  payload: error,
});

export const saveFavoriteOutputLand = (payload) => ({
  type: SAVE_FAVORITE_OUTPUT_LAND,
  payload,
});

export const saveFavoriteOutputLandSuccess = (payload) => ({
  type: SAVE_FAVORITE_OUTPUT_LAND_SUCCESS,
  payload,
});

export const saveFavoriteOutputLandFailure = (error) => ({
  type: SAVE_FAVORITE_OUTPUT_LAND_FAILURE,
  payload: error,
});
