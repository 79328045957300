import { uploadKmzFailure, uploadKmzSuccess } from "../../actions/analyzeLand";

import * as api from "../../api";
import { call, put } from "redux-saga/effects";

export function* uploadKmzSaga(action) {
  try {
    const response = yield call(api.uploadKmz, action.payload);
    yield put(uploadKmzSuccess(response.data));
  } catch (error) {
    yield put(uploadKmzFailure(error.message));
  }
}
