import React from "react";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import Map from "react-map-gl/maplibre";
import { centroid } from "@turf/turf";
import { Source, Layer } from "react-map-gl/maplibre";
import { useEffect } from "react";
import proj4 from "proj4";
import { useRef } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { Box } from "@mui/material";

const MapCard = ({ geoJSON }) => {
  //Do I need to reproject the coordinates??
  const ref = useRef(null);
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting)
      ),
    [ref]
  );

  useEffect(() => {
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, []);

  const convertCoordinates = (coordinates) => {
    return coordinates.map((coord) => proj4("EPSG:3857", "EPSG:4326", coord));
  };
  const coord = {
    coordinates: geoJSON.geom?.coordinates.map((polygon) =>
      polygon.map(convertCoordinates)
    ),
    type: geoJSON.geom.type,
  };
  const center = centroid(coord).geometry?.coordinates;
  const newViewState = {
    longitude: center[0],
    latitude: center[1],
    zoom: 12,
    dragPan: false,
  };

  return (
    <Box ref={ref} sx={{ height: "100%", width: "100%", position: "relative" }}>
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: 10,
          cursor: "default",
        }}
      />
      {isIntersecting && (
        <Map
          {...newViewState}
          style={{ width: "100%" }}
          mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
          attributionControl={false}
        >
          <Source
            id="esa-eox-source"
            type="raster"
            tileSize={256}
            tiles={[
              "https://tiles.maps.eox.at/wmts?layer=s2cloudless-2020_3857&style=default&tilematrixset=GoogleMapsCompatible&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image/png&TileMatrix={z}&TileCol={x}&TileRow={y}",
            ]}
          >
            <Layer id="esa-eox-layer" type="raster" source="esa-eox-source" />
          </Source>

          <Source id="source-data" type="geojson" data={coord}>
            {/* Fill layer for better click interaction */}
            <Layer
              id="land-polygons-fill"
              type="fill"
              source="source-data"
              layout={{}}
              paint={{
                "fill-color": "#35A344B2",
                "fill-opacity": 0.7,
              }}
            />
          </Source>
        </Map>
      )}
    </Box>
  );
};

export default MapCard;
