import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import {
  ExtendedStyledSpinner,
  SpinnerContainer,
  StyledAutocomplete,
  StyledBox,
  StyledOption,
  StyledTextField,
  StyledTypography,
  VerticalLine,
} from "../styles/SelectSearchWithAutocomplete/SelectSearchWithAutocomplete.styles";
import "../styles/SelectSearchWithAutocomplete/SelectSearchWithAutocomplete.css";

const AutocompleteSearch = ({
  val,
  changeVal,
  textHeader,
  textSelect,
  last = false,
  items,
  small,
  load,
}) => {
  const [filteredOptions, setFilteredOptions] = useState(items);
  const [inputValue, setInputValue] = useState("");

  const normalizeString = (str) => str?.toLowerCase().trim();

  useEffect(() => {
    const normalizedInput = normalizeString(inputValue);

    const newFilteredOptions = items.filter((item) =>
      normalizeString(item.label)?.includes(normalizedInput)
    );

    setFilteredOptions(newFilteredOptions);
  }, [inputValue, items]);
  return (
    <StyledBox small={small}>
      <StyledTypography small={small} itemsLength={items.length}>
        {textHeader}
      </StyledTypography>
      {!load ? (
        <StyledAutocomplete
          value={items[val]}
          className="custom-autocomplete"
          onChange={(event, newValue) => {
            changeVal(newValue);
            setInputValue(newValue ? newValue.label : "");
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          options={filteredOptions}
          isOptionEqualToValue={(option, value) => option.value === value}
          getOptionLabel={(option) => (option ? option.label : "")}
          renderOption={(props, option) => (
            <StyledOption {...props} key={option.id || option.label}>
              {option.label}
            </StyledOption>
          )}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              label={inputValue ? "" : textSelect}
              variant="outlined"
              size={small ? "small" : "medium"}
              val={val}
              InputLabelProps={{
                shrink: false,
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {load ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      ) : (
        <SpinnerContainer>
          <ExtendedStyledSpinner small={small} />
        </SpinnerContainer>
      )}
      {!last && <VerticalLine small={small} />}
    </StyledBox>
  );
};

export default AutocompleteSearch;
