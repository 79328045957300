import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getDisplayedLayersResults } from "../redux/actions/layersActions";
import { displayedLayersSelector } from "../redux/selectors/displayedLayers";

// Needed Constants --> Move to correspondent place.
const GEO_SERVER_DOMAIN_URL = process.env.REACT_APP_GEO_SERVER_DOMAIN_URL;
const GEO_SERVER_WORKSPACE_NAME =
  process.env.REACT_APP_GEO_SERVER_WORKSPACE_NAME;
const baseUrl = `${GEO_SERVER_DOMAIN_URL}/ows`;
const service = `SERVICE=WMS`;
const version = "VERSION=1.1.1";
const request = "REQUEST=GetMap";
const format = "FORMAT=image/png";
const transparent = "TRANSPARENT=true";
const styles = "STYLES=";
const srs = "SRS=EPSG:3857";
const width = "WIDTH=256";
const height = "HEIGHT=256";
const bbox = "BBOX={bbox-epsg-3857}";

// Starting hook useLayers
export const useLayers = () => {
  const dispatch = useDispatch();
  const [activeLayers, setActiveLayers] = useState([]);
  const [layersStructure, setLayersStructure] = useState({ regions: {} });

  const displayedLayers = useSelector(displayedLayersSelector);

  const getDisplayedLayers = useCallback(
    (data) => {
      const params = {
        token: data,
      };
      dispatch(getDisplayedLayersResults(params));
    },
    [dispatch]
  );

  // Function to toggle layer activation
  const toggleLayer = (layerName) => {
    if (activeLayers.includes(layerName)) {
      setActiveLayers(activeLayers.filter((l) => l !== layerName));
    } else {
      setActiveLayers([...activeLayers, layerName]);
    }
  };

  useEffect(() => {
    if (!Array.isArray(displayedLayers)) {
      return;
    }

    const structure = displayedLayers?.reduce(
      (acc, layer) => {
        if (!layer) return acc;

        const {
          id,
          layer_table_name: layerTableName,
          layer_category: category,
          layer_subcategory: subcategory,
          layer_name: layerName,
        } = layer;
        // Extracting region and subregion from layerName
        const [region, subregion] = layerTableName.split("_");

        const layers = `LAYERS=${GEO_SERVER_WORKSPACE_NAME}:${layerTableName}`;
        const tileUrl = `${baseUrl}?${service}&${version}&${request}&${format}&${layers}&${transparent}&${styles}&${srs}&${width}&${height}&${bbox}`;

        // Inicializar 'regions' si aún no existe
        if (!acc.regions) {
          acc.regions = {};
        }

        // Inicializar un objeto para la región si aún no existe
        if (!acc.regions[region]) {
          acc.regions[region] = {};
        }

        // Create section for the subregion if it doesn't exist
        if (!acc.regions[region][subregion]) {
          acc.regions[region][subregion] = {};
        }

        // Create section for the category if it doesn't exist
        if (!acc.regions[region][subregion][category]) {
          acc.regions[region][subregion][category] = {};
        }

        // Create section for the subcategory if it doesn't exist
        if (
          subcategory &&
          !acc.regions[region][subregion][category][subcategory]
        ) {
          acc.regions[region][subregion][category][subcategory] = [];
        }
        acc.regions[region][subregion][category][subcategory].push({
          id,
          name: layerName,
          tileUrl,
        });

        return acc;
      },
      { regions: {} }
    );

    setLayersStructure(structure);
  }, [displayedLayers]);

  return {
    layersStructure,
    toggleLayer,
    getDisplayedLayers,
    activeLayers,
    displayedLayers,
  };
};
