import React from "react";
import { Source, Layer } from "react-map-gl/maplibre";

const DynamicLayers = ({ layersStructure, activeLayers }) => {
  // Generate layer elements from the structure
  const layerElements = Object.entries(layersStructure).flatMap(
    ([regionName, region]) =>
      Object.entries(region).flatMap(([subregionName, subregion]) =>
        Object.entries(subregion).flatMap(([categoryName, category]) =>
          Object.entries(category).flatMap(([subcategoryName, subcategory]) =>
            subcategory
              .filter((layer) => activeLayers.includes(layer.name))
              .map((layer) => (
                <Source
                  key={`${layer.name}-source`}
                  id={`${layer.name}-source`}
                  type="raster"
                  tiles={[layer.tileUrl]}
                  tileSize={256}
                >
                  <Layer
                    key={`${layer.name}-layer`}
                    id={`${layer.name}-layer`}
                    type="raster"
                    source={`${layer.name}-source`}
                  />
                </Source>
              ))
          )
        )
      )
  );

  return <>{layerElements}</>;
};

export default DynamicLayers;
