import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { hasGeometries } from "../utils/functions";
import SiteCard from "./SiteCard";
const SiteResults = ({
  outputLandsResponse,
  handleSelectedLand,
  sideRef,
  collectionData,
  addCollection,
  addToFolder,
  selectedLand,
  setReadMore,
  setQuestion,
}) => {
  //TODO: Fix: Consume it from setLockedSearchEntity, instead.
  const geometriesExist = hasGeometries(outputLandsResponse);
  return (
    <Box
      sx={{
        flex: "0 0 40%",
        maxWidth: "600px",
        overflowY: "scroll",
        display: "flex",
      }}
    >
      <Box
        sx={{
          overflowY: "scroll",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          paddingX: "16px",
          flexGrow: 1,
          paddingBottom: "150px",
        }}
      >
        <Typography sx={{ fontWeight: 500, fontSize: "16px" }}>
          {" "}
          {outputLandsResponse?.length} Results
        </Typography>

        {!geometriesExist && (
          <Button onClick={setQuestion}>Unlock substation</Button>
        )}

        {outputLandsResponse
          .sort((a, b) => b.ac_score - a.ac_score)
          .map((landData, index) => {
            return (
              <div
                key={index}
                ref={sideRef.current[landData.id]}
                onClick={() => handleSelectedLand(landData.id)}
              >
                <SiteCard
                  landData={landData}
                  number={index}
                  collectionData={collectionData}
                  addCollection={addCollection}
                  addToFolder={addToFolder}
                  borderLand={selectedLand === landData.id}
                  setReadMore={setReadMore}
                />
              </div>
            );
          })}
      </Box>
    </Box>
  );
};

export default SiteResults;
