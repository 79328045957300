import React from "react";
import { Paper, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setMapStyle } from "../redux/reducers/mapSlice";
const MapStyle = ({ iMG, text, setter, color }) => {
  const dispatch = useDispatch();
  const mapType = useSelector((state) => state.map.mapStyle);
  const handleMapStyleChange = () => {
    dispatch(setMapStyle(setter));
  };

  return (
    <Paper
      sx={{
        position: "relative",
        border:
          mapType === setter ? "2px solid #52975C" : "2px solid transparent",
        borderRadius: "4px",
        cursor: "pointer",
      }}
      elevation={mapType === setter ? 6 : 0}
      onClick={handleMapStyleChange}
    >
      <img
        src={iMG}
        width={"86px"}
        height={"56px"}
        alt={text}
        style={{ borderRadius: "2px" }}
      />
      <Typography
        sx={{
          fontSize: "8px",
          position: "absolute",
          left: "4px",
          top: "42px",
          fontWeight: 500,
          color: color,
        }}
      >
        {text}
      </Typography>
    </Paper>
  );
};

export default MapStyle;
