import {
  getCollectionSuccess,
  getCollectionFailure,
  addLandCollectionSuccess,
  addLandCollectionFailure,
  getUserCollectionSuccess,
  addCollectionSuccess,
  addCollectionFailure,
  deleteCollectionSuccess,
  deleteCollectionFailure,
  renameCollectionSuccess,
  reanmeCollectionFailure,
  getUserCollectionResults,
} from "../../actions/collectionActions";
import { call, put } from "redux-saga/effects";

import * as api from "../../api";

export function* getCollectionSaga(action) {
  try {
    const response = yield call(api.fetchCollection, action.payload);
    yield put(getCollectionSuccess(response.data));
  } catch (error) {
    yield put(getCollectionFailure(error.message));
  }
}

export function* addLandCollectionSaga(action) {
  try {
    const response = yield call(api.addLandCollection, action.payload);
    yield put(addLandCollectionSuccess(response.data));
    const r = yield call(getUserCollectionSaga, {
      payload: { token: action.payload.token },
    });
  } catch (error) {
    yield put(addLandCollectionFailure(error.message));
  }
}

export function* getUserCollectionSaga(action) {
  try {
    const response = yield call(api.fetchUserCollection, action.payload);
    yield put(getUserCollectionSuccess(response.data));
  } catch (error) {
    console.log(error);
    yield put(getCollectionFailure(error.message));
  }
}

export function* addCollectionSaga(action) {
  try {
    const response = yield call(api.addCollection, action.payload);
    yield put(addCollectionSuccess(response.data));
    const r = yield call(getUserCollectionSaga, {
      payload: { token: action.payload.token },
    });
  } catch (error) {
    yield put(addCollectionFailure(error.message));
  }
}

export function* deleteCollectionSaga(action) {
  try {
    const response = yield call(api.deleteCollection, action.payload);
    yield put(deleteCollectionSuccess(response.data));
    const r = yield call(getUserCollectionSaga, {
      payload: { token: action.payload.token },
    });
  } catch (error) {
    yield put(deleteCollectionFailure(error.message));
  }
}

export function* renameCollectionSaga(action) {
  try {
    const response = yield call(api.renameCollection, action.payload);
    yield put(renameCollectionSuccess(response.data));
    const r = yield call(getUserCollectionSaga, {
      payload: { token: action.payload.token },
    });
  } catch (error) {
    yield put(reanmeCollectionFailure(error.message));
  }
}

export function* deleteLandCollectionSaga(action) {
  try {
    const response = yield call(api.deleteLandCollection, action.payload);
    yield put(deleteCollectionSuccess(response.data));
    const r = yield call(getUserCollectionSaga, {
      payload: { token: action.payload.token },
    });
  } catch (error) {
    yield put(deleteCollectionFailure(error.message));
  }
}
