import styled, { keyframes } from "styled-components";

export const SpinnerAnimation = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const StyledSpinner = styled.div`
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.25em;
  border-color: "red";
  border-right-color: transparent;
  animation: 0.75s linear infinite ${SpinnerAnimation};
  margin-top: 5px;
`;
