import { takeLatest } from "redux-saga/effects";
import {
  ADD_COLLECTION,
  ADD_LAND_COLLECTION,
  DELETE_COLLECTION,
  DELETE_LAND_COLLECTION,
  GET_COLLECTION_RESULTS,
  GET_USER_COLLECTION_RESULTS,
  RENAME_COLLECTION,
} from "../../actions/types";

import {
  getCollectionSaga,
  addLandCollectionSaga,
  getUserCollectionSaga,
  addCollectionSaga,
  deleteCollectionSaga,
  renameCollectionSaga,
  deleteLandCollectionSaga,
} from "./collectionsSagas";

export default function* layersSaga() {
  yield takeLatest(GET_COLLECTION_RESULTS, getCollectionSaga);
  yield takeLatest(ADD_LAND_COLLECTION, addLandCollectionSaga);
  yield takeLatest(GET_USER_COLLECTION_RESULTS, getUserCollectionSaga);
  yield takeLatest(ADD_COLLECTION, addCollectionSaga);
  yield takeLatest(DELETE_COLLECTION, deleteCollectionSaga);
  yield takeLatest(RENAME_COLLECTION, renameCollectionSaga);
  yield takeLatest(DELETE_LAND_COLLECTION, deleteLandCollectionSaga);
}
