import { useFavoriteLandResults } from "../hooks/favoriteLandResultsHook";
import SearchResults from "../components/SearchResults";
/*
In case is needed, this is the Mock of the lands
import GeoData from '../response.json'
*/

const FavoriteLandResults = ({ step }) => {
  const {
    isLoadingFavoriteLands,
    favoriteLandsResponse,
    saveOutputLandsSelection,
    saveAsFavoriteLand,
    isLoadingOutputLands,
    onCloseModal,
    shouldShowModal,
    modalMessage,
  } = useFavoriteLandResults();

  return (
    <SearchResults
      activeStep={step}
      onSaveOutputLandsSelection={saveOutputLandsSelection}
      isLoadingLands={isLoadingFavoriteLands || isLoadingOutputLands}
      outputLandsResponse={favoriteLandsResponse}
      saveAsFavoriteLand={saveAsFavoriteLand}
      onCloseModal={onCloseModal}
      shouldShowModal={shouldShowModal}
      modalMessage={modalMessage}
    ></SearchResults>
  );
};

export default FavoriteLandResults;
