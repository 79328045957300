import { createSlice } from "@reduxjs/toolkit";

const landSearchSlice = createSlice({
  name: "landSearch",
  initialState: {
    landSearches: [],
    status: "idle",
    error: null,
    openedLandsSearchSideBar: false,
  },
  reducers: {
    setLandSearches: (state, action) => {
      state.landSearches = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    openLandsSearchSideBar: (state) => {
      state.openedLandsSearchSideBar = true;
    },
    closeLandsSearchSideBar: (state) => {
      state.openedLandsSearchSideBar = false;
    },
    toggleLandsSearchSideBar: (state) => {
      state.openedLandsSearchSideBar = !state.openedLandsSearchSideBar;
    },
  },
});

export const {
  setLandSearches,
  setStatus,
  setError,
  openLandsSearchSideBar,
  closeLandsSearchSideBar,
  toggleLandsSearchSideBar,
} = landSearchSlice.actions;
export default landSearchSlice.reducer;
