import styled from "styled-components";

export const ProjectDetailsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

export const ProjectDetail = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
`;

export const ProjectDetailTitle = styled.span`
  overflow: hidden;
  color: var(--background-color-2, #fffdfa);
  text-overflow: ellipsis;
  font-family: Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: ${(props) => (props.isFavoriteLands ? "center" : "")};
`;

export const ProjectDetailSubtitle = styled.span`
  color: var(--secondary-color, #91b191);
  font-family: Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;
