import React from "react";
import { Box } from "@mui/material";
import BannerBlackLogo from "../images/new-black-logo.png";
import CreateModal from "./CreateModal";
const QuestionModal = ({ setHelp }) => {
  return (
    <CreateModal
      height={"70vh"}
      width={"70vw"}
      setOpen={setHelp}
      content={
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img src={BannerBlackLogo} width={"200px"} alt="Banner Black Logo" />
        </Box>
      }
    />
  );
};

export default QuestionModal;
