import { routesArray } from "./routesConfig";
import { useNavigate, Routes, Route } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

const Routing = () => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  const baseUrl = process.env.REACT_APP_DOMAIN_BASE_URL;

  return (
    <Auth0ProviderWithRedirectCallback
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience: audience,
        scope: "openid profile email",
        redirect_uri: baseUrl,
      }}
    >
      <Routes>
        {routesArray.map((route) => (
          <Route {...route} key={route.id} />
        ))}
      </Routes>
    </Auth0ProviderWithRedirectCallback>
  );
};

export default Routing;
