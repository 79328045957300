import { takeLatest } from "redux-saga/effects";
import {
  SAVE_OUTPUT_LANDS,
  GET_OUTPUT_LANDS,
  GET_SELECTED_LANDS_RESULTS,
  GET_PRE_PROCESSED_SEARCHES_INPUTS,
  GET_FAVORITE_OUTPUT_LANDS,
  SAVE_FAVORITE_OUTPUT_LAND,
} from "../../actions/types";
import {
  getOutputLandsSaga,
  getSelectedLandResultsSaga,
  saveOutputLandsSaga,
  getPreProcessedSearchesInputsSaga,
  getFavoriteOutputLandsSaga,
  saveFavoriteOutputLandSaga,
} from "./outputLandsSagas";

export default function* landsSaga() {
  yield takeLatest(GET_OUTPUT_LANDS, getOutputLandsSaga);
  yield takeLatest(
    GET_PRE_PROCESSED_SEARCHES_INPUTS,
    getPreProcessedSearchesInputsSaga
  );
  yield takeLatest(SAVE_OUTPUT_LANDS, saveOutputLandsSaga);
  yield takeLatest(GET_SELECTED_LANDS_RESULTS, getSelectedLandResultsSaga);
  yield takeLatest(GET_FAVORITE_OUTPUT_LANDS, getFavoriteOutputLandsSaga);
  yield takeLatest(SAVE_FAVORITE_OUTPUT_LAND, saveFavoriteOutputLandSaga);
}
