import { useSelector, useDispatch } from "react-redux";
import { searchClickSelector, tabSelector } from "../redux/selectors/search";
import { setTab, setSearch } from "../redux/actions/searchesActions";

export const useSearchControls = () => {
  const search = useSelector(searchClickSelector);
  const tab = useSelector(tabSelector);

  const dispatch = useDispatch();

  const sTab = (event, newTab) => {
    if (newTab !== null && newTab !== "AnalyzeLand") {
      dispatch(setTab(newTab));
    }
  };
  const sSearch = (inp) => {
    dispatch(setSearch(inp));
  };

  return {
    search,
    tab,
    sTab,
    sSearch,
  };
};
