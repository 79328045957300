import React from "react";
import { useState, useEffect } from "react";
import Checkbox from "./Checkbox";
import CardConditionDiv from "./CardConditionDiv";
import {
  getConditionFromRanking,
  getScoreAndRankingValues,
  getLandDetails,
  getAcRuleDetails,
  getEcRuleDetails,
} from "../utils/functions";
import "../styles/OutputLandCard.css";
import "../styles/OutputLandCard.styled.jsx";
import {
  Card,
  CardContainer,
  ShowDetailsLink,
  ResultCardTitleText,
  ResultCardTitleContainer,
  ConditionText,
  StyledStarIcon,
  InlineDiv,
} from "../styles/OutputLandCard.styled.jsx";
// TODO: Find the way to add the icons for each attribute value
/* import LandIcon from "../images/land_icon.png";
import GridDistanceIcon from "../images/grid_distance.png";
import AreaSquaredIcon from "../images/area_squared_icon.png";
import PermittingIcon from "../images/permitting_icon.png";
import RadiationIcon from "../images/radiation_icon.png"; */

const OutputLandMapCard = ({
  number,
  landData,
  mapCardId,
  onCheckboxChange,
  saveAsFavoriteLand,
}) => {
  const [isSelected, setIsSelected] = useState(false);
  const [showEcRules, setShowEcRules] = useState(false);
  const [isStarred, setIsStarred] = useState(false);

  const handleCheckboxSelect = (selected) => {
    setIsSelected(selected);
    onCheckboxChange(mapCardId, selected);
  };

  const toggleEcRules = () => {
    setShowEcRules(!showEcRules);
  };
  const toggleStar = () => {
    saveAsFavoriteLand({
      is_favorite: !isStarred,
      output_land_id: mapCardId,
    });
  };

  

  const condition = getConditionFromRanking(landData?.ranking);
  const landDetails = getLandDetails(landData);
  const rankingAndScoreValues = getScoreAndRankingValues(landData);
  // Special handling for AC & EC rules data
  const acRulesDetails = getAcRuleDetails(landData);
  const ecRulesDetails = getEcRuleDetails(landData);

  useEffect(() => {
    setIsStarred(landData?.is_favorite);
  },[landData?.is_favorite]);

  return (
    <CardContainer>
      <ResultCardTitleContainer>
        <ResultCardTitleText>
          {"Site " + (number + 1)}
          {landData.technology_name && ` - ${landData.technology_name}`}
          {landData.project_size_in_mw && ` - ${landData.project_size_in_mw}MW`}
          {landData.reverse_geocoding_address &&
            ` - ${landData.reverse_geocoding_address}`}
        </ResultCardTitleText>
        <CardConditionDiv condition={condition}>
          <ConditionText>{condition}</ConditionText>
        </CardConditionDiv>
      </ResultCardTitleContainer>
      <Card>
        {rankingAndScoreValues}
        {landDetails}
        {acRulesDetails}
        {showEcRules && ecRulesDetails}
      </Card>{" "}
      {ecRulesDetails?.length > 0 && (
        <ShowDetailsLink onClick={toggleEcRules}>
          {showEcRules ? "Show Less" : "Show More"}
        </ShowDetailsLink>
      )}
      <InlineDiv>
        <Checkbox
          selected={isSelected}
          onSelectedChange={handleCheckboxSelect}
        />

        <StyledStarIcon isSelected={isStarred} onClick={toggleStar} />
      </InlineDiv>
    </CardContainer>
  );
};

export default OutputLandMapCard;
