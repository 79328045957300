import React from "react";
import { Box, Typography } from "@mui/material";

const DropDown = ({ selected, data, top }) => {
  const checkstyle = () => ({
    display: "inline-flex",
    gap: "0px",
    background: "white",
    alignItems: "center",
    transition: " opacity .1s ease-in-out",
    opacity: selected ? 1 : 0,
    padding: "12px 12px 12px 12px",
    borderRadius: "6px",
    position: "absolute",
    right: "16px",
    top: top,
    zIndex: selected ? 5 : -5,
    border: "1px solid #9D9D9D24",
    flexDirection: "column",
  });

  return (
    <Box sx={checkstyle()}>
      {Object.keys(data).map((inp, index) => (
        <Box
          key={index}
          sx={{
            width: "100%",
            padding: "8px",
            "&:hover": {
              backgroundColor: "#f0f0f0",
            },
          }}
          onClick={data[inp]}
        >
          <Typography sx={{ fontSize: "14px", cursor: "pointer" }}>
            {inp}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default DropDown;
