// utils/selectOptions.js

import { useMemo } from "react";
import wellknown from "wellknown";
import * as turf from "@turf/turf";

export const useTechnologyOptions = (preProcessedSearchData) => {
  return useMemo(() => {
    return (
      preProcessedSearchData?.technologies?.map((tech) => ({
        value: tech.id,
        label: tech.value,
      })) || []
    );
  }, [preProcessedSearchData]);
};
export const useSubregionOptions = (preProcessedSearchData) => {
  return useMemo(() => {
    return (
      preProcessedSearchData?.sub_regions?.map((subRegion) => ({
        value: subRegion.id,
        label: subRegion.name,
      })) || []
    );
  }, [preProcessedSearchData]);
};
export const useSearchEntitiesAdmLevel1Options = (searchEntitiesAdmLevel1) => {
  return useMemo(() => {
    return (
      searchEntitiesAdmLevel1?.map((entity) => ({
        value: entity.entity_id,
        label: entity.name,
        centroid: entity.centroid,
      })) || []
    );
  }, [searchEntitiesAdmLevel1]);
};

export const useSearchEntitiesOptions = (searchEntities) => {
  return useMemo(() => {
    return (
      searchEntities?.map((entity) => ({
        value: entity.entity_id,
        label: entity.name,
        centroid: entity.centroid,
      })) || []
    );
  }, [searchEntities]);
};
export const useRegionOptions = (preProcessedSearchData) => {
  return useMemo(() => {
    return (
      preProcessedSearchData?.search_entities?.map((entity) => {
        //TODO: This is for Israel without normalizing layers. Once it is done, keep only one code.
        /*// Parse the WKT geometry
        const geom = wellknown.parse(entity.geom);
        // Calculate the centroid using turf
        const centroid = turf.centroid(geom);
        const [longitude, latitude] = centroid.geometry?.coordinates;*/

        //TODO: Make this generic: Add centroid in every table as "search_entity" type --> Actually this works for California since it has centroid in the corresponding table.
        /*const centroid = entity.centroid;
        const [longitude, latitude] = centroid
          .split(",")
          .map((coord) => coord.trim());*/
        const centroid = entity.centroid;
        const [longitude, latitude] = centroid
          .split(",")
          .map((coord) => coord.trim());

        // Build the label parts
        const labelParts = [];

        //TODO: Remove this line and make it generic. All must have "name" in "search_entities" type of table
        //if (entity.local_name) labelParts.push(`Kibbutz: ${entity.local_name}`);

        if (entity.name) labelParts.push(`${entity.name}`);
        if (entity.osm_id) labelParts.push(`Id: ${entity.osm_id}`);
        if (labelParts.length === 0 && entity.operator)
          labelParts.push(`Operator: ${entity.operator}`);
        if (labelParts.length === 0 && entity.voltage)
          labelParts.push(`Voltage: ${entity.voltage}`);

        const label = labelParts.join(" - ");

        return {
          value: `${longitude}*${latitude}`,
          label,
        };
      }) || []
    );
  }, [preProcessedSearchData]);
};
