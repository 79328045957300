import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
// TODO: change the place from where the accessTokenSelector is returned
import { accessTokenSelector } from "../redux/selectors/outputLands";
import { redirectToLoginSelector } from "../redux/selectors/auth";
import { clearRedirectToLogin, resetStore } from "../redux/actions/authActions"; // Asegúrate de crear esta acción

const useAuth0AccessToken = () => {
  const { getAccessTokenSilently, logout } = useAuth0();
  const [accessToken, setAccessToken] = useState(null);

  const storedAccessToken = useSelector(accessTokenSelector);
  const redirectToLogin = useSelector(redirectToLoginSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (redirectToLogin) {
      dispatch(clearRedirectToLogin());
      dispatch(resetStore());
      logoutAuth0();
    }
  }, [redirectToLogin, dispatch]);

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        // TODO: Save token in local storage: localStorage.setItem("accessToken", accessToken);
        setAccessToken(token);
      } catch (e) {
        console.error(e);
        logoutAuth0();
      }
    })();
  }, [getAccessTokenSilently]);

  const logoutAuth0 = () => {
    logout({
      logoutParams: {
        returnTo: `${process.env.REACT_APP_DOMAIN_BASE_URL}/landingpage`,
      },
    });
  };
  return { accessToken, logoutAuth0, storedAccessToken };
};

export default useAuth0AccessToken;
