import { call, put } from "redux-saga/effects";
import {
  setLandSearches,
  setStatus,
  setError,
} from "../../actions/landSearchActions";
import * as api from "../../api";

export function* fetchLandSearchesSaga(action) {
  try {
    yield put(setStatus("loading"));
    const response = yield call(api.getLandSearches, { token: action.payload });
    yield put(setLandSearches(response.data));
    yield put(setStatus("success"));
  } catch (error) {
    yield put(setError(error.message));
    yield put(setStatus("fail"));
  }
}
