import React from "react";
import { Box, Button } from "@mui/material";
export const Done = ({ handleDone, val }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        bottom: "16px",
        right: "32px",
        width: "128px",
      }}
    >
      <Button
        variant="long"
        disabled={val === "" || val === -1}
        sx={{
          backgroundColor: val === "" ? "#D5E6D8" : "#52975C",
          color: val === "" ? "#95C09B" : "white",
          height: "48px",
        }}
        onClick={handleDone}
      >
        Done
      </Button>
    </Box>
  );
};
