import {
  GET_SUBREGION_RESULTS,
  GET_SUBREGION_RESULTS_FAILURE,
  GET_SUBREGION_RESULTS_SUCCESS,
} from "../actions/types";

function subRegionReducer(state = {}, action) {
  switch (action.type) {
    case GET_SUBREGION_RESULTS:
      return {
        ...state,
        isLoading: true,
      };
    case GET_SUBREGION_RESULTS_SUCCESS:
      return {
        ...state,
        response: action.payload,
        isLoading: false,
      };

    case GET_SUBREGION_RESULTS_FAILURE:
      return {
        ...state,
        response: [],
        isLoading: false,
      };
    default:
      return state;
  }
}

export default subRegionReducer;
