import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAuth0AccessToken from "../hooks/authHook";
import {
  saveOutputLands,
  saveFavoriteOutputLand,
} from "../redux/actions/landsActions";
import { useModal } from "./modalHook";

import {
  favoriteLandsResponseSelector,
  isLoadingFavoriteLandsSelector,
} from "../redux/selectors/favoriteLands";
import { isLoadingOutputLandsSelector } from "../redux/selectors/outputLands";

export const useFavoriteLandResults = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { accessToken } = useAuth0AccessToken();

  const isLoadingOutputLands = useSelector(isLoadingOutputLandsSelector);
  const isLoadingFavoriteLands = useSelector(isLoadingFavoriteLandsSelector);
  const favoriteLandsResponse = useSelector(favoriteLandsResponseSelector);

  const { shouldShowModal, onCloseModal, modalMessage } = useModal();

  const saveOutputLandsSelection = (data) => {
    // navigate is being sent to go to next step on success.
    dispatch(saveOutputLands({ ...data, navigate }));
  };

  const saveAsFavoriteLand = (data) => {
    dispatch(
      // Save favorite land, and
      // Get favorite lands to refresh the favorite lands screen.
      saveFavoriteOutputLand({
        ...data,
        shouldGetAllFavoriteLands: true,
        token: accessToken,
      })
    );
  };

  return {
    saveOutputLandsSelection,
    isLoadingFavoriteLands,
    favoriteLandsResponse,
    saveAsFavoriteLand,
    isLoadingOutputLands,
    onCloseModal,
    shouldShowModal,
    modalMessage,
  };
};
