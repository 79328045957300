import { useEffect } from "react";

export const usePreProcessedSearches = (
  accessToken,
  hasFetched,
  setHasFetched,
  getPreProcessedSearchesInputsResults,
  load,
  preProcessedSearchData
) => {
  useEffect(() => {
    const fetchPreProcessedSearchesInputs = async () => {
      try {
        await getPreProcessedSearchesInputsResults({ token: accessToken });
        setHasFetched(true);
      } catch (error) {
        console.error("Error fetching pre-processed searches inputs:", error);
        // TODO: Handle the error state here if needed
      }
    };

    if (accessToken && !hasFetched && !load) {
      fetchPreProcessedSearchesInputs();
    }
  }, [
    accessToken,
    hasFetched,
    setHasFetched,
    getPreProcessedSearchesInputsResults,
    load,
    preProcessedSearchData,
  ]);
};
