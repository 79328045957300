import { useDispatch, useSelector } from "react-redux";
import { getDisplayedLayersResults } from "../redux/actions/layersActions";
import { displayedLayersSelector } from "../redux/selectors/displayedLayers";
import { useState, useEffect } from "react";
import { useCallback } from "react";

// Needed Constants --> Move to correspondent place.
const GEO_SERVER_DOMAIN_URL = process.env.REACT_APP_GEO_SERVER_DOMAIN_URL;
const GEO_SERVER_WORKSPACE_NAME =
  process.env.REACT_APP_GEO_SERVER_WORKSPACE_NAME;
const baseUrl = `${GEO_SERVER_DOMAIN_URL}/ows`;
const service = `SERVICE=WMS`;
const version = "VERSION=1.1.1";
const request = "REQUEST=GetMap";
const format = "FORMAT=image/png";
const transparent = "TRANSPARENT=true";
const styles = "STYLES=";
const srs = "SRS=EPSG:3857";
const width = "WIDTH=256";
const height = "HEIGHT=256";
const bbox = "BBOX={bbox-epsg-3857}";

export const useLayers = () => {
  const dispatch = useDispatch();
  const displayedLayers = useSelector(displayedLayersSelector);
  const getDisplayedLayers = useCallback(
    (data) => {
      const params = {
        token: data,
      };
      dispatch(getDisplayedLayersResults(params));
    },
    [dispatch]
  );

  const [layers, setLayers] = useState({});

  useEffect(() => {
    if (displayedLayers) {
      const newLayers = displayedLayers.reduce((acc, layer) => {
        const layers = `LAYERS=${GEO_SERVER_WORKSPACE_NAME}:${layer.layer_table_name}`;
        const tileUrl = `${baseUrl}?${service}&${version}&${request}&${format}&${layers}&${transparent}&${styles}&${srs}&${width}&${height}&${bbox}`;

        if (
          !acc[layer.layer_category] &&
          "layer_name" in layer &&
          layer["layer_name"] != null
        ) {
          acc[layer.layer_category] = {};
        }

        if ("layer_name" in layer && layer["layer_name"] != null) {
          acc[layer.layer_category][layer.layer_name] = {
            active: layer.is_search_entity,
            tileUrl: tileUrl,
          };
        } else if (
          "layer_subcategory" in layer &&
          layer["layer_subcategory"] != null
        ) {
          if (!acc[layer.layer_category]) {
            acc[layer.layer_category] = {};
          }
          acc[layer.layer_category][layer.layer_subcategory] = {
            active: layer.is_search_entity,
            tileUrl: tileUrl,
          };
        }

        return acc;
      }, {});
      setLayers(newLayers);
    }
  }, [displayedLayers]); //Probably need to include the tileUrl

  const changeLayer = (category, layerName, value) => {
    if (category in layers) {
      if (layerName in layers[category]) {
        setLayers((prevLayers) => ({
          ...prevLayers,
          [category]: {
            ...prevLayers[category],
            [layerName]: { ...prevLayers[category][layerName], active: value },
          },
        }));
      }
    }
  };

  return {
    layers,
    changeLayer,
    getDisplayedLayers,
  };
};
