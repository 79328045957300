import React from "react";
import { Box, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Globe from "../images/icons/Globe.svg";
import Fold from "../images/icons/Folder.svg";
import { useState } from "react";
import RenameModal from "./RenameModal";
import DropDown from "./DropDown";
import DeleteModal from "./DeleteModal";

const Folder = ({ inp, fDirection, changeCollection, deleteCollection }) => {
  const [selected, setSelected] = useState(false);

  const [rename, setRename] = useState(false);
  const [del, setDel] = useState(false);

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: fDirection,
        alignItems: fDirection === "row" ? "center" : "",
      }}
    >
      {rename && (
        <RenameModal
          setOpen={setRename}
          inp={inp}
          changeCollection={changeCollection}
          setSelected={setSelected}
        />
      )}
      {del && (
        <DeleteModal
          setOpen={setDel}
          deleteCollection={deleteCollection}
          collectionID={inp.id}
          setSelected={setSelected}
        />
      )}
      <img
        src={Fold}
        alt="Folder"
        width={fDirection === "row" ? "81px" : "160px"}
      />
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "12px",
            paddingRight: "12px",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
            {inp["name"]}
          </Typography>
          {fDirection === "column" && (
            <Box>
              <MoreVertIcon
                sx={{ height: "18px", width: "18px", cursor: "pointer" }}
                onClick={() => setSelected(!selected)}
              />
              <DropDown
                selected={selected}
                top={"24px"}
                data={{
                  Rename: () => setRename(true),
                  Delete: () => setDel(true),
                }}
              />
            </Box>
          )}
        </Box>
        <Box
          sx={{ display: "flex", paddingLeft: "11px", alignItems: "center" }}
        >
          <img src={Globe} width={"14px"} alt="Projects" />
          <Typography sx={{ paddingLeft: "4px", fontSize: "12px" }}>
            {inp.land_count + " projects"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Folder;
