import React, { useState } from "react";
import { Box, Button } from "@mui/material";

import { useSearchLandResults } from "../hooks/searchLandResultsHook";

import SiteCard from "../new_components/SiteCard";
import MapComp from "../new_components/MapComp";
import SearchBar from "../new_components/SearchBar";
import HeaderBar from "../new_components/HeaderBar";
import NoCollectionPage from "../new_components/NoCollectionPage";
import CollectionsPage from "../new_components/CollectionsPage";
import { useMap } from "../new_hooks/useMap";
import Modal from "../components/Modal";
import SiteResults from "../new_components/SiteResults";

import ReadMore from "../new_components/ReadMore";
import { useCollections } from "../new_hooks/useCollections";
import { useSearchControls } from "../new_hooks/useSearchControls";
import PageLayout from "../components/PageLayout";
import useAuth0AccessToken from "../hooks/authHook";
import LandSearchSideBar from "../new_components/LandSearchSideBar/LandSearchesSideBar";
import { ROUTES } from "../routes/constants";
import { AnimatePresence } from "framer-motion";
import { StyledMotionWrapperDiv } from "../styles/MapPage.styles";
import useLandSearchResults from "../new_hooks/useLandSearchResults";

const MapPage = () => {
  const [selectedLand, setSelectedLand] = useState(-1);
  const [readMore, setReadMore] = useState(false);
  const {
    saveOutputLandsSelection,
    outputLandsResponse,
    selectedLandsInputs,
    saveAsFavoriteLand,
    modalMessage,
    shouldShowModal,
    shouldShowQuestionInModal,
    onCloseModal,
    isLoadingOutputLands,
    setQuestion,
    handleNoClick,
    handleYesClick,
  } = useSearchLandResults();
  const { search, tab, sTab, sSearch } = useSearchControls();
  const {
    outputlandsData,
    viewState,
    mapRef,
    onMapClick,
    setViewState,
    onSideChange,
    sideRef,
  } = useMap(setSelectedLand, setReadMore, readMore);
  const { userCollection, aCollection, addToFolder } = useCollections();
  const { accessToken } = useAuth0AccessToken();
  const isHomePageRoute = window.location.pathname.endsWith(ROUTES.SEARCH);
  const { isLandSearchSidebarOpen, landSearches } = useLandSearchResults(
    accessToken,
    isHomePageRoute
  );

  const handleSelectedLand = (inp) => {
    setSelectedLand(inp);
    onSideChange(inp);
  };

  const sidebarVariants = {
    hidden: {
      x: "-100%",
      opacity: 0,
    },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30,
      },
    },
    exit: {
      x: "-100%",
      opacity: 0,
      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
  };

  return (
    <PageLayout
      tab={tab}
      changeTab={sTab}
      search={search}
      isSearching={sSearch}
      setViewState={setViewState}
    >
      <AnimatePresence>
        {isLandSearchSidebarOpen && !search && isHomePageRoute ? (
          <StyledMotionWrapperDiv
            key="landSearchSideBar"
            variants={sidebarVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <LandSearchSideBar landSearches={landSearches.result} />
          </StyledMotionWrapperDiv>
        ) : null}
      </AnimatePresence>

      <Box
        sx={{
          position: "relative",
          display: "flex",
          width: "100%",
          height: "90vh",
          flexGrow: 1,
        }}
      >
        {outputLandsResponse?.length > 0 &&
          search &&
          !isLoadingOutputLands &&
          (!readMore ? (
            <SiteResults
              outputLandsResponse={outputLandsResponse}
              handleSelectedLand={handleSelectedLand}
              sideRef={sideRef}
              collectionData={userCollection}
              addCollection={aCollection}
              addToFolder={addToFolder}
              selectedLand={selectedLand}
              setReadMore={setReadMore}
              setQuestion={setQuestion}
            />
          ) : (
            <ReadMore
              setReadMore={setReadMore}
              landID={selectedLand}
              outputLandsResponse={outputLandsResponse}
              collectionData={userCollection}
              addCollection={aCollection}
              addToFolder={addToFolder}
            />
          ))}

        <Box
          sx={{
            position: "relative",
            flexGrow: "1",
            overflowX: "hidden",
            overflowY: "hidden",
            flex: 1,
          }}
        >
          <MapComp
            landID={selectedLand}
            setLandID={setSelectedLand}
            search={search}
            outputlandsData={outputlandsData}
            viewState={viewState}
            mapRef={mapRef}
            onMapClick={onMapClick}
            setViewState={setViewState}
            isLoadingOutputLands={isLoadingOutputLands}
          />
        </Box>
      </Box>

      {shouldShowModal && (
        <Modal isOpen={shouldShowModal} onClose={() => onCloseModal()}>
          {!shouldShowQuestionInModal ? (
            <>
              <h2>Information</h2>
              <p>{modalMessage}</p>
            </>
          ) : (
            <>
              <h2>Read More?</h2>
              <p>{modalMessage}</p>
              <Box
                sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}
              >
                <Button
                  variant="contained"
                  onClick={handleYesClick}
                  sx={{
                    backgroundColor: "#52975C",
                    "&:hover": { backgroundColor: "#47784E" },
                    flex: 1,
                    mx: 1,
                  }}
                >
                  Yes
                </Button>
                <Button
                  variant="contained"
                  onClick={handleNoClick}
                  sx={{
                    backgroundColor: "#52975C",
                    "&:hover": { backgroundColor: "#47784E" },
                    flex: 1,
                    mx: 1,
                  }}
                >
                  No
                </Button>
              </Box>
            </>
          )}
        </Modal>
      )}
    </PageLayout>
  );
};

export default MapPage;
