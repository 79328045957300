import React from "react";
import { Box, Typography } from "@mui/material";
import LoadResults from "../images/LoadResults.gif";
import CreateModal from "./CreateModal";

const LoadingResults = ({ setClose }) => {
  return (
    <CreateModal
      width={"35vw"}
      height={"45vh"}
      setOpen={setClose}
      content={
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
            gap: "8px",
            flexDirection: "column",
          }}
        >
          <Typography sx={{ fontSize: "24px", fontWeight: 700 }}>
            {" "}
            Loading results{" "}
          </Typography>
          <img src={LoadResults} alt="Load Results" width={"140px"} />
        </Box>
      }
    />
  );
};

export default LoadingResults;
