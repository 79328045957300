import React from "react";

const LogoutButton = ({onLogout}) => {
  return (
    <button onClick={() => onLogout()}>
      Log Out
    </button>
  );
};

export default LogoutButton;