import axios from "axios";

export const fetchSearchOrchestrator = async ({
  token,
  projectSize,
  technology,
  searchEntity,
  subRegion,
  projectSizeUnit,
}) => {
  try {
    const searchOrchestratorUrl =
      process.env.REACT_APP_API_BASE_URL + "/orchestrators/searchOrchestrator";
    const response = await axios.get(searchOrchestratorUrl, {
      params: {
        project_size: projectSize,
        technology_id: technology.value,
        entity_id: searchEntity.value,
        entity_centroid: searchEntity.centroid,
        sub_region_id: subRegion.value,
        project_size_unit: projectSizeUnit,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchStatusQuery = async (url) => {
  try {
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw new Error("Failed to fetch search status.");
  }
};

//TODO: Deprecate
export const fetchSaveOutputLands = async ({ token, selectedMapCards }) => {
  try {
    const saveResultsUrl =
      process.env.REACT_APP_API_BASE_URL + "/saveOutputLands";

    const response = await axios.post(
      saveResultsUrl,
      {
        output_lands: selectedMapCards,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error("Failed to fetch output lands.");
  }
};

//TODO: Deprecate
export const fetchFavoriteOutputLands = async ({ token }) => {
  try {
    const favoriteOutputLandsUrl =
      process.env.REACT_APP_API_BASE_URL + "/getFavoriteOutputLands";
    const response = await axios.get(favoriteOutputLandsUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw new Error("Failed to fetch favorite output lands.");
  }
};

export const fetchDisplayedLayers = async ({ token }) => {
  try {
    const displayedLayersUrl =
      process.env.REACT_APP_API_BASE_URL + "/getdisplayedLayers";
    const response = await axios.get(displayedLayersUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchSubRegion = async ({ token, extent }) => {
  try {
    /*const getPolygonsByExtentUrl =
      process.env.REACT_APP_API_BASE_URL + "/getPolygonsByExtent";

 const response = await axios.post(
      getPolygonsByExtentUrl,
      {
        extent: extent,
        layer: "sub_regions",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );*/

    /* const getPolygonsByExtentUrl =
      process.env.REACT_APP_API_BASE_URL +
      "/getPolygonsByExtent" +
      "?layer=sub_regions&extent=" +
      extent[0][0] +
      "," +
      extent[0][1] +
      "," +
      extent[1][0] +
      "," +
      extent[1][1]; */

    const getFeaturebyXYUrl =
      process.env.REACT_APP_API_BASE_URL +
      "/wps/getFeatureByXy" +
      "?layer_name=sub_regions&x=" +
      extent[0][0] +
      "&y=" +
      extent[0][1];

    const response = await axios.get(getFeaturebyXYUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    throw new Error("Failed to show displayed layers.");
  }
};

export const fetchOutputLands = async ({
  token,
  projectSize,
  projectSizeUnit,
  technology,
  searchEntity,
  subRegion,
  unlockSearchEntity = false,
}) => {
  try {
    const outputLandsWithValuesUrl =
      process.env.REACT_APP_API_BASE_URL + "/getOutputLandsWithValues";

    const response = await axios.post(
      outputLandsWithValuesUrl,
      {
        project_size: projectSize,
        project_size_unit: projectSizeUnit,
        technology_id: technology.value,
        entity_id: searchEntity.value,
        entity_centroid: searchEntity.centroid,
        sub_region_id: subRegion.value,
        unlock_search_entity: unlockSearchEntity,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

//TODO: Deprecate
export const fetchSelectedLandResults = async ({ token }) => {
  try {
    const selectedResultsUrl =
      process.env.REACT_APP_API_BASE_URL + "/getSelectedResults";
    const response = await axios.get(selectedResultsUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw new Error("Failed to fetch selected land results.");
  }
};

export const getUser = async ({ token }) => {
  try {
    const getUsersUrl = process.env.REACT_APP_API_BASE_URL + "/getUser";
    const response = await axios.get(getUsersUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getPreProcessedSearchesInputs = async ({
  token,
  technologyId = null,
  subregionId = null,
}) => {
  try {
    const getSubregionTechsUrl =
      process.env.REACT_APP_API_BASE_URL + "/getSubregionTechs";
    const response = await axios.get(getSubregionTechsUrl, {
      params: {
        technology_id: technologyId,
        sub_region_id: subregionId,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const getSearchEntities = async ({
  token,
  sub_region_id,
  technology_id,
  search_entity_adm_level_1_entity_id,
  search_entity_adm_level_1_layer_public_id,
}) => {
  try {
    const getSearchEntitiesUrl =
      process.env.REACT_APP_API_BASE_URL + "/getSearchEntities";
    const response = await axios.get(getSearchEntitiesUrl, {
      params: {
        technology_id: technology_id,
        sub_region_id: sub_region_id,
        search_entity_adm_level_1_entity_id:
          search_entity_adm_level_1_entity_id,
        search_entity_adm_level_1_layer_public_id:
          search_entity_adm_level_1_layer_public_id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    throw error;
  }
};

//TODO: Deprecate
export const fetchSaveFavoriteOutputLand = async ({
  token,
  is_favorite,
  output_land_id,
}) => {
  try {
    const saveResultsUrl =
      process.env.REACT_APP_API_BASE_URL + "/saveFavoriteOutputLand";
    const response = await axios.post(
      saveResultsUrl,
      {
        output_land_id: output_land_id,
        is_favorite: is_favorite,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error("Failed to fetch output lands.");
  }
};

export const fetchCollection = async ({ token, collection_id }) => {
  try {
    const getCollectionUrl =
      process.env.REACT_APP_API_BASE_URL + "/getCollection";
    const response = await axios.get(
      getCollectionUrl,
      {
        collection_id: collection_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error("Failed to fetch collection");
  }
};

export const fetchUserCollection = async ({ token }) => {
  try {
    const getUserCollectionUrl =
      process.env.REACT_APP_API_BASE_URL + "/getUserCollections";
    const response = await axios.get(getUserCollectionUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw new Error("Failed to get user's collections ");
  }
};

export const addCollection = async ({
  token,
  collection_name,
  description,
}) => {
  try {
    const addCollectionUrl =
      process.env.REACT_APP_API_BASE_URL + "/insertCollection";
    const response = await axios.post(
      addCollectionUrl,
      {
        collection_name: collection_name,
        description: description,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    throw new Error("Failed to add collection");
  }
};

export const addLandCollection = async ({ token, land_id, collection_id }) => {
  try {
    const addLandCollectionUrl =
      process.env.REACT_APP_API_BASE_URL + "/insertLandCollection";
    const response = await axios.post(
      addLandCollectionUrl,
      {
        land_id: land_id,
        collection_id: collection_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    throw new Error("Failed to add land to collection");
  }
};

export const deleteLandCollection = async ({
  token,
  land_id,
  collection_id,
}) => {
  try {
    const deleteLandCollectionUrl =
      process.env.REACT_APP_API_BASE_URL + "/deleteLandCollection";
    const response = await axios.post(
      deleteLandCollectionUrl,
      {
        land_id: land_id,
        collection_id: collection_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    throw new Error("Failed to delete land from collection");
  }
};

export const deleteCollection = async ({ token, collection_id }) => {
  try {
    const deleteCollectionUrl =
      process.env.REACT_APP_API_BASE_URL + "/deleteCollection";
    const response = await axios.post(
      deleteCollectionUrl,
      {
        collection_id: collection_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    throw new Error("Failed to delete collection");
  }
};

export const renameCollection = async ({ token, collection_id, new_name }) => {
  try {
    const renameCollectionUrl =
      process.env.REACT_APP_API_BASE_URL + "/renameCollection";
    const response = await axios.post(
      renameCollectionUrl,
      {
        collection_id: collection_id,
        new_name: new_name,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    throw new Error("Failed to rename Collection");
  }
};
export const uploadKmz = async ({ token, file }) => {
  try {
    const uploadKmzUrl = process.env.REACT_APP_API_BASE_URL + "/uploadKmz";
    const formData = new FormData();
    formData.append("file", file);
    const response = axios.post(uploadKmzUrl, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-ms-blob-type": "BlockBlob",
        "Content-Type": file.type,
      },
    });

    const analyzeKmz =
      process.env.REACT_APP_API_BASE_URL + "/orchestrators/landAnalysisProcess";
    const resp = axios.post(
      analyzeKmz,
      {
        blob: response.data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return resp;
  } catch (error) {
    throw new Error("Failed to upload File");
  }
};

export const getLandSearches = async ({ token }) => {
  try {
    const url = process.env.REACT_APP_API_BASE_URL + "/getLandSearches";
    const response = await axios.post(
      url,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error("Failed to get user's land searches");
  }
};
