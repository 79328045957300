import styled from "styled-components"; // Importa styled-components
import { ReactComponent as ArrowLeftIcon } from "../images/arrow-left.svg";
import { ReactComponent as ArrowRightIcon } from "../images/arrow-right.svg";
import { Box, IconButton } from "@mui/material";
import { motion } from "framer-motion";

export const MapResultsButton = styled.button`
  background-color: #4e814e;
  color: white;
  padding: 2px 2px;
  border: none;
  cursor: pointer;
  min-height: 700px;
  max-width: 34px;
`;
export const StyledArrowRighttIcon = styled(ArrowRightIcon)`
  cursor: pointer;
`;

export const StyledArrowLeftIcon = styled(ArrowLeftIcon)`
  cursor: pointer;
`;

export const StyledChevronRightIcon = styled(IconButton)`
  && {
    position: absolute;
    left: ${({ isMapPageRoute }) => (isMapPageRoute ? "-65px" : "-55px")};
    z-index: 5000;
    color: #000000;
    background-color: #ffffff;
    border-radius: 6px;
    height: 33px;
  }
`;

export const WrapperSelectFilter = styled(Box)`
  && {
    display: flex;
    gap: 8px;
    position: relative;
    top: 16px;
    transition: left 0.3s ease-in-out;
    left: ${({ isMapPageRoute, isLandSearchSidebarOpen }) =>
      isMapPageRoute ? "16px" : isLandSearchSidebarOpen ? "416px" : "60px"};
  }
`;

export const StyledMotionBox = styled(motion(Box))`
  position: absolute;
  top: 16px;
  display: flex;
  gap: 8px;
`;
