import React from "react";
import { FormControl, Typography, TextField } from "@mui/material";

const Text = ({ setVal, label }) => {
  const handleChange = (event) => {
    setVal(event.target.value);
  };

  return (
    <FormControl
      fullWidth
      variant="outlined"
      size="small"
      sx={{ "& fieldset": { border: "none" }, marginLeft: "-3px" }}
    >
      <Typography
        sx={{ fontSize: "14px", fontWeight: 400, paddingLeft: "3px" }}
      >
        {" "}
        {label}{" "}
      </Typography>
      <TextField
        size="small"
        placeholder="Project name, location, area, etc."
        sx={{
          backgroundColor: "#FAFAFA",
          border: "1px solid #E0E0E0",
          borderRadius: "6px",
          fontSize: "14px",
        }}
        onChange={handleChange}
      ></TextField>
    </FormControl>
  );
};

export default Text;
