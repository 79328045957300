import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import Routing from "./routes";
import { createTheme, ThemeProvider } from '@mui/material/styles';

const App = () => {
  const theme = createTheme({
    typography:{
      fontFamily: [
        'Satoshi Variable'
      ]
    },
    components: {
      MuiButton:{
        variants: [
          {
            props: {variant: "long"},
            style: {
              width: "100%",
              height: "56px",
              fontSize: "16px",
              fontWeight: 600,
              textTransform: "none",
              backgroundColor: "#47784E",

              color: "white",
              '&:hover':{
                backgroundColor: "#47784E"
              }
            }
          }
        ]
      },
      MuiToggleButton: {
        variants: [
          {
            props: {variant: "out"},
            style: {
              fontSize: "16px",
              border: "none",
              textTransform: "none",
              color: "black",
              borderRadius: "0px",
              padding: "4px 8px",
              "&.Mui-selected":{
                borderBottom: "1px solid #47784E",
                backgroundColor: "transparent"
              }
            }
          }
        ]
      }
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Helmet>
          <title>REplace</title>
        </Helmet>
        <Routing />
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
