import { FETCH_LAND_SEARCHES } from "./types";

import {
  fetchLandSearches,
  setLandSearches,
  setStatus,
  setError,
} from "../reducers/landSearchReducer";

export const fetchLandSearchesAction = (token) => ({
  type: FETCH_LAND_SEARCHES,
  payload: token,
});

export { setLandSearches, setStatus, setError };
