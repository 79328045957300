import { UPLOAD_KMZ, UPLOAD_KMZ_FAILURE, UPLOAD_KMZ_SUCCESS } from "./types";

export const uploadKmz = (payload) => {
  return {
    type: UPLOAD_KMZ,
    payload,
  };
};

export const uploadKmzSuccess = (payload) => ({
  type: UPLOAD_KMZ_SUCCESS,
  payload,
});

export const uploadKmzFailure = (payload) => ({
  type: UPLOAD_KMZ_FAILURE,
  payload,
});
